import { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AvailableAmountsResultInfo } from "../../../../../../../../Models/API/CapTable/available-amounts-result-info";
import { ShareClassTypeOptions, WarrantsType } from "../../../../../../../../Models/API/enums";
import { IssueTransaction as IssueTransactionType } from "../../../../../../../../Models/App/CapTable/IssueTransaction";
import CheckBox from "../../../../../../../../Shared/Components/CheckBox/CheckBox";
import DatePickerInput from "../../../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../../../Shared/Components/Input/TextInput";
import RadioButton from "../../../../../../../../Shared/Components/RadioButton/RadioButton";
import Select from "../../../../../../../../Shared/Components/Select/Select";
import { ExtraAssetTypes, optionSectionWarrantsTypes } from "../../../../../../../../Shared/Config";
import { AppendStateInputHandlerType } from "../../../../../../../../Shared/Hooks/useAppendState";
import { ForwardedRef } from "../../../../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../../../../Shared/Hooks/useRootStore";
import { formatDecimal, formatNumber, isNumber, zeroIfNegative } from "../../../../../../../../Shared/Utilities";
import { useIssueTransactionValidation } from "../../../../../../Hooks/Validations/useIssueTransactionValidation";
import { isCommonClass } from "../../../../../../utils/transactions";
import { CapTableValuationType } from "../../../../../../../../Models/API/CapTable/convertible-loan-transaction-data";

interface Props {
    transaction: IssueTransactionType;
    onInputHandler: AppendStateInputHandlerType;
    setTransaction: Dispatch<SetStateAction<IssueTransactionType>>;
}

const IssueTransaction = forwardRef<ForwardedRef, Props>(({ transaction, onInputHandler, setTransaction }, forwardedRef) => {
    const { capTableStore, currency, shareHolderStore } = useRootStore();
    const [availableAmounts, setAvailableAmounts] = useState<AvailableAmountsResultInfo>({
        availableShares: 0,
        availableWarrants: 0,
        minAvailableRegisteredShares: 0,
        minAvailableRegisteredWarrants: 0,
        availableBsa: 0,
    });
    const isCommonShare = isCommonClass(capTableStore.project?.shareClassesList, transaction.assetId);
    const { validateForm, formValidationState } = useIssueTransactionValidation({
        transaction,
        availableAmounts,
        type:
            transaction.assetId === ExtraAssetTypes.ConvertibleLoan
                ? "loan"
                : transaction.assetId === ExtraAssetTypes.SAFE
                ? "safe"
                : isCommonShare
                ? "common"
                : "class",
    });

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            const isValid = validateForm({ showErrors: true });
            if (!isValid) return false;
            await shareHolderStore.addUpdateIssueTransaction(transaction);
            return true;
        },
    }));

    // Issue transaction class change cleanup

    useEffect(() => {
        if (!transaction.assetId || !capTableStore.project) return;
        const shareClass = capTableStore.project.shareClassesList?.find((x) => x.shareClass.shareClassID === transaction.assetId);
        if (!shareClass) return;

        if (shareClass.shareClass.shareClassTypeID === ShareClassTypeOptions.Normal) {
            setTransaction((prevState) => ({
                ...prevState,
                date: shareClass.shareClass.roundDate ?? new Date(),
                pricePerShare: shareClass.shareClass.issuePricePerShare,
            }));
        }

        (async () => {
            const res = await capTableStore.getAvailableAmountByClass(transaction.assetId!, shareClass.shareClass.roundDate ?? new Date());
            if (isCommonShare) {
                const total = (transaction.numberOfShares ?? 0) + (transaction?.numberOfWarrants ?? 0);
                res.availableBsa += total;
                res.availableShares += total;
                res.availableWarrants += total;
            } else {
                res.availableShares += transaction.numberOfShares ?? 0;
                if (transaction.warrantsType === WarrantsType.Warrants || transaction.warrantsType === WarrantsType.Bsa) {
                    res.availableWarrants += transaction.numberOfWarrants ?? 0;
                }
            }
            setAvailableAmounts(res);
        })();
    }, [transaction.assetId]);

    if (!transaction.date || !transaction.assetId) return <></>;

    const isLoan = transaction.assetId === ExtraAssetTypes.ConvertibleLoan;
    const isSafe = transaction.assetId === ExtraAssetTypes.SAFE;

    if (isLoan || isSafe) {
        return (
            <>
                {/* <Grid className={TransactionGridStyle} cols={isLoan ? 4 : 3} rows={isLoan ? 2 : 1}> */}
                {isLoan && (
                    <>
                        <CheckBox
                            qaid="AddEditTransaction.CheckBox.Maturity"
                            isChecked={transaction.maturityEnabled}
                            name="maturityEnabled"
                            onClick={(value, name) => {
                                setTransaction((prevState) => ({
                                    ...prevState,
                                    maturityEnabled: value,
                                    maturityDate: undefined,
                                    valuationCap: undefined,
                                    discountPrice: undefined,
                                }));
                            }}
                            label="Maturity & discount"
                        />
                        <DatePickerInput
                            qaid="AddEditTransaction.Input.MaturityDate"
                            value={transaction.maturityDate}
                            name="maturityDate"
                            label="Maturity date"
                            onChange={onInputHandler}
                            disabled={!transaction.maturityEnabled}
                            required={!!transaction.maturityEnabled}
                            error={formValidationState?.maturityDate?.message}
                        />
                    </>
                )}
                <NumberInput
                    label={`Principle (${currency?.symbol})`}
                    qaid="AddEditTransaction.Input.TotalCost"
                    value={transaction.moneyInvested}
                    name="moneyInvested"
                    onChange={onInputHandler}
                    error={formValidationState?.moneyInvested?.message}
                    containerStyle={{ gridArea: isLoan ? "1 / 4 / 1 / 5" : "inherit" }}
                    required
                />
                <NumberInput
                    label={`Valuation cap (${currency?.symbol})`}
                    qaid="AddEditTransaction.Input.ValuationCap"
                    value={transaction.valuationCap}
                    name="valuationCap"
                    onChange={onInputHandler}
                    disabled={isLoan && !transaction.maturityEnabled}
                    placeholder={currency?.symbol}
                    error={formValidationState?.valuationCap?.message}
                    options={[
                        {
                            value: CapTableValuationType.PreMoney,
                            label: "Pre-money",
                        },
                        {
                            value: CapTableValuationType.PostMoney,
                            label: "Post-money",
                        },
                    ]}
                    onOptionSelect={(valuationCapType) =>
                        isNumber(valuationCapType) && setTransaction((prevState) => ({ ...prevState, valuationCapType }))
                    }
                    selectedValue={transaction.valuationCapType}
                    ratio={1}
                />
                <NumberInput
                    label="Discount"
                    qaid="AddEditTransaction.Input.Discount"
                    value={transaction.discount}
                    name="discount"
                    percentage
                    onChange={onInputHandler}
                    disabled={isLoan && !transaction.maturityEnabled}
                    error={formValidationState?.discount?.message}
                />
                {isLoan && (
                    <>
                        <CheckBox
                            qaid="AddEditTransaction.CheckBox.Interest"
                            isChecked={transaction.interestEnabled}
                            name="interestEnabled"
                            onClick={(value, name) => {
                                setTransaction((prevState) => ({
                                    ...prevState,
                                    interestEnabled: value,
                                    interestRate: undefined,
                                    isCompunded: value,
                                    isAnnualy: false,
                                }));
                            }}
                            label="Interest"
                        />
                        <NumberInput
                            label="Rate (%)"
                            qaid="AddEditTransaction.Input.InterestRate"
                            value={transaction.interestRate}
                            name="interestRate"
                            onChange={onInputHandler}
                            percentage
                            disabled={!transaction.interestEnabled}
                            required={!!transaction.interestEnabled}
                            error={formValidationState?.interestRate?.message}
                        />
                        <RadioButton
                            label="Compunded"
                            qaid="AddEditTransaction.Radio.Compunded"
                            checked={transaction.isCompunded}
                            value={true}
                            name="interestType"
                            onChange={(isCompunded) => setTransaction((prevState) => ({ ...prevState, isCompunded, isAnnualy: false }))}
                            disabled={!transaction.interestEnabled}
                        />
                        <RadioButton
                            label="Annualy"
                            qaid="AddEditTransaction.Radio.Annualy"
                            checked={transaction.isAnnualy}
                            value={true}
                            name="interestType"
                            onChange={(isAnnualy) => setTransaction((prevState) => ({ ...prevState, isAnnualy, isCompunded: false }))}
                            disabled={!transaction.interestEnabled}
                        />
                    </>
                )}
                {/* </Grid> */}
            </>
        );
    }

    const getAvailableWarrants = (): string => {
        const amount =
            transaction.warrantsType === WarrantsType.Warrants
                ? availableAmounts.availableWarrants - (isCommonShare ? transaction.numberOfShares ?? 0 : 0)
                : transaction.warrantsType === WarrantsType.Bsa
                ? availableAmounts.availableBsa - (isCommonShare ? transaction.numberOfShares ?? 0 : 0)
                : 0;

        return `Available: ${formatNumber(zeroIfNegative(amount))}`;
    };

    const availableShares: string = `Available: ${formatNumber(
        zeroIfNegative(availableAmounts.availableShares - (isCommonShare ? transaction.numberOfWarrants ?? 0 : 0))
    )}`;

    const getDiscountPrice = (value?: number, price?: number): undefined | number => {
        const finalPrice = price ?? transaction.pricePerShare;
        return finalPrice && value ? finalPrice - finalPrice * (value / 100) : undefined;
    };

    const getDiscount = (value?: number, price?: number): undefined | number => {
        const finalPrice = price ?? transaction.pricePerShare;
        return finalPrice && value ? ((finalPrice - value) / finalPrice) * 100 : undefined;
    };

    return (
        // <Grid className={TransactionGridStyle} cols={3} rows={3}>
        <>
            <NumberInput
                label="Number of shares"
                qaid="AddEditTransaction.Input.Shares"
                value={transaction.numberOfShares}
                name="numberOfShares"
                onChange={onInputHandler}
                required={(transaction.warrantsType ?? 0) === WarrantsType.None}
                comment={availableShares}
                error={formValidationState?.numberOfShares?.message}
            />
            <NumberInput
                label={`Price per share (${currency?.symbol})`}
                qaid="AddEditTransaction.Input.PricePerShare"
                value={transaction.pricePerShare}
                name="pricePerShare"
                number="float"
                onChange={(pricePerShare, name) => {
                    setTransaction((prevState) => ({
                        ...prevState,
                        pricePerShare,
                        discountPrice: isCommonShare ? undefined : getDiscountPrice(prevState.discount, pricePerShare),
                    }));
                }}
                required={(transaction.warrantsType ?? 0) === WarrantsType.None || !!transaction.numberOfShares}
                disabled={!isCommonShare}
                error={formValidationState?.pricePerShare?.message}
            />
            <TextInput
                label={`Total cost (${currency?.symbol})`}
                qaid="AddEditTransaction.Input.TotalCost"
                isTotal
                value={formatDecimal((transaction.numberOfShares ?? 0) * (transaction.pricePerShare ?? 0), { decimalLength: 6 })}
            />
            <Select
                label="Award type"
                qaid="AddEditTransaction.Select.AwardType"
                value={transaction.warrantsType}
                options={optionSectionWarrantsTypes}
                name="warrantsType"
                onChange={onInputHandler}
                error={formValidationState?.warrantsType?.message}
                info="You have the option to add warrants if the shareholder received them as part of the share class"
            />
            {transaction.warrantsType !== WarrantsType.None && (
                <>
                    <NumberInput
                        label={transaction.warrantsType === WarrantsType.Bsa ? "Number of BSA" : "Number of warrants"}
                        qaid="AddEditTransaction.Input.Warrants"
                        value={transaction.numberOfWarrants}
                        name="numberOfWarrants"
                        onChange={onInputHandler}
                        comment={getAvailableWarrants()}
                        error={formValidationState?.numberOfWarrants?.message}
                        required={(transaction.warrantsType ?? 0) !== WarrantsType.None}
                    />
                    <NumberInput
                        label="Exercise price"
                        qaid="AddEditTransaction.Input.ExPrice"
                        value={transaction.exPrice}
                        name="exPrice"
                        number="float"
                        onChange={onInputHandler}
                        error={formValidationState?.exPrice?.message}
                        required={(transaction.warrantsType ?? 0) !== WarrantsType.None}
                    />
                </>
            )}
            {!isCommonShare && (
                <>
                    <CheckBox
                        label="Discount"
                        qaid="AddEditTransaction.Input.Discount"
                        name="discountEnabled"
                        isChecked={transaction.discountEnabled}
                        onClick={onInputHandler}
                        style={{ gridArea: "4 / 1 / 4 / 1" }}
                    />
                    <NumberInput
                        label="Rate (%)"
                        qaid="AddEditTransaction.Input.Discount"
                        value={transaction.discount}
                        name="discount"
                        onChange={(discount, name) => {
                            setTransaction((prevState) => ({
                                ...prevState,
                                discount,
                                discountPrice: getDiscountPrice(discount),
                            }));
                        }}
                        percentage
                        disabled={!transaction.discountEnabled}
                        required={!!transaction.discountEnabled}
                        error={formValidationState?.discount?.message}
                        containerStyle={{ gridArea: "4 / 2 / 4 / 2", opacity: transaction.discountEnabled ? 1 : 0 }}
                    />
                    <TextInput
                        label="Price"
                        qaid="AddEditTransaction.Input.DiscountPrice"
                        value={transaction.discountPrice?.toString()}
                        isTotal
                        name="discountPrice"
                        // onChange={(discount, name) => {
                        // 	setTransaction((prevState) => ({
                        // 		...prevState,
                        // 		discount: getDiscount(discount),
                        // 	}));
                        // }}
                        // disabled={!transaction.discountEnabled}
                        disabled
                        // required={!!transaction.discountEnabled}
                        error={formValidationState?.discount?.message}
                        containerStyle={{ gridArea: "4 / 3 / 4 / 3", opacity: transaction.discountEnabled ? 1 : 0 }}
                    />
                </>
            )}
        </>
    );
});

export default IssueTransaction;
