import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const AddShareholderStyle = css({
	label: "AddShareholder",
	flex: 1,
	display: "flex",
	flexDirection: "column",
	padding: "7rem 8rem 4rem",
	height: "100%",
	overflow: "auto",
	"@media screen and (max-width: 768px)": {
		padding: "8rem 4rem 4rem",
	},
	".type-selection": {
		marginBottom: "5rem",
	},
	"&__title": {
		marginBottom: "2rem",
	},
	"&__form": {
		label: "Form",
		"&.isOpen": {
			gridTemplateRows: "1fr 0.33fr 1fr",
		},
	},
	"&__form-row": {
		label: "FormRow",
		display: "grid",
		gridTemplateColumns: "repeat(4, 1fr)",
		gridColumnGap: "2.4rem",
		"&.mt": {
			marginTop: "2rem",
		},
		"@media screen and (max-width: 768px)": {
			gridTemplateColumns: "repeat(3, 1fr)",
		},
	},
	"&__permissions-container": {
		marginTop: "3rem",
		marginBottom: "2rem",
		borderTop: `1px solid ${appConfig.style.colors.table}`,
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		paddingBottom: "2rem",
		paddingTop: "2rem",
	},
});

export default AddShareholderStyle;
