import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import React, { ReactElement, useEffect, useState } from 'react';
import { IC_EDIT2, IC_TRASH2, IC_USER_CIRCLE } from '../../../../Assets';
import AddButton from '../../../../Shared/Components/Button/AddButton';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import Image from '../../../../Shared/Components/Image';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import { Cell } from '../../../../Shared/Components/Table/Table.Style';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { grantTypes } from '../../../../Shared/StaticData/equityPlans';
import { formatNumber } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import useEsopModals from '../../useEsopModals';
import TableActions from '../TableActions/TableActions';
import { IPromisedTable } from '../../../../Models/App/EquityPlans/Promised';
import Button from '../../../../Shared/Components/Button/Button';

const style = css({
	'.actions': {
		opacity: 0,
		visibility: 'hidden',
		position: 'absolute',
		background: '#ffffff',
		transition: 'opacity .3s',
		right: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '1rem',
	},
	'.benName': {
		cursor: 'pointer',
	},
	'&:hover': {
		'.actions': {
			opacity: 1,
			visibility: 'visible',
		},
		'.benName': {
			// transform: "scale(1.05)",
			// textDecoration: "underline",
		},
	},
	'&__ben-cel': {
		'&:hover': {
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
});

interface Props {
	header: ReactElement;
	expand?: boolean;
}

const PromisedTable = ({ header, expand }: Props) => {
	const { equityPlansStore, companyStore: { companyId } } = useRootStore();
	const { openPromisedCard, showDeletePromisedModal, openBeneficiaryModal, showAddNewPromised, openBoardApprovalModal } = useEsopModals();
	const [hoveredMenuId, setHoveredMenuId] = useState<number>(-1);
	const [selctedRows, setSelectedRows] = useState<IPromisedTable[]>([]);

	useEffect(() => {
		setSelectedRows([])
	}, [equityPlansStore.selectedPlan]);

	React.useEffect(() => {
		equityPlansStore.getPromisedsList();
	}, [companyId]);

	const promisedColumns: TableColumn<IPromisedTable>[] = [
		{
			label: 'Grant number',
			name: 'grantNumber',
			sortable: true,
			isOverflow: true,
			render: (prom, gNum: string) => {
				return (
					<Cell style={{ position: 'relative' }} isOverflow>
						<Clickable
							qaid="GrantsTable.Button.GrantNumber"
							className="text-ellipsis"
							applyStyle={hoveredMenuId === prom.promisedId}
							justify="start"
						>
							{gNum}
						</Clickable>
						<TableActions
							cssClass="actions"
							items={[
								{
									label: 'Edit',
									icon: IC_EDIT2,
									qaid: 'PromisedTable.QA.Edit',
									onClick: () => openPromisedCard(prom, true),
								},
								{
									label: 'Delete',
									icon: IC_TRASH2,
									qaid: 'PromisedTable.QA.Delete',
									onClick: async () => {
										const res = await showDeletePromisedModal(prom);
										if (!res) return;
										setSelectedRows((selected) => selected.filter((row) => row.promisedId !== prom.promisedId));
									},
								},
							]}
						/>
					</Cell>
				);
			},
			style: { flex: '1 0 18rem' },
		},
		{
			label: 'Beneficiary',
			name: 'fullName',
			format: (val: { firstName: string; lastName: string }) => `${val?.firstName} ${val?.lastName}`,
			sortable: true,
			// style: { flex: 3 },
			render: (prom, val: { firstName: string; lastName: string }) => {
				return (
					<Cell className={`${style}__ben-cel`} onMouseEnter={() => setHoveredMenuId(-1)} onMouseLeave={() => setHoveredMenuId(prom.promisedId || 0)}>
						{val?.firstName ? (
							<>
								<Image width="3.1rem" src={IC_USER_CIRCLE} alt="user" />
								<span
									className="benName"
									onClick={(e) => {
										e.stopPropagation();
										prom.beneficiaryId && openBeneficiaryModal(prom.beneficiaryId);
									}}
								>{`${val?.firstName}  ${val?.lastName}`}</span>
							</>
						) : (
							<></>
						)}
					</Cell>
				);
			},
			style: { flex: '1 0 16rem' },
		},
		{
			label: 'Grant type',
			name: 'awardTypes',
			sortable: true,
			style: { flex: '1 0 12rem' },
			format(val) {
				return grantTypes[val]?.label;
			},
		},
		{
			label: 'Promised',
			name: 'amountPromised',
			sortType: 'number',
			sortable: true,
			format: (val) => formatNumber(val),
		},
	];

	const onSelectedRow = () => {
		openBoardApprovalModal(selctedRows);
	}

	return (
		<Table
			rowClassName={style}
			columns={promisedColumns}
			scrollAfterRows={expand ? 16 : 7}
			rows={equityPlansStore.promisedRows}
			onRowClick={(obj) => openPromisedCard(obj)}
			searchBy={['grantNumber', 'fullName']}
			exportToExcel={{ fileName: 'Promiseds', sheetName: 'Promiseds' }}
			fullscreen
			renderBottom={
				<Flex align="center" justify="between">
					<AddButton onClick={showAddNewPromised} qaid="PromisedTable.Button.AddNewPromised" label="Add new promised award" />
					<Button disabled={!selctedRows.length} onClick={onSelectedRow} qaid="PromisedTable.Button.Boardapproval" label="Convert to grant" />
				</Flex>
			}
			filterBy={promisedColumns.filter((col) => col.name !== 'actions').map((col) => col.name as keyof IPromisedTable)}
			customHeaderRender={header}
			onRowEnter={(row) => setHoveredMenuId(row.promisedId || 0)}
			onRowLeave={() => setHoveredMenuId(-1)}
			intersectionSettings={{ triggerOnce: false }}
			onRowSelect={(selected) => setSelectedRows(selected as IPromisedTable[])}
			selected={selctedRows}
		/>
	);
};

export default observer(PromisedTable);