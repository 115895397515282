import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import { IC_PLUS_PURPLE, IC_PLUS_WHITE2 } from "../../../Assets";
import { isNullOrUndefined } from "../../Utilities";
import { CSSProperties, forwardRef, useRef } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";
import Tooltip from "../Tooltip";

interface Props {
    qaid: string;
    label: string;
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    width?: string;
    tooltip?: string;
}

const Button = styled.div(
    {
        label: "ExpandButton",
        background: appConfig.style.colors.gradientPurpleVertical,
        border: `1px solid ${appConfig.style.colors.color1}`,
        borderRadius: "0.8rem",
        display: "flex",
        // "position": "absolute",
        // "top": "3rem",
        zIndex: 2,
        // "left": "90rem",
        cursor: "pointer",
        height: "3.6rem",
        width: "3.6rem",
        transition: "width .5s ease",
        float: "left",
        // "overflow": "hidden",
        padding: "0.8rem",
        position: "relative",
        alignItems: "center",
        color: "#ffffff",
        "&:not(.inactive)": {
            color: appConfig.style.colors.color1,
            "&:hover": {
                background: appConfig.style.colors.purple,
                // "> span": {
                // 	display: "block",
                // },
                img: {
                    "&.purple": {
                        display: "block",
                    },
                    "&:not(.purple)": {
                        display: "none",
                    },
                },
            },
        },
        img: {
            // paddingLeft: "0.2rem",
            width: "1.8rem",
            "&.purple": {
                display: "none",
            },
            "&:not(.purple)": {
                display: "block",
            },
        },
        ".arrow": {
            width: 0,
            height: 0,
            borderLeft: "0.8rem solid transparent",
            borderRight: "0.8rem solid transparent",
            borderTop: `0.8rem solid ${appConfig.style.colors.color1}`,
            position: "absolute",
            top: "100%",
            left: "0.95rem",
            // border: "solid black",
            // borderWidth: "0 2px 2px 0",
            // borderColor: appConfig.style.colors.text1,
            // display: "inline-block",
            // padding: 2,
            // height: 2,
            // whiteSpace: "nowrap",
        },
    },
    ({ width, disabled }: Pick<Props, "width" | "disabled">) => ({
        opacity: disabled ? 0.5 : 1,
        // ...(disabled
        // 	? {
        // 			background: "#aaaaaa",
        // 			border: `1px solid #999999`,
        // 			opacity: 0.8,
        // 			"&:hover": {
        // 				color: "#ffffff",
        // 			},
        // 			".arrow": {
        // 				borderTop: `0.8rem solid #999999`,
        // 			},
        // 	  }
        // 	: {}),
        "&:hover": {
            width,
        },
    })
);

const Style = css({
    label: "ExpandButtonText",
    paddingLeft: "1rem",
    // display: "none",
    width: "100%",
    // transition: "all 3s",
});

const ExpandButton = forwardRef<HTMLDivElement, Props>(({ label, onClick, qaid, style, className, disabled, width = "10rem", tooltip }, forwardedRef) => {
    const ButtonElement = (
        <Button
            className={classNames({ inactive: disabled }, { [className || ""]: !!className })}
            style={style}
            data-qaid={qaid}
            onClick={() => !disabled && onClick?.()}
            width={width}
            disabled={disabled}
            ref={forwardedRef}
        >
            <img src={IC_PLUS_WHITE2} alt="add new shareholder" />
            <img src={IC_PLUS_PURPLE} alt="add new shareholder" className="purple" />
            <div className={classNames(Style, "text-ellipsis")}>{label}</div>
            <div className="arrow down" />
        </Button>
    );

    return tooltip ? <Tooltip title={tooltip}>{ButtonElement}</Tooltip> : ButtonElement;
});

export default ExpandButton;
