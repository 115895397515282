import { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { WarrantsTransaction } from "../../../../../../../../Models/App/CapTable/WarrantsTransaction";
import { AppendStateInputHandlerType } from "../../../../../../../../Shared/Hooks/useAppendState";
import useRootStore from "../../../../../../../../Shared/Hooks/useRootStore";
import { formatNumber, isNullOrUndefined } from "../../../../../../../../Shared/Utilities";
import { SelectListItem } from "../../../../../../../../Models/App/SelectListItem";
import _ from "underscore";
import Select from "../../../../../../../../Shared/Components/Select/Select";
import { currencies, optionSectionWarrantsTypes } from "../../../../../../../../Shared/Config";
import { ForwardedRef } from "../../../../../../../../Shared/Hooks/useMultiStepForm";
import { WarrantsType } from "../../../../../../../../Models/API/enums";
import NumberInput from "../../../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../../../Shared/Components/Input/TextInput";
import useExerciseWarrantsValidation from "../../../../../../Hooks/Validations/useExerciseWarrantsValidation";

interface Props {
    transaction: WarrantsTransaction;
    onInputHandler: AppendStateInputHandlerType;
    setTransaction: Dispatch<SetStateAction<WarrantsTransaction>>;
}

const ExcerciseTransaction = forwardRef<ForwardedRef, Props>(({ transaction, onInputHandler, setTransaction }: Props, forwardedRef) => {
    const { shareHolderStore, currency, capTableStore } = useRootStore();
    const [availableAmount, setAvailableAmount] = useState<number>();
    const transacionRef = useRef<WarrantsTransaction>();
    const { validateForm, isFormValid, formValidationState } = useExerciseWarrantsValidation(transaction, availableAmount);

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            const isValid = validateForm({ showErrors: true });
            if (!isValid) return false;
            await shareHolderStore.addUpdateWarrantsTransaction(transaction);
            return true;
        },
    }));

    useEffect(() => {
        if (transaction.transactionId) {
            // if it's edit, save the initial amount to add to the available amount
            transacionRef.current = transaction;
        }
    }, []);

    useEffect(() => {
        getAvilableAmountForAdnRewards();
    }, [transaction.shareClassID, transaction.date, transaction.warrantsType, transaction.exPrice]);

    const expPrices = useMemo(() => {
        if (isNullOrUndefined(transaction.shareClassID) || isNullOrUndefined(transaction.warrantsType)) return [];
        const exPrices = shareHolderStore.shareholderData?.personIssueTransactionList
            ?.filter((x) => x.shareClassID === transaction.shareClassID && x.warrantsTypeEnum === transaction.warrantsType)
            .map((x) => ({
                exPrice: x.warrantsExercisePrice,
                warrants: x.numberOfWarrants,
                transactionId: x.shareHolderIssueTransactionID,
                warrantsType: x.warrantsTypeEnum,
            }));

        if (!exPrices) return [];

        const t = _.groupBy(
            exPrices.filter((x) => (x.warrants ?? 0) > 0),
            "exPrice"
        );

        const items = [];
        for (const [key, value] of Object.entries(t)) {
            items.push({
                label: `${key}`,
                value: parseFloat(key),
                availableAmount: value.map((x) => x.warrants ?? 0).reduce((a, b) => a + b) ?? 0,
            });
        }

        return items;
    }, [transaction.warrantsType, transaction.shareClassID]);

    const getAvilableAmountForAdnRewards = async () => {
        const { shareClassID, date, warrantsType, exPrice } = transaction;
        if (!shareClassID || !date || !warrantsType || !exPrice) return;

        const amount = await capTableStore.getAvailableAmountByTypeAndExPrice(
            shareClassID,
            shareHolderStore.personId,
            warrantsType,
            exPrice,
            date
        );
        if (!amount) return 0;
        setAvailableAmount(amount.availableAmount + (transaction.transactionId ? transacionRef.current?.numberOfWarrants ?? 0 : 0));
    };

    if (!transaction.date || !transaction.shareClassID) return <></>;

    return (
        <>
            <Select
                label="Award type"
                qaid="AddEditTransaction.Select.AwardType"
                value={transaction.warrantsType}
                options={optionSectionWarrantsTypes}
                name="warrantsType"
                onChange={onInputHandler}
                style={{ gridArea: "2 / 1 / 2 / 1" }}
                required
                error={formValidationState?.warrantsType?.message}
            />
            {transaction.warrantsType !== WarrantsType.None && (
                <>
                    <Select
                        qaid="AddEditTransaction.Select.ExPrice"
                        label={`Exercised price (${currency?.symbol})`}
                        value={transaction.exPrice}
                        name="exPrice"
                        options={expPrices}
                        onChange={onInputHandler}
                        style={{ gridArea: "2 / 2 / 2 / 2" }}
                        required
                    />
                    <NumberInput
                        label={transaction.warrantsType === WarrantsType.Bsa ? "Number of BSA" : "Number of warrants"}
                        qaid="AddEditTransaction.Input.Warrants"
                        value={transaction.numberOfWarrants}
                        name="numberOfWarrants"
                        onChange={onInputHandler}
                        disabled={!transaction.exPrice}
                        comment={`Available: ${formatNumber(availableAmount)}`}
                        error={formValidationState?.numberOfWarrants?.message}
                        required
                        containerStyle={{ gridArea: "2 / 3 / 2 / 3" }}
                    />
                    <TextInput
                        label={`Total cost (${currency?.symbol})`}
                        qaid="AddEditTransaction.Input.TotalCost"
                        isTotal
                        value={formatNumber((transaction.numberOfWarrants ?? 0) * (transaction.exPrice ?? 0), false)}
                        required
                        containerStyle={{ gridArea: "2 / 4 / 2 / 4" }}
                    />
                </>
            )}
        </>
    );
});

export default ExcerciseTransaction;
