import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

export const CAPTABLE_CELL_HEIGHT = 40;

const MainTableStyle = css({
    label: "MainCapTable",
    position: "relative",
    height: "100%",
    // "width": "clamp(90rem, 100%, 150rem)",
    // "margin": "auto",
    "&__table-wrapper": {
        overflow: "hidden",
        borderRadius: 16,
        height: "fit-content",
        maxWidth: "100%",
        width: "100%",
        // border: `1px solid ${appConfig.style.colors.field}`,
        boxShadow: appConfig.style.boxShadow.card1,
        border: `1px solid ${appConfig.style.colors.table}`,
        "::-webkit-scrollbar-track": {
            background: appConfig.style.colors.table,
        },
        "&.fullscreen": {
            maxHeight: "calc(100% - 5rem)",
        },
    },
    "&__table": {
        display: "flex",
        boxShadow: appConfig.style.boxShadow.card1,
        transition: "height .3s",
        "> div": {
            display: "flex",
            height: "fit-content",
            background: "#ffffff",
        },
        "&.fullscreen": {
            maxHeight: "calc(100% - 7.2rem)",
        },

        // "&::-webkit-scrollbar": {
        // 	width: 3,
        // 	height: 3,
        // },
    },
    "&__sticky-top": {
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "red",
    },
    "&__sticky-bottom": {
        position: "sticky",
        bottom: 0,
        zIndex: 1,
        background: "orange",
    },
    "&__sticky-left": {
        position: "sticky",
        left: 0,
        zIndex: 2,
        flex: 1,
    },
    "&__sticky-right": {
        position: "sticky",
        right: 0,
        zIndex: 2,
        flex: 4,
    },
    "&__scroll": {
        // overflow: "overlay",
        // overflowY: "hidden",
        height: "100%",
        flex: 5,
    },
    ".MuiCollapse-root, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner": {
        flex: 1,
    },
    "&__col": {
        flex: "1 0 15rem",
        minWidth: "15rem",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "&.wide": {
            // flex: "1 0 21rem",
            minWidth: "21.3rem",
        },
        "&.bs-right": {
            boxShadow: appConfig.style.boxShadow.right,
        },
        "&.bs-left": {
            boxShadow: appConfig.style.boxShadow.left,
        },
    },
    "&__cell": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        // flex: 1,
        background: "#ffffff",
        flex: `0 0 ${CAPTABLE_CELL_HEIGHT / 10}rem`,
        "&:not(.to-left)": {
            justifyContent: "center",
        },
        "&.to-left": {
            padding: "0 1.6rem",
        },
        "&.header": {
            fontWeight: 500,
            flex: `0 0 ${(CAPTABLE_CELL_HEIGHT / 10) * 2.5}rem`, // * 2 because header takes 2 rows
            ".double-header-container": {
                "&.bg-total": {
                    "*": {
                        background: appConfig.style.colors.total,
                    },
                },
                height: "100%",
                "> div": {
                    height: `${CAPTABLE_CELL_HEIGHT / 10}rem`,
                },
            },
        },
    },
    "&__cell-total": {
        background: appConfig.style.colors.total,
        "&.bold": {
            fontWeight: 700,
        },
    },
    "&__actions": {
        display: "flex",
    },
    "&__border-left": {
        borderLeft: `1px solid ${appConfig.style.colors.table}`,
    },
    "&__border-right": {
        borderRight: `1px solid ${appConfig.style.colors.table}`,
    },
    "&__border-top": {
        borderTop: `1px solid ${appConfig.style.colors.table}`,
    },
    "&__border-bottom": {
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
    },
    "&__border-left-thick": {
        borderLeft: `2px solid ${appConfig.style.colors.table}`,
    },
    "&__border-right-thick": {
        borderRight: `1px solid ${appConfig.style.colors.table}`,
    },
    "&__border-top-thick": {
        borderTop: `3px solid ${appConfig.style.colors.table}`,
    },
    "&__border-bottom-thick": {
        borderBottom: `3px solid ${appConfig.style.colors.table}`,
    },
    "&__edit-icon": {
        width: "2rem",
        height: "2rem",
    },
    "&__pin-icon": {
        width: "2rem",
        height: "2rem",
    },
    "&__expand-toggle": {
        padding: "0.8rem",
        img: {
            width: "3rem",
            height: "3rem",
        },
    },
    "&__tooltip": {
        width: "3.6rem",
        height: "3.6rem",
    },
    "&__errors-container": {
        position: "relative",
        ".tooltip-modal": {
            visibility: "hidden",
            opacity: 0,
            // visibility: "visible",
            // opacity: 1,
            zIndex: 10,
            background: "#F4EADF",
            border: `1px solid ${appConfig.style.colors.color4}`,
            position: "absolute",
            left: "calc(100% + 0.8rem)",
            transform: "translate(0, calc(-50% - 2rem))",
            padding: "0 1.4rem",
            borderRadius: 8,
            transition: "all .3s",
            maxWidth: "53.4rem",
            width: "max-content",
            boxShadow: "3px 3px 16px 0px #ABAFC699",
            "> div": {
                display: "flex",
                gap: "0.8rem",
                padding: "3rem 0",
                letterSpacing: "2%",
                "&:not(:first-child)": {
                    borderTop: `1px solid ${appConfig.style.colors.color4}`,
                },
            },
        },
        "&:hover": {
            ".tooltip-modal": {
                visibility: "visible",
                opacity: 1,
            },
        },
    },
    "&__edit-btn": {
        display: "flex",
        alignItems: "center",
    },
    "&__header-title": {
        paddingTop: "0.4rem",
        maxWidth: "calc(100% - 6rem)",
    },
    "&__top-header": {
        userSelect: "none",
        svg: {
            fontSize: "2.5rem",
        },
    },
    ".sh-name": {
        transition: "all 0.1s",
        "&:hover": {
            color: appConfig.style.colors.color1,
            textDecoration: "underline",
        },
    },
});

export default MainTableStyle;
