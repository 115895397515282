import { css } from "@emotion/css";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { createRef, useEffect, useMemo, useState } from "react";
import { NumberOption } from "../../../../../../../Models/API/All/NumberOption";
import { EntityTypeEnum, TransactionTypesEnum, WarrantsType } from "../../../../../../../Models/API/enums";
import { IssueTransaction as IssueTransactionType } from "../../../../../../../Models/App/CapTable/IssueTransaction";
import { SecondaryTransaction as SecondaryTransactionType } from "../../../../../../../Models/App/CapTable/SecondaryTransaction";
import { WarrantsTransaction as WarrantsTransactionType } from "../../../../../../../Models/App/CapTable/WarrantsTransaction";
import Button from "../../../../../../../Shared/Components/Button/Button";
import DatePickerInput from "../../../../../../../Shared/Components/Input/DatePickerInput";
import Flex from "../../../../../../../Shared/Components/Layout/Flex";
import Grid from "../../../../../../../Shared/Components/Layout/Grid";
import Select from "../../../../../../../Shared/Components/Select/Select";
import { ExtraAssetTypes, TransactionEntityType, TransactionType, transactionTypes } from "../../../../../../../Shared/Config";
import { useAppendState } from "../../../../../../../Shared/Hooks/useAppendState";
import { ForwardedRef } from "../../../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../config/config";
import { isCommonClass } from "../../../../../utils/transactions";
import ExcerciseTransaction from "./ExerciseTransaction";
import IssueTransaction from "./IssueTransaction";
import SecondaryTransaction from "./SecondaryTransaction";

const Style = css({
    label: "AddEditTransaction",
    background: "#f9f9ff",
    border: `1px solid ${appConfig.style.colors.table}`,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    padding: "2.4rem 4.4rem",
    position: "relative",
    "&__title": {
        marginBottom: "3rem",
    },
    "&__type-selection": {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "1.6rem",
    },
    "&::before": {
        content: "''",
        bottom: "100%",
        left: "calc(50% - 16px)",
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        borderBottomColor: appConfig.style.colors.table,
        borderWidth: 16,
        position: "absolute",
        top: "100%",
        transform: "rotate(180deg)",
    },
    "&::after": {
        content: "''",
        bottom: "100%",
        left: "calc(50% - 15px)",
        border: "solid transparent",
        height: 0,
        width: 0,
        pointerEvents: "none",
        borderBottomColor: "#f9f9ff",
        borderWidth: 15,
        position: "absolute",
        top: "100%",
        transform: "rotate(180deg)",
    },
});

export const TransactionGridStyle = css({
    label: "TransactionForm",
    display: "grid",
    gridColumnGap: "1.6rem",
    gridRowGap: "3.2rem",
});

interface Props {
    transaction?: IssueTransactionType | SecondaryTransactionType | WarrantsTransactionType;
    onCancel?: () => void;
}

const AddEditTransaction = ({ transaction, onCancel }: Props) => {
    const { capTableStore, shareHolderStore } = useRootStore();
    const [type, setType] = useState<TransactionType>();
    const transactionRef = createRef<ForwardedRef>();
    const [issueTransaction, setIssueTransaction, onIssueInputHandler] = useAppendState<IssueTransactionType>();
    const [secTransaction, setSecTransaction, onSecInputHandler] = useAppendState<SecondaryTransactionType>();
    const [exTransaction, setExTransaction, onExInputHandler] = useAppendState<WarrantsTransactionType>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isIssue = type === TransactionTypesEnum.IssueTransaction;
    const isSecondary = type === TransactionTypesEnum.SecondaryTransaction;
    const isExcercise = type === TransactionTypesEnum.ExerciseWarrantsTransaction;

    const assetOptions: NumberOption[] = useMemo(() => {
        const arr =
            type === TransactionTypesEnum.IssueTransaction
                ? [
                      { value: ExtraAssetTypes.ConvertibleLoan as number, label: "Convertable loan" },
                      { value: ExtraAssetTypes.SAFE as number, label: "SAFE" },
                  ]
                : [];
        if (isNullOrUndefined(capTableStore.project) || isNullOrUndefined(capTableStore.project.shareClassesList)) return arr;

        return [
            ...arr,
            ...capTableStore.project.shareClassesList.map((x) => ({
                label: x.shareClass.shareClass ?? "",
                value: x.shareClass.shareClassID ?? 0,
            })),
        ];
    }, [capTableStore.project, type]);

    useEffect(() => {
        return () => {
            setIssueTransaction({} as IssueTransactionType);
            setSecTransaction({
                entityType: EntityTypeEnum.Buyer,
                warrantsType: WarrantsType.None,
                buyerId: shareHolderStore.projectPersonId,
            } as SecondaryTransactionType);
            setExTransaction({
                warrantsType: WarrantsType.None,
            } as WarrantsTransactionType);
        };
    }, []);

    useEffect(() => {
        // buyerId: transaction.entityType === EntityTypeEnum.Seller ? secondPerson.current?.projectPersonID : transaction.buyerId,
        // sellerId: transaction.entityType === EntityTypeEnum.Seller ? transaction.sellerId : secondPerson.current?.projectPersonID,
        if (isNullOrUndefined(transaction)) return;
        if (transaction.typeId === TransactionTypesEnum.IssueTransaction) {
            setIssueTransaction(transaction as IssueTransactionType);
            setType(TransactionTypesEnum.IssueTransaction);
        } else if (transaction.typeId === TransactionTypesEnum.SecondaryTransaction) {
            const t = transaction as SecondaryTransactionType;
            setSecTransaction({
                ...transaction,
                // sellerId: t.entityType === EntityTypeEnum.Seller ? shareHolderStore.projectPersonId : t.buyerId,
                // buyerId: t.entityType === EntityTypeEnum.Seller ? t.sellerId : shareHolderStore.projectPersonId,
            } as SecondaryTransactionType);
            setType(TransactionTypesEnum.SecondaryTransaction);
        } else if (transaction.typeId === TransactionTypesEnum.ExerciseWarrantsTransaction) {
            setExTransaction(transaction as WarrantsTransactionType);
            setType(TransactionTypesEnum.ExerciseWarrantsTransaction);
        }
    }, [transaction]);

    const onTypeChangeHandler = (value: number) => {
        setType(value);
        setIssueTransaction({} as IssueTransactionType);
        setSecTransaction({
            entityType: EntityTypeEnum.Buyer,
            warrantsType: WarrantsType.None,
            buyerId: shareHolderStore.projectPersonId,
        } as SecondaryTransactionType);
        setExTransaction({
            warrantsType: WarrantsType.None,
        } as WarrantsTransactionType);
    };

    const onSaveHandler = async () => {
        setIsLoading(true);
        const isValid = await transactionRef.current?.onValidate();
        setIsLoading(false);
        if (!isValid) return;
        onCancel?.();
    };

    const cols = isExcercise || (isIssue && issueTransaction?.assetId === ExtraAssetTypes.ConvertibleLoan) ? 4 : isSecondary ? 5 : 3;

    return (
        <div className={Style}>
            <div className={classNames(`${Style}__title`, "bold")}>Add New Transaction</div>
            <Grid
                className={`${Style}__type-selection ${TransactionGridStyle}`}
                cols={cols}
            >
                <Select
                    label="Transaction type"
                    qaid="AddEditTransaction.Select.Type"
                    options={transactionTypes(true)}
                    value={type}
                    onChange={onTypeChangeHandler}
                    required
                />

                {type && (
                    <>
                        {isSecondary ? (
                            <Select
                                label="Choose shareholder side"
                                qaid="AddEditTransaction.Select.ShareholderSide"
                                value={secTransaction.entityType}
                                name="entityType"
                                onChange={(entityType) => {
                                    setSecTransaction((prevState) => ({
                                        ...prevState,
                                        entityType,
                                        sellerId:
                                            entityType === EntityTypeEnum.Seller ? shareHolderStore.projectPersonId : prevState.buyerId,
                                        buyerId:
                                            entityType === EntityTypeEnum.Seller ? prevState.sellerId : shareHolderStore.projectPersonId,
                                    }));
                                }}
                                options={TransactionEntityType}
                                info="Choose if the current shareholder that you are editing is a buyer or a seller in the secondary transaction"
                            />
                        ) : (
                            <Select
                                label="Asset class"
                                qaid="AddEditTransaction.Select.Class"
                                disabled={issueTransaction.transactionId ? true : false}
                                value={isIssue ? issueTransaction.assetId : exTransaction.shareClassID}
                                onChange={(assetId) => {
                                    if (isIssue) {
                                        if (isCommonClass(capTableStore.project?.shareClassesList, assetId)) {
                                            setIssueTransaction((prevState) => ({
                                                ...prevState,
                                                assetId,
                                                warrantsType: WarrantsType.None,
                                                date: undefined,
                                            }));
                                        } else {
                                            setIssueTransaction((prevState) => ({
                                                ...prevState,
                                                assetId,
                                                warrantsType: WarrantsType.None,
                                            }));
                                        }
                                    } else if (isExcercise) {
                                        setExTransaction((prevState) => ({
                                            ...prevState,
                                            shareClassID: assetId,
                                            exPrice: undefined,
                                        }));
                                    }
                                }}
                                options={assetOptions}
                                required
                                scrollAfter={5}
                                info="To issue shares to a shareholder per class (beside the common class), you will need first to define the class with the add class button on the cap table"
                            />
                        )}
                        <DatePickerInput
                            disabled={
                                isIssue &&
                                ![ExtraAssetTypes.ConvertibleLoan, ExtraAssetTypes.SAFE].includes(issueTransaction?.assetId ?? 0) &&
                                !isCommonClass(capTableStore.project?.shareClassesList, issueTransaction.assetId)
                            }
                            label="Date"
                            qaid="AddEditTransaction.Select.Date"
                            value={
                                isIssue
                                    ? issueTransaction.date
                                    : isSecondary
                                    ? secTransaction.date
                                    : isExcercise
                                    ? exTransaction.date
                                    : undefined
                            }
                            name="date"
                            onChange={
                                isIssue ? onIssueInputHandler : isSecondary ? onSecInputHandler : isExcercise ? onExInputHandler : undefined
                            }
                            maxDate={issueTransaction.assetId === ExtraAssetTypes.ConvertibleLoan ? undefined : new Date()}
                            required
                        />
                    </>
                )}
                {isIssue && (
                    <IssueTransaction
                        ref={transactionRef}
                        transaction={issueTransaction}
                        onInputHandler={onIssueInputHandler}
                        setTransaction={setIssueTransaction}
                    />
                )}
                {isSecondary && (
                    <SecondaryTransaction
                        ref={transactionRef}
                        transaction={secTransaction}
                        onInputHandler={onSecInputHandler}
                        setTransaction={setSecTransaction}
                        onCancel={onCancel}
                    />
                )}
                {isExcercise && (
                    <ExcerciseTransaction
                        ref={transactionRef}
                        transaction={exTransaction}
                        onInputHandler={onExInputHandler}
                        setTransaction={setExTransaction}
                    />
                )}
            </Grid>

            {((issueTransaction.date && issueTransaction.assetId) ||
                secTransaction.date ||
                (exTransaction.date && exTransaction.shareClassID)) && (
                <Flex
                    justify="end"
                    margin="4rem 0 0 0"
                    gap="1.2rem"
                >
                    <Button
                        qaid="AddEditTransaction.Button.Cancel"
                        onClick={onCancel}
                        label="Cancel"
                        cancel
                    />
                    <Button
                        qaid="AddEditTransaction.Button.Add"
                        onClick={onSaveHandler}
                        label={
                            issueTransaction.transactionId || secTransaction.transactionId || exTransaction.transactionId ? "Update" : "Add"
                        }
                        isLoading={isLoading}
                        disabled={isExcercise && exTransaction.warrantsType === WarrantsType.None}
                    />
                </Flex>
            )}
        </div>
    );
};

export default observer(AddEditTransaction);
