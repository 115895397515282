import { useHistory } from 'react-router-dom';
import Button from '../../../../Shared/Components/Button/Button';
import { IC_DOCUMENT_PDF, IC_DOCUMENT_PEN } from '../../../../Assets';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { GrantDocumentStatus, TrusteeGrantDocument } from '../../Types/trusteeTypes';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../../Shared/Utilities';
import useTrusteeModals from '../../useTrusteeModals';
import { css } from "@emotion/css";
import Tab from '../../../../Shared/Components/Tab';
import appConfig from '../../../../config/config';

const Style = css({
    label: "GrantsDocumentTable",

    "&__mainContainer": {
        padding: '0 10rem'
    },

    "&__pageBody": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    "&__pageHeader": {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '3rem 0',
    },

    '&__headerMain': {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #D0D4E4',
        width: '100%',
        paddingBottom: '1rem',

        '> h3': {
            alignSelf: 'self-end',
            margin: '0px',
            padding: '0 0 0 3rem',
        },

        '> img': {
            height: '4rem',
        },
    },

    "&__button": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },

    "&__backButton": {
        marginRight: '6rem',
    },

    "&__tabs": {
        borderBottom: `1px solid ${appConfig.style.colors.border1}`,
        display: 'flex',
        width: '80%'
    }
});

const TrusteeGrantDocuments = () => {
    const history = useHistory();
    const { trusteeStore } = useRootStore();
    const { showSuccessModal } = useTrusteeModals();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [documentsId, setDocumentsId] = useState<string[]>([]);
    const [signing, setSigning] = useState(false);

    const First = (props: { click: TrusteeGrantDocument }) => {
        const iconClickHandler = async () => {
            const res: any = await trusteeStore.GetSingleTrusteeGrantDocument(props.click.documentId);
            if (!res.data) return;

            const file = {
                base64: 'data:application/pdf;base64,' + res.data.documentBytes,
                fileName: props.click.beneficiaryName + '_' + props.click.grantNumber + '_' + props.click.grantDate + '.pdf'
            }

            const downloadLink = document.createElement("a");
            downloadLink.href = file.base64;
            downloadLink.download = file.fileName;
            downloadLink.click();
        }

        return (
            <div style={{ cursor: 'pointer' }}>
                <img src={IC_DOCUMENT_PDF} width={20} onClick={iconClickHandler} />
            </div>
        )
    }

    const documentsTable: TableColumn<TrusteeGrantDocument>[] = [
        {
            name: 'beneficiaryName',
            label: 'Full name',
            style: { flex: '1 0 20rem' },
            sortable: true,
        },
        {
            name: 'idSSN',
            label: 'ID/SSN',
            style: { flex: '1 0 13rem' },
            sortable: true,
        },
        {
            name: 'grantDate',
            label: 'Grant date',
            format: (v) => formatDate(v),
            style: { flex: '1 0 13rem' },
            sortable: true,
        },
        {
            name: 'grantNumber',
            label: 'Grant number',
            style: { flex: '1 0 13rem' },
            sortable: true,
            sortType: 'number',
        },
        {
            name: 'grantType',
            label: 'Grant type',
            style: { flex: '1 0 13rem' },
            sortable: true,
        },
        {
            name: 'exercisePrice',
            label: 'Exercise price',
            style: { flex: '1 0 13rem' },
            sortable: true,
            sortType: 'number',
        },
        {
            name: 'quantity',
            label: 'Quantity',
            style: { flex: '1 0 13rem' },
            sortable: true,
            sortType: 'number',
        },
        {
            name: 'actions',
            label: 'Document',
            render: (e, id) => <First click={e} />,
            style: { flex: '1 0 13rem' },
        },
    ];

    const documentSignHandler = async () => {
        setSigning(true);
        const res = await trusteeStore.PostTrusteeGrantDocumentsSignage(documentsId);

        if (res.data) {
            showSuccessModal('Grant letters signed sucssesfully');
            setDocumentsId([]);
            trusteeStore.GetTrusteeGrantDocuments(selectedTab);
        }

        setSigning(false);
    }

    const documentSelectHandler = (e: any) => {
        const d = e as TrusteeGrantDocument[];
        const f = d.map(doc => doc.documentId);
        setDocumentsId(f);
    }

    useEffectOnce(() => {
        trusteeStore.GetTrusteeGrantDocuments(GrantDocumentStatus.Pending);
    });

    useEffect(() => {
        trusteeStore.GetTrusteeGrantDocuments(selectedTab);
    }, [selectedTab]);

    const tabs = <div className={`${Style}__tabs`}>
        <Tab
            label="Pending"
            onClick={() => setSelectedTab(GrantDocumentStatus.Pending)}
            isActive={selectedTab === GrantDocumentStatus.Pending}
            qaid="grantDocuments.tab.Pending"
        />
        <Tab
            label="Pending employee signature"
            onClick={() => setSelectedTab(GrantDocumentStatus.PendingEmployee)}
            isActive={selectedTab === GrantDocumentStatus.PendingEmployee}
            qaid="grantDocuments.tab.PendingEmployee"
        />
        <Tab
            label="Signed"
            onClick={() => setSelectedTab(GrantDocumentStatus.Signed)}
            isActive={selectedTab === GrantDocumentStatus.Signed}
            qaid="grantDocuments.tab.Signed"
        />
    </div>;

    return (
        <div className={`${Style}__mainContainer`}>
            <div className={`${Style}__pageBody`}>
                <div className={`${Style}__pageHeader`}>
                    <Button
                        qaid='trustee.orders.back'
                        label='Back'
                        inverse
                        cancel
                        onClick={() => history.push('/trustee')}
                        className={`${Style}__backButton`}
                    />
                    <div className={`${Style}__headerMain`} >
                        <img src={IC_DOCUMENT_PEN} />
                        <h3>Grant documents</h3>
                    </div>
                </div>

                {selectedTab === GrantDocumentStatus.Pending ?
                    <Table
                        columns={documentsTable}
                        rows={trusteeStore.trusteeGrantDocuments}
                        onRowSelect={documentSelectHandler}
                        customHeaderRender={tabs}
                        filterBy={documentsTable.filter((col) => col.name !== 'actions').map((col) => col.name as keyof TrusteeGrantDocument)}
                        searchBy={['grantNumber', 'beneficiaryName', 'idSSN', 'grantDate', 'grantType']}
                    />
                    : <Table
                        columns={documentsTable}
                        rows={trusteeStore.trusteeGrantDocuments}
                        customHeaderRender={tabs}
                        filterBy={documentsTable.filter((col) => col.name !== 'actions').map((col) => col.name as keyof TrusteeGrantDocument)}
                    />}

                {selectedTab === GrantDocumentStatus.Pending &&
                    <div className={`${Style}__button`}>
                        <Button
                            qaid='trustee.orders.back'
                            label='Sign'
                            onClick={documentSignHandler}
                            disabled={documentsId.length == 0}
                            isLoading={signing}
                        />
                    </div>}
            </div>
        </div>
    )
}

export default observer(TrusteeGrantDocuments);