import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Select from "../../../../../Shared/Components/Select/Select";
import Table, { TableColumn } from "../../../../../Shared/Components/Table/Table";
import { Cell, Row } from "../../../../../Shared/Components/Table/Table.Style";
import { optionSectionAwardTypes } from "../../../../../Shared/Config";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { formatDate, formatNumber, getUniqueId, isNullOrUndefined } from "../../../../../Shared/Utilities";
import { GrantFields } from "../helpers/types";
import { SelectListItem } from "../../../../../Models/App/SelectListItem";
import { useTranslation } from "react-i18next";

interface EditableGrantFields extends GrantFields {
    id: string;
}

const GrantsTableEdit = () => {
	const { equityPlansStore } = useRootStore();
	const [data, setData] = useState<EditableGrantFields[]>();
	const { t } = useTranslation();

	const translateLabel = (array: SelectListItem<any>[], value: any): any => {
		if (value === false) {
			return array.map((item: SelectListItem<any>) => ({ ...item, label: t(item.label) }));
		}

		const item = array.find((item: SelectListItem<any>) => item.value === value);
		if (!item) return null;

		return { ...item, label: t(item.label) };
	};

	const [tableColumn, setTableColumn] = useState<TableColumn<EditableGrantFields>[]>([
		{
			name: 'firstName',
			label: 'First Name',
			inlineEdit(row) {
				return (
					<TextInput
						value={row.firstName}
						name="firstName"
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.FirstName"
						flat
					/>
				);
			},
		},
		{
			name: 'lastName',
			label: 'Last Name',
			inlineEdit(row) {
				return (
					<TextInput
						value={row.lastName}
						name="lastName"
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.LastName"
						flat
					/>
				);
			},
		},
		{
			name: 'email',
			label: 'Email',
			inlineEdit(row) {
				return (
					<TextInput value={row.email} name="email" onChange={(value, name) => onCellUpdate(row.id, name!, value)} qaid="EditTable.Text.Email" flat />
				);
			},
		},
		{
			name: 'employeeNumber',
			label: 'Employee Number',
			inlineEdit(row) {
				return (
					<TextInput
						value={row.employeeNumber}
						name="employeeNumber"
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.EmployeeNumber"
						flat
					/>
				);
			},
		},
		{
			name: 'awardType',
			label: 'Award Type',
			format(val, obj) {
				return translateLabel(optionSectionAwardTypes, obj?.awardType)?.label || val;
			},
			inlineEdit(row) {
				return (
					<Select
						value={row.awardType}
						name="awardType"
						options={optionSectionAwardTypes}
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.AwardType"
						autoTranslate
						flat
					/>
				);
			},
		},
		{
			name: 'grantDate',
			label: 'Grand Date',
			format(val) {
				return formatDate(val);
			},
			inlineEdit(row) {
				return (
					<DatePickerInput
						value={row.grantDate}
						name="grantDate"
						onChange={(value, name) => onCellUpdate(row.id, name!, value?.toString())}
						qaid="EditTable.Input.GrantDate"
						flat
					/>
				);
			},
		},
		{
			name: 'numberOfOptions',
			label: 'Number of Options',
			format(val) {
				return formatNumber(val);
			},
			inlineEdit(row) {
				return (
					<NumberInput
						value={row.numberOfOptions}
						name="numberOfOptions"
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.NumberOfOptions"
						flat
					/>
				);
			},
		},
		{
			name: 'exercisePrice',
			label: 'Exercise price',
			format(val) {
				return formatNumber(val);
			},
			inlineEdit(row) {
				return (
					<NumberInput
						value={row.exercisePrice}
						name="exercisePrice"
						onChange={(value, name) => onCellUpdate(row.id, name!, value)}
						qaid="EditTable.Text.ExercisePrice"
						flat
					/>
				);
			},
		},
		{
			name: 'vestingStartDate',
			label: 'Vesting Start Date',
			format(val) {
				return formatDate(val);
			},
			inlineEdit(row) {
				return (
					<DatePickerInput
						value={row.vestingStartDate}
						name="vestingStartDate"
						onChange={(value, name) => onCellUpdate(row.id, name!, value?.toString())}
						qaid="EditTable.Input.VestingStartDate"
						flat
					/>
				);
			},
		},
		{
			name: 'vestingSchedule',
			label: 'Vesting Schedule',
			format(val) {
				return formatDate(val);
			},
			inlineEdit(row) {
				return (
					<DatePickerInput
						value={row.vestingSchedule}
						name="vestingSchedule"
						onChange={(value, name) => onCellUpdate(row.id, name!, value?.toString())}
						qaid="EditTable.Input.VestingSchedule"
						flat
					/>
				);
			},
		},
		{
			name: 'expirationDate',
			label: 'Expiration Date',
			format(val) {
				return formatDate(val);
			},
			inlineEdit(row) {
				return (
					<DatePickerInput
						value={row.expirationDate}
						name="expirationDate"
						onChange={(value, name) => onCellUpdate(row.id, name!, value?.toString())}
						qaid="EditTable.Input.ExpirationDate"
						flat
					/>
				);
			},
		},
		{
			name: 'taxTrack',
			label: 'Tax Track',
		},
	]);

	useEffect(() => {
		const array: EditableGrantFields[] = equityPlansStore.customImportData.map((row) => {
			return tableColumn.reduce(
				(acc, field, idx) => {
					const formattedValue = equityPlansStore.importFields.find((f) => f.name === field.name)?.formatValue?.(row[idx]) ?? row[idx];
					return { ...acc, [field.name as string]: formattedValue };
				},
				{
					id: getUniqueId(),
				} as EditableGrantFields
			);
		});
		console.log('TCL: GrantsTableEdit -> array', array);
		setData(array);
	}, [tableColumn]);

	// useEffect(() => {
	// 	console.log("data", data);
	// }, [data]);

	const onCellUpdate = (rowId: string, name: string, value: string | number | undefined) => {
		setData((prevState) => {
			if (isNullOrUndefined(prevState)) return;
			const rowIdx = prevState.findIndex((r) => rowId === r.id);
			return [
				...prevState.slice(0, rowIdx),
				{
					...prevState[rowIdx],
					[name]: value,
				},
				...prevState.slice(rowIdx + 1),
			];
		});
	};

	const onDragEnd = (result: any) => {
		const to = result.destination.index;
		const from = result.source.index;
		setTableColumn((prevState) => {
			const arr = prevState.concat([]);
			const oldStep = arr.splice(from, 1)[0];
			arr.splice(to, 0, oldStep);
			return arr;
		});
	};

	console.log('TCL: GrantsTableEdit -> customImportData', equityPlansStore.customImportData);
	return (
		<Table
			columns={tableColumn}
			rows={data}
			scrollAfterRows={20}
			className="w-100"
			customHeaderRender={<span>* Double click the cell to edit</span>}
			renderHeader={
				<Row header>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="grantsTable" type="COLUMN" direction="horizontal">
							{(provided) => (
								<Flex ref={provided.innerRef} {...provided.droppableProps}>
									{tableColumn.map((field, index) => (
										<Draggable draggableId={field.name as string} index={index} key={field.name as string}>
											{(provided, snapshot) => (
												<Cell
													border={{ top: true, right: true }}
													style={{
														flex: '0 0 54px',
														minWidth: 54,
														maxWidth: 54,
													}}
													className="text-ellipsis"
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													{field.label}
												</Cell>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Flex>
							)}
						</Droppable>
					</DragDropContext>
				</Row>
			}
		/>
	);
};

export default GrantsTableEdit;
