import { useHistory } from 'react-router-dom';
import Button from '../../../../Shared/Components/Button/Button';
import { IC_LOOKUP, IC_TRUSTEE_DOWNLOAD, IC_TRUSTEE_REPORT, IC_TRUSTEE_RESET, IC_TRUSTEE_VIEW } from '../../../../Assets';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { ReportParamsType, TrusteeManagerReport } from '../../Types/trusteeTypes';
import { useEffectOnce } from 'react-use';
import { PivotalReportTypeRequest, ReportQueryParams, TrusteeReportParams } from '../../Types/ReqRes';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import Tab from '../../../../Shared/Components/Tab';
import appConfig from '../../../../config/config';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import DatePickerInput from '../../../../Shared/Components/Input/DatePickerInput';
import Select from '../../../../Shared/Components/Select/Select';
import { NumberOption } from '../../../../Models/API/All/NumberOption';
import { isNumber } from '../../../../Shared/Utilities';
import Tooltip from '../../../../Shared/Components/Tooltip';
import useTrusteeModals from '../../useTrusteeModals';
import { CircularProgress } from '@mui/material';

const Style = css({
    label: "GrantsDocumentTable",

    "&__mainContainer": {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1fr',
    },

    "&__btnHeader": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '3rem 8rem 0 0',
    },

    "&__pageBody": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    "&__pageHeader": {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '3rem 0',
    },

    '&__headerMain': {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        width: '100%',
        paddingBottom: '1rem',

        '> h3': {
            alignSelf: 'self-end',
            margin: '0px',
            padding: '0 0 0 3rem',
        },

        '> img': {
            height: '4rem',
        },
    },

    "&__tabs": {
        borderBottom: `1px solid ${appConfig.style.colors.border1}`,
        display: 'flex',
        width: '70%'
    },

    "&__headerTable": {
        display: 'flex',
        marginBottom: '2rem',
        justifyContent: 'space-between'
    },

    "&__wrapTable": {
        width: '50%',
        margin: '0 1rem'
    },

    "&__card": {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
        borderRadius: '16px',
        boxShadow: `0px 4px 8px ${appConfig.style.colors.boxShadowColor1}`,
        border: `1px solid ${appConfig.style.colors.table}`,
        height: '100%',
        maxHeight: '58rem',
    },

    "&__headerCard": {
        background: appConfig.style.colors.purple,
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: '0 1.6rem',
        height: '7rem',
        display: 'flex',

        "> span": {
            fontWeight: 500,
            margin: 'auto 0',
        }
    },

    "&__bodyCard": {
        height: '100%',
        borderTop: `1px solid ${appConfig.style.colors.table}`,
    },

    "&__actionsCard": {
        height: '45px',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        display: 'flex',
        flexDirection: 'row-reverse',

        "> img": {
            margin: '0 5px',
            cursor: 'pointer'
        }
    },

    '&__selected': {
        color: appConfig.style.colors.color1,
        boxShadow: `4px 0px 15px #4D4D4D40`,
        transition: "box-shadow .3s",
    },

    "&__filterCard": {
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',

        "> span": {
            color: '#979DB5',
            marginBottom: '5px'
        }
    },
});

const resetFilters = [
    { key: 'Grant_Cancelation.Cancelation_Date', value: '', value2: '' },
    { key: 'Order_.Order_Type', value: '', value2: '' },
    { key: 'Grant_.Award_Types', value: '', value2: '' },
    { key: 'Order_.Tax_Plan', value: '', value2: '' },
    { key: 'Order_.Sale_Date', value: '', value2: '' },
    { key: 'Grant_Event_Date', value: '', value2: '' },
    { key: 'Grant_.Grant_Status', value: '', value2: '' },
    { key: 'Grant_Events.Grant_Event_Date', value: '', value2: '' },
    { key: 'Grant_.Tax_Track', value: '', value2: '' },
    { key: 'Grant_.Grant_Date', value: '', value2: '' },
    { key: 'Grant_Letter.Manager_Sign_Date', value: '', value2: '' },
    { key: 'Grant_Letter.Participant_Sign_Date', value: '', value2: '' },
    { key: 'Contact.Full_Name', value: '', value2: '' },
    { key: 'Company_Site.Site_Name', value: '', value2: '' },
    { key: 'Contact.Employment_Status', value: '', value2: '' },
    { key: 'ISOVestingView.grant_date', value: '', value2: '' },
];

enum reportTabs {
    General,
    BusinessPartners
}

const TrusteeReports = () => {
    const history = useHistory();
    const { trusteeStore } = useRootStore();
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [tableData, setTableData] = useState<TrusteeManagerReport[]>([]);
    const [selectedRow, setSelectedRow] = useState<TrusteeManagerReport>();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<{ key: string, value: string | Date | undefined | number, value2: string | Date | undefined | number }[]>(resetFilters);
    const { showReportPreviewModal } = useTrusteeModals();

    useEffect(() => {
        setTableData(trusteeStore.trusteeManagerReports)
    }, [trusteeStore.trusteeManagerReports]);

    const reportsTableColumns: TableColumn<TrusteeManagerReport>[] = [
        {
            name: 'reportNameEng',
            label: 'Report name',
            sortable: true,
        },
    ];

    useEffectOnce(() => {
        trusteeStore.GetTrusteeManagerReports();
    });

    const getReport = async (report: TrusteeManagerReport, type: PivotalReportTypeRequest) => {
        if (!report) return;

        const filtersToSend = filters.filter(filter => filter.value !== '');

        let repo: TrusteeReportParams = {
            reportName: report.reportName,
            requestType: type,
            SearchParamsJson: '',
            numberOfItemsInPage: 5000,
            pageNumber: 1,
        };

        let combineParameters: ReportQueryParams[] = [];

        filtersToSend.map(filter => {
            const match: any = report.reportParams.find(parem => parem.key === filter.key);
            combineParameters.push({
                key: match.key,
                type: match.type,
                isAsOfDate: match.isAsOfDate,
                value: isNumber(filter.value) ? report.reportParams.find(item => item.key === filter.key)?.comboOptions[filter.value] : filter.value,
                value2: filter.value2
            });
        });

        const includeAsOfDate = combineParameters.find(item => item.isAsOfDate === true);
        if (!includeAsOfDate) {
            report.reportParams.map(param => {
                if (param.isAsOfDate === true) {
                    combineParameters.push({
                        key: param.key,
                        type: param.type,
                        isAsOfDate: param.isAsOfDate,
                        value: new Date(),
                        value2: ''
                    })
                }
            });
        }

        repo.SearchParamsJson = JSON.stringify(combineParameters);

        setLoading(true);
        const res = await trusteeStore.GenerateTrusteeReport(repo);

        if (res) {
            if (type === PivotalReportTypeRequest.excel) {
                const ggg = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                const base = ggg + res.reportString;
                saveAs(base, (res.reportName + '.' + res.reportExt));
            }
            else {
                const dataToView = JSON.parse(res.reportString).Report;
                !!dataToView && showReportPreviewModal(dataToView, report.reportNameEng)
            }
        }
        setLoading(false);
    }

    const tabs = <div className={`${Style}__tabs`}>
        <Tab
            label="General"
            onClick={() => setSelectedTab(reportTabs.General)}
            isActive={selectedTab === reportTabs.General}
            qaid="grantReports.tab.General"
        />
        <Tab
            label="Business partners"
            // onClick={() => setSelectedTab(reportTabs.BusinessPartners)}
            isActive={selectedTab === reportTabs.BusinessPartners}
            qaid="grantDocuments.tab.BusinessPartners"
        />
    </div>;

    useEffect(() => {
        if (!!trusteeStore.trusteeManagerReports.length) {
            setTableData(trusteeStore.trusteeManagerReports.filter(row => row.reportName.toLowerCase().includes(searchValue.toLowerCase())));
        }
    }, [searchValue]);

    const getFilter = (key: string, param: ReportParamsType) => {
        switch (key) {
            case 'Grant_Cancelation.Cancelation_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value2: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            case 'Order_.Order_Type':
                const orderTypesOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={orderTypesOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Grant_.Award_Types':
                const awardTypesOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={awardTypesOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Order_.Tax_Plan':
                const taxPlanOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={taxPlanOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Order_.Sale_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value2: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            case 'Grant_Event_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="EditGrant.Input.GrantDate"
                        // minDate={grantMinDate}
                        // maxDate={grant.experationDate}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        // error={formValidationState?.grantDate?.message}
                        // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                        containerClassName="mb-0"
                    />
                </div>;

            case 'Grant_.Grant_Status':
                const grantStatusOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={grantStatusOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Grant_Events.Grant_Event_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="EditGrant.Input.GrantDate"
                        // minDate={grantMinDate}
                        // maxDate={grant.experationDate}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        // error={formValidationState?.grantDate?.message}
                        // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                        containerClassName="mb-0"
                    />
                </div>;

            case 'Grant_.Tax_Track':
                const grantTaxOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={grantTaxOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Grant_.Grant_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value2: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            case 'Grant_Letter.Manager_Sign_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="EditGrant.Input.GrantDate"
                        // minDate={grantMinDate}
                        // maxDate={grant.experationDate}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        // error={formValidationState?.grantDate?.message}
                        // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                        containerClassName="mb-0"
                    />
                </div>;

            case 'Grant_Letter.Participant_Sign_Date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="EditGrant.Input.GrantDate"
                        // minDate={grantMinDate}
                        // maxDate={grant.experationDate}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        // error={formValidationState?.grantDate?.message}
                        // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                        containerClassName="mb-0"
                    />
                </div>;

            case 'Contact.Full_Name':
                const nameOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={nameOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Company_Site.Site_Name':
                const siteNameOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={siteNameOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'Contact.Employment_Status':
                const statusOptions: NumberOption[] = param.comboOptions.map((item, idx) => { return { value: idx, label: item } })
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <Select
                        qaid="EditGrant.Input.TaxTrack"
                        options={statusOptions}
                        value={filters.find(item => item.key === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case 'ISOVestingView.grant_date':
                return <div className={`${Style}__filterCard`}>
                    <span>{param.hedderEng}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="EditGrant.Input.GrantDate"
                            // minDate={grantMinDate}
                            // maxDate={grant.experationDate}
                            value={filters.find(item => item.key === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.key === key ? { ...item, value2: value } : item));
                            }}
                            // error={formValidationState?.grantDate?.message}
                            // ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            default:
                break;
        }
    }

    const actions = () => {
        if (!selectedRow) return;

        return <div>
            {selectedRow.reportParams.map(param => getFilter(param.key, param))}
            <div className={`${Style}__actionsCard`}>
                <Tooltip title={'Download'}>
                    {!loading ? <img
                        width={18}
                        src={IC_TRUSTEE_DOWNLOAD}
                        onClick={() => getReport(selectedRow, PivotalReportTypeRequest.excel)}
                    /> : <CircularProgress size={18} style={{ margin: 'auto 5px' }} />}
                </Tooltip>
                <Tooltip title={'View'}>
                    <img
                        width={20}
                        src={IC_TRUSTEE_VIEW}
                        onClick={() => getReport(selectedRow, PivotalReportTypeRequest.json)}
                    />
                </Tooltip>
                <Tooltip title={'Reset'}>
                    <img
                        width={15}
                        src={IC_TRUSTEE_RESET}
                        onClick={() => [setFilters(resetFilters)]}
                    />
                </Tooltip>
            </div>
        </div>
    };

    return (
        <div className={`${Style}__mainContainer`}>
            <div className={`${Style}__btnHeader`}>
                <Button
                    qaid='trustee.orders.back'
                    label='Back'
                    inverse
                    cancel
                    onClick={() => history.push('/trustee')}
                />
            </div>
            <div className={`${Style}__pageBody`}>
                <div className={`${Style}__pageHeader`}>
                    <div className={`${Style}__headerMain`}>
                        <img src={IC_TRUSTEE_REPORT} />
                        <h3>Reports</h3>
                    </div>
                </div>
                <div className={`${Style}__headerTable`}>
                    {tabs}
                    <TextInput
                        placeholder="general.search2"
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        qaid="Filter.Input.Search"
                        style={{ flex: 1 }}
                        endIcon={IC_LOOKUP}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <div className={`${Style}__wrapTable`}>
                        {selectedTab === reportTabs.General &&
                            <Table
                                columns={reportsTableColumns}
                                rows={tableData}
                                rowSize={1.3}
                            onRowClick={(obj) => [setSelectedRow(obj), setFilters(resetFilters)]}
                            rowClassName={(row) => (selectedRow && selectedRow.reportName === row.reportName ? `${Style}__selected` : undefined)}
                            />}
                    </div>
                    <div className={`${Style}__wrapTable`}>
                        <div className={`${Style}__card`}>
                            <div className={`${Style}__headerCard`}>
                                <span>Selected report settings</span>
                            </div>
                            <div className={`${Style}__bodyCard`}>
                                {actions()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(TrusteeReports);