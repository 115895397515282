import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { EquityPlan } from "../../../../Models/App/EquityPlans/Company";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import { Cell } from "../../../../Shared/Components/Table/Table.Style";
import useModal from "../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import useEsopModals from "../../useEsopModals";
import EntitiesFactory from "./EntitiesFactory/EntitiesFactory";
import EditEquityPlan from "./Plan/EditEquityPlan/EditEquityPlan";
import { IC_EQUITY_SETTINGS, IC_TRASH2 } from "../../../../Assets";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import { formatDate } from "../../../../Shared/Utilities";
import { useHistory } from "react-router-dom";
import VestingScheduleTable from "./VestingSchedule/VestingScheduleTable";
import NewEquityPlan from "./Plan/NewEquityPlan/NewEquityPlan";
import TrusteeAdditionalStyles from "../../../TrusteeEquity/Dashboard/AdditionalViews/AdditionalViews.module.css";
import NumberInput from "../../../../Shared/Components/Input/NumberInput";
import SelectDataSource from "./SelectDataSource";

interface SettingsProps {
    rtd: () => void;
}

const CompanySettings = (props: SettingsProps) => {
    const history = useHistory();
    const { showModal, removeCurrentModal } = useModal();
    const { equityPlansStore, companyStore, trusteeStore, auth, appState } = useRootStore();
    const { showDeletePlanModal, showSuccessModal, showErrorModal, showChangeDataSource } = useEsopModals();

    const openPlanSettingsModal = (plan: EquityPlan) => {
        equityPlansStore.GetEquityPlan(plan.planId);
        equityPlansStore.GetPoolSteps(plan.planId);
        showModal({
            body: <EditEquityPlan planId={plan.planId} />,
            width: "80%",
        });
    };

    const tableColumn: TableColumn<EquityPlan>[] = [
        { label: "Plan Name", name: "name" },
        {
            label: "Approval Date",
            name: "planApprovalDateByBoard",
            format(val, obj) {
                return formatDate(val);
            },
        },
        {
            label: "RSU",
            name: "allowRsu",
            render(obj, value) {
                return value ? <Cell>V</Cell> : <></>;
            },
        },
        {
            label: "Options",
            name: "allowSop",
            render(obj, value) {
                return value ? <Cell>V</Cell> : <></>;
            },
        },
        {
            label: "",
            name: "menu",
            menuItems: [
                {
                    onClick: (pln) => showDeletePlanModal(pln.planId),
                    element: (
                        <img
                            src={IC_TRASH2}
                            alt="delete plan"
                        />
                    ),
                    qaid: "CompanySettings.Button.DeletePlan",
                },
            ],
        },
    ];

    const newPlanHandler = (data?: EquityPlan) => {
        showModal({
            body: (
                <NewEquityPlan
                    data={data}
                    onSave={async () => {
                        const res = await equityPlansStore.AddEquityPlan();
                        if (res?.data?.planId) {
                            removeCurrentModal();
                            showSuccessModal("The plan was added successfully");
                        } else {
                            console.log("plan res", res);
                            showErrorModal(res);
                        }
                    }}
                />
            ),
            width: "60%",
        });
    };

    const backBtnHandler = () => {
        companyStore.company.pivotalEquityPlans ? history.push("/trustee") : history.push("/ESOP/dashboard");
    };

    useEffect(() => {
        companyStore.company.pivotalEquityPlans
            ? trusteeStore.GetPoolSummary()
            : equityPlansStore.GetEquitySettings();
    }, [companyStore.company.pivotalEquityPlans]);

    return (
        <div style={TrusteeAdditionalStyles} className={TrusteeAdditionalStyles.mainContainer}>
            <div className={TrusteeAdditionalStyles.btnHeader}>
                <Button
                    qaid=""
                    label="Back"
                    inverse
                    cancel
                    onClick={backBtnHandler}
                />
            </div>

            <div className={TrusteeAdditionalStyles.pageBody}>
                <div className={TrusteeAdditionalStyles.pageHeader}>
                    <div className={TrusteeAdditionalStyles.headerMain}>
                        <img src={IC_EQUITY_SETTINGS} />
                        <h3>General Equity Settings</h3>
                    </div>
                </div>

                {equityPlansStore.showTrustee && (
                    <div className={TrusteeAdditionalStyles.settingSection}>
                        <SelectDataSource />
                    </div>
                )}

                {
                    companyStore.company.pivotalEquityPlans ? (
                        <>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <p><span style={{ fontWeight: 600 }}>Summary:</span> as of {formatDate(trusteeStore.dataAsOfDate)}</p><br />
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.totalPool ?? 0} label="Pool" isViewMode />
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.unallocated ?? 0} label="Unallocated" isViewMode />
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.allocated ?? 0} label="Allocated" isViewMode />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Company Equity Plans</h2>
                                <Table
                                    columns={tableColumn}
                                    rows={equityPlansStore.companyData?.plans}
                                    scrollAfterRows={5}
                                    className={""}
                                    renderBottom={
                                        <Flex
                                            align="center"
                                            justify="start"
                                        >
                                            <AddButton
                                                onClick={newPlanHandler}
                                                qaid=""
                                                label="Create new equity plan"
                                            />
                                        </Flex>
                                    }
                                    onRowClick={(row) => newPlanHandler(row)}
                                />
                            </div>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Vesting Schedule</h2>
                                <VestingScheduleTable />
                            </div>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Company data</h2>
                                <EntitiesFactory className={""} />
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};

export default observer(CompanySettings);