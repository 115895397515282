import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { IC_ALTSHARE_LOGO_CIRCLE, IC_SEND_MESSAGE_BUTTON } from '../../../../../Assets';
import { DataCollectionFieldTypeEnum } from '../../../../../Models/API/DataCollection/comment-response';
import Image from '../../../../../Shared/Components/Image';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { DateFormatsEnum, formatDate, isNullOrUndefined } from '../../../../../Shared/Utilities';
import appConfig from '../../../../../config/config';
import { observer } from 'mobx-react-lite';

const Container = styled.div({
	label: 'ChatContainer',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	position: 'relative',
});

const Header = {
	Container: styled.div({
		label: 'ChatHeader',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		flexBasis: '7rem',
		boxShadow: `0px 1px 4px 0px #0c0c0d24`,
		justifyContent: 'center',
		zIndex: 1,
		// position: 'sticky',
		background: '#ffffff',
		height: '7.2rem',
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,
		gap: '2rem',
	}),
	TextsContainer: styled.div({
		display: 'flex',
		flexDirection: 'column',
	}),
	Title: styled.span({
		fontWeight: 700,
		fontSize: '1.8rem',
	}),
	Subtitle: styled.span({
		fontWeight: 500,
		fontSize: '1.3rem',
		color: '#1e1e1e7f',
	}),
};

const MessagesContainer = styled.div({
	label: 'MessagesContainer',
	padding: '2rem 3rem',
	display: 'flex',
	flexDirection: 'column',
	// justifyContent: 'flex-end',
	gap: '1rem',
	flex: 1,
	overflow: 'auto',
});

const MessageContainer = styled.div(
	{
		label: 'MessageContainer',
		display: 'flex',
		flexDirection: 'column',
		width: '60%',
	},
	({ isSelf }: { isSelf: boolean }) => ({
		...(isSelf
			? {
					alignSelf: 'flex-end',
					'> span': {
						alignSelf: 'flex-end',
					},
			  }
			: {
					alignSelf: 'flex-start',
			  }),
	})
);

const Message = styled.div(
	{
		label: 'ChatMessage',
		padding: '1.6rem 2.4rem',
		borderRadius: 16,
	},
	({ isSelf }: { isSelf: boolean }) => ({
		...(isSelf
			? {
					background: '#DCE0FE',
					borderBottomRightRadius: 4,
			  }
			: {
					background: appConfig.style.colors.background1,
					borderBottomLeftRadius: 4,
			  }),
	})
);

const Input = styled.textarea({
	label: 'ChatInput',
	height: '100%',
	outline: 'none',
	border: 'none',
	padding: '2rem 3rem',
	width: '100%',
	resize: 'none',
	borderTopLeftRadius: 16,
	borderTopRightRadius: 16,
	borderBottomLeftRadius: 16,
	borderBottomRightRadius: 16,
	boxShadow: '0px 16px 32px -8px #000000db',
	alignContent: 'center',
	// outline: '1px solid green',
	'&::placeholder': {
		color: '#000000',
		opacity: 0.5,
	},
	'&::-ms-input-placeholder': {
		color: '#000000',
		opacity: 0.5,
	},
});

const InputContainer = styled.div({
	label: 'InputContainer',
	// position: 'sticky',
	bottom: 0,
	width: '100%',
	height: '7.2rem',
	maxHeight: '23rem',
	// outline: '1px solid red',
});

const TimeAgo = styled.span({
	label: 'MessageTimeAgo',
	fontWeight: 400,
	fontSize: '1.2rem',
	marginTop: '0.8rem',
});

type SendMessagePayload = {
	comment: string;
};

type Props = {
	fieldType: DataCollectionFieldTypeEnum;
};

const QuestionsHistory = ({ fieldType }: Props) => {
	const { dataCollectionStore } = useRootStore();
	const inputTextRef = useRef<HTMLTextAreaElement>(null);
	const inputRef = useRef<HTMLDivElement>(null);
	const messeagesContainerRef = useRef<HTMLDivElement>(null);

	const comments = dataCollectionStore.commentsByQuestion[fieldType];

	const isLastGroupedMessage = (idx: number) => {
		const nextComment = comments[idx + 1];
		if (nextComment === undefined) return true;

		return comments[idx].isValuationTeam !== nextComment.isValuationTeam;
	};

	useEffect(() => {
		setTimeout(scrollToBottom, 25);
	}, []);

	const scrollToBottom = () => {
		if (!messeagesContainerRef.current) return;
		messeagesContainerRef.current.scrollTop = 9999999;
	};

	const onSendMessageHandler = async () => {
		if (!inputTextRef.current?.value || isNullOrUndefined(dataCollectionStore.valuationProjectId)) return;
		await dataCollectionStore.addComment({
			commentDescription: inputTextRef.current.value,
			fieldType,
			valuationProjectId: dataCollectionStore.valuationProjectId,
		});
		inputTextRef.current.value = '';
		scrollToBottom();
	};

	return (
		<Container>
			<Header.Container>
				<Image src={IC_ALTSHARE_LOGO_CIRCLE} width="4.8rem" />
				<Header.TextsContainer>
					<Header.Title>altshare</Header.Title>
					<Header.Subtitle>409A valuation team</Header.Subtitle>
				</Header.TextsContainer>
			</Header.Container>
			<MessagesContainer ref={messeagesContainerRef}>
				{comments
					// .sort((a, b) => +new Date(a.createDate) - +new Date(b.createDate))
					.map((comment, idx) => {
						const isSelf = dataCollectionStore.currentRole === 'valuationTeam' ? comment.isValuationTeam : !comment.isValuationTeam;
						return (
							<MessageContainer key={comment.commentDescription + idx} isSelf={isSelf}>
								<Message isSelf={isSelf}>{comment.commentDescription}</Message>
								{isLastGroupedMessage(idx) && <TimeAgo>{formatDate(comment.createDate * 1000, DateFormatsEnum.LABEL_AMPM_TIME_SHORT)}</TimeAgo>}
							</MessageContainer>
						);
					})}
			</MessagesContainer>
			<InputContainer ref={inputRef}>
				<Input
					placeholder="Type your message..."
					onChange={(e) => {
						if (inputTextRef.current) inputTextRef.current.value = e.target.value;
						if (!inputRef.current) return;
						inputRef.current.style.height = `calc(7.2rem + ${(e.target.value.split('\n').length - 1) * 2}rem)`;
					}}
					ref={inputTextRef}
				/>
				<Image
					src={IC_SEND_MESSAGE_BUTTON}
					width="4.3rem"
					style={{
						position: 'absolute',
						zIndex: 2,
						right: '3.6rem',
						bottom: '1.8rem',
						boxShadow: '0px 2px 6px -1px rgba(12, 12, 13, 0.15)',
						borderRadius: '50%',
						cursor: 'pointer',
					}}
					onClick={onSendMessageHandler}
				/>
			</InputContainer>
		</Container>
	);
};
export default observer(QuestionsHistory);
