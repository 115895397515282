import {
    TrusteeGrantDocument,
    TrusteeBeneficiaries,
    TrusteeCancelation,
    TrusteeGrant,
    TrusteeOrder,
    TrusteeManagerReport,
    FilterLevel,
    AndOrConnector,
    SqlOperator,
    UpcomingVestings,
    SubmitFilesToTrusteeType,
    PivotalParameterType,
} from "./trusteeTypes";

// REQ

export interface PagedTableReqParams {
    companyId?: number;
    GrantId?: string;
    BeneficiaryId?: string;
    OrderBy?: number;
    Descending?: boolean;
    filterDtos?: string;
    // filterDtos?: FilterDto[]
    PageNumber?: number;
    ItemInPage?: number;
    PlanId?: number;
    AsOfDate?: Date | string;
}

export interface TrusteeReportParams {
    reportName: string;
    requestType: PivotalReportTypeRequest;
    parameters?: ReportQueryParams[];
    SearchParamsJson: string;
    asOfDate?: Date | string;
    key?: string;
    numberOfItemsInPage?: number;
    pageNumber?: number;
}

export enum PivotalReportTypeRequest {
    excel = 1,
    json
}

export interface ReportQueryParams {
    key: string;
    type: PivotalParameterType;
    isAsOfDate: boolean;
    value?: string | Date | number | undefined;
    value2?: string | Date | number | undefined;
}

export interface TrusteeFileReq {
    submitFilesToTrusteeType: SubmitFilesToTrusteeType;
    file: string;
    fileName: string;
    fileType: string;
    comments?: string;
}

// RES

export interface UpcomingVestingsRes {
    upComingVestingList: UpcomingVestings[];
    totalVestingEvents: number;
}
export interface TrusteeGrantsRes {
    grants: TrusteeGrant[];
    numberOfRows: number;
}

export interface TrusteeBeneficiariesRes {
    beneficiarys: TrusteeBeneficiaries[];
    numberOfTotalBeneficiarys: number;
}

export interface TrusteeOrdersRes {
    orders: TrusteeOrder[];
    totalNumberOfOrders: number;
}

export interface TrusteeCancelationsRes {
    orders: TrusteeCancelation[];
    totalNumberOfOrders: number;
}

export interface GrantDocumentsRes {
    documents: TrusteeGrantDocument[];
    numberOfTotalItems: number;
    documentsType: number;
}

export interface ManagerReportsRes {
    reportsToSee: TrusteeManagerReport[];
}

export interface TrusteeReportRes {
    reportExt: string;
    reportName: string;
    reportString: string;
}

export interface TerminationRequestRes {
    status: boolean;
}

export interface TrusteeMainSaaSDashboardRes {
    poolDto: {
        allocated: number;
        unallocated: number;
    };
    sharesDto: {
        exercised: number;
        rsUs: number;
    };
    outstandingDto: {
        unvested: {
            rsUs: number;
            options: number;
        };
        exercisable: number;
    };
}

export interface FilterDto {
    AndOrConnector: AndOrConnector;
    SqlOperator: SqlOperator;
    Value: string;
    Filed?: number;
}
