import { FC, useState } from "react";
import { defaultColors } from "../DoughnutChart/DoughnutChart.style";
import SquareIcon from "@mui/icons-material/Square";
import ChartStyles from './ChartLegendDivided.module.css'
import { formatNumber, isNullOrUndefined } from "../../../../../Utilities";

export interface ChartLegendProps {
    labels: string[];
    figures?: number[] | string[];
    percenteges?: string[]
    colors?: string[];
    toColums?: boolean
}

interface ChartItem {
    color: string
    label: string
    figure: string | number
    percentage?: string
}

const ChartLegendDivided: FC<ChartLegendProps> = ({ colors = defaultColors, labels, figures, percenteges, toColums }: ChartLegendProps) => {
    function sortLegendItems(legendItems: any[]) {
        const lastItem = legendItems.find((item) => item.label === "Others");
        const items = legendItems.filter((item) => item.label !== "Others");

        if (lastItem) {
            items.push(lastItem);
        }

        return items;
    }

    const legendItems: ChartItem[] = labels.map((label, index) => {
        const item: ChartItem = {
            color: colors[index],
            label: label,
            figure: !isNullOrUndefined(figures) ? figures[index]
                : !isNullOrUndefined(percenteges) ? percenteges[index] : 0
        }
        if (Array.isArray(percenteges)) item.percentage = percenteges[index]

        return item
    });

    const sortedLegendItems: ChartItem[] = sortLegendItems(legendItems);
    const column1 = sortedLegendItems.slice(0, 3)
    const column2 = sortedLegendItems.slice(3, 6)

    const LegendBlock = (props: { items: ChartItem[] }) => {
        return (
            <div className={ChartStyles.legendBlock}>
                {
                    props.items.map((item, i) => (
                        <div className={ChartStyles.blockRow} key={`cl${i}`}>
                            <SquareIcon style={{ color: item.color }} />
                            <div className={ChartStyles.rowData}>
                                <p>{item.label}</p>
                                <p style={{ fontWeight: 600 }}>{`${item.percentage ?? 0}%`}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    if (toColums) {
        return (
            <div className={ChartStyles.chartContainer} style={ChartStyles}>
                <div className={ChartStyles.chartColumns}>
                    <LegendBlock items={column1} />
                    <LegendBlock items={column2} />
                </div>
            </div>
        )
    }

    return (
        <div className={ChartStyles.chartContainer} style={ChartStyles}>
            <div className={ChartStyles.chartColumns}>
                <div className={ChartStyles.singleColumn}>
                    {
                        sortedLegendItems.map((item, i) => {
                            return (
                                <div className={ChartStyles.chartItem} key={`ci${i}`}>
                                    <SquareIcon style={{ color: item.color }} />
                                    <p>{item.label}</p>
                                </div>
                            )
                        })
                    }
                </div>

                {figures && <p className={ChartStyles.divider}>{''}</p>}

                <div style={{ fontWeight: 'bold' }} className={ChartStyles.singleColumn}>
                    {
                        figures && sortedLegendItems.map((item, i) => {
                            return (
                                <div className={ChartStyles.chartItem} key={`sci${i}`}>
                                    <p> {formatNumber(item.figure)}</p>
                                </div>
                            )
                        })
                    }
                </div>

                {percenteges && <p className={ChartStyles.divider}>{''}</p>}

                <div style={{ fontWeight: 'bold' }} className={ChartStyles.singleColumn}>
                    {percenteges && sortedLegendItems.map((item, i) => {
                        return (
                            <div className={ChartStyles.chartItem} key={`pci${i}`}>
                                <p>{`${item.percentage ?? 0}%`}</p>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div >
    )
}

export default ChartLegendDivided;