import { useTranslation } from "react-i18next";
import { AvailableAmountsResultInfo } from "../../../../Models/API/CapTable/available-amounts-result-info";
import { PersonTypesEnum, WarrantsType } from "../../../../Models/API/enums";
import { SecondaryTransaction } from "../../../../Models/App/CapTable/SecondaryTransaction";
import { InputRefs, useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { useRef } from "react";

const useSecondaryTransactionValidation = (transaction: SecondaryTransaction, availableAmount: AvailableAmountsResultInfo) => {
    const { t } = useTranslation();
    const inputRefs = useRef<InputRefs<SecondaryTransaction>>({} as InputRefs<SecondaryTransaction>);

    const numberOfWarrantsValidation = () => {
        if (transaction.warrantsType === WarrantsType.Warrants) {
            return [
                // commonValidators.requiredIf(() => !!availableAmount.availableWarrants),
                commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
                commonValidators.range(1, availableAmount.availableWarrants, t("validations.exceededTheAvailableAmount")),
            ];
        }
        if (transaction.warrantsType === WarrantsType.Bsa) {
            return [
                // commonValidators.requiredIf(() => !!availableAmount.availableBsa),
                commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
                commonValidators.range(1, availableAmount.availableBsa, t("validations.exceededTheAvailableAmount")),
            ];
        }
        return [];
    };

    const { isFormValid, formErrors, formValidationState, setFormValidationState, validateForm } = useFormValidation({
        form: transaction,
        schema: {
            // sellerFirstName: [commonValidators.minLength(2)],
            // sellerLastName: [commonValidators.requiredIf(() => transaction.sellerType === PersonTypesEnum.Person), commonValidators.minLength(2)],
            // sellerEmail: [commonValidators.requiredIf(() => transaction.sellerType === PersonTypesEnum.Person), commonValidators.emailValidator],
            // secondaryUserRole: [commonValidators.required()],
            numberOfShares: [
                // commonValidators.requiredIf(() => !!availableAmount.availableShares),
                commonValidators.required(),
                commonValidators.range(
                    0,
                    availableAmount.availableShares,
                    t("validations.exceededTheAvailableAmount") + " (seller shares as of date)"
                ),
            ],
            pricePerShare: [
                // commonValidators.requiredIf(() => transaction.warrantsType === WarrantsType.None),
                commonValidators.required(),
            ],
            numberOfWarrants: numberOfWarrantsValidation(),
            pricePerWarrant:
                transaction.warrantsType === WarrantsType.None ? [] : [commonValidators.required(), commonValidators.positive()],
            assetBoughtId: commonValidators.required(),
            assetSoldId: commonValidators.required(),
        },
        refs: inputRefs.current,
    });

    return {
        isFormValid,
        formErrors,
        formValidationState,
        setFormValidationState,
        validateForm,
        inputRefs: inputRefs.current,
    };
};

export default useSecondaryTransactionValidation;
