import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const EditShareholderStyle = css({
    label: "EditShareholder",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "7rem 8rem 4rem",
    height: "100%",
    overflow: "auto",
    "@media screen and (max-width: 768px)": {
        padding: "8rem 4rem 4rem",
    },

    ".menu": {
        "img[data-type='menu-img']": {
            transform: "rotate(90deg) scale(0.9)",
        },
    },

    "&__discount": {
        fontSize: "1.2rem",
        color: appConfig.style.colors.text2,
        marginLeft: "0.4rem",
    },
    "&__title-container": {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        textTransform: "capitalize",
        ".img-sh-type": {
            width: "5.4rem",
        },
        marginBottom: "2.4rem",
    },
    "&__contact-section": {
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${appConfig.style.colors.table}`,
        padding: "3.6rem 4.4rem",
        borderRadius: 16,
        position: "relative",
        transition: "all .3s",
        overflow: "hidden",
        flex: "0 0 23.3rem",
        "&.expanded": {
            flex: "0 0 33.3rem",
        },
        ".data-container": {
            display: "flex",
            gap: "4.8rem",
        },
        ".img-edit": {
            position: "absolute",
            right: "1.5rem",
            top: "1.5rem",
        },
        ".form": {
            flex: 1,
            position: "relative",
            "&::after": {
                content: "''",
                position: "absolute",
                right: "-2.4rem",
                top: 0,
                width: 1,
                height: "100%",
                background: appConfig.style.colors.table,
            },
        },
        ".permissions": {
            flex: "0 0 15rem",
        },
    },
    "&__transactions-section": {
        display: "flex",
        flexDirection: "column",
    },
    "&__loans-transactions-section": {
        color: appConfig.style.colors.text2,
    },
    // '&__menu-container': {
    // 	position: 'relative',
    // 	marginLeft: 'auto'
    // },
    // '&__menu': {
    // 	padding: '1.2rem',
    // 	zIndex: 2,
    // 	position: 'absolute',
    // 	top: "calc(100% - 0.5rem)",
    // 	boxShadow: appConfig.style.boxShadow.card1,
    // 	border: `1px solid ${appConfig.style.colors.background1}`,
    // 	background: '#ffffff',
    // 	display: 'flex',
    // 	flexDirection: 'column',
    // 	gap: "2rem",
    // 	right: "2rem",
    // 	borderRadius: '0.8rem',
    // 	width: 'max-content',
    // 	minWidth: '15rem'
    // },
    // '&__menu-item': {
    // 	background: '#ffffff',
    // 	img: {
    // 		width: "2rem"
    // 	}
    // }
});

export default EditShareholderStyle;
