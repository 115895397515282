import { useTranslation } from "react-i18next";
import { AvailableAmountsResultInfo } from "../../../../Models/API/CapTable/available-amounts-result-info";
import { WarrantsType } from "../../../../Models/API/enums";
import { IssueTransaction } from "../../../../Models/App/CapTable/IssueTransaction";
import { ObjectValidationDescriptor, commonValidators, minNumber } from "../../../../Shared/ObjectValidator";
import { useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import { isNullOrUndefined } from "../../../../Shared/Utilities";

interface Props {
    transaction: IssueTransaction;
    availableAmounts: AvailableAmountsResultInfo;
    type: "safe" | "loan" | "common" | "class";
}

export const useIssueTransactionValidation = ({ transaction, availableAmounts, type }: Props) => {
    const { t } = useTranslation();

    const classValidatorsSchema: ObjectValidationDescriptor<IssueTransaction> = {
        discount: [
            commonValidators.requiredIf(() => transaction.discountEnabled),
            commonValidators.graterThan(0, t("validations.pleaseEnterAValidNumber")),
        ],
        numberOfShares: [
            commonValidators.requiredIf(() => transaction.warrantsType === WarrantsType.None),
            //commonValidators.positive(t('validations.enterValidNumber')),
            // commonValidators.range(
            // 	transaction.warrantsType !== WarrantsType.None ? 0 : 0.000000001,
            // 	availableAmounts.availableShares,
            // 	t("validations.pleaseEnterAValidNumber")
            // ),
            (v) => {
                if (v && v > 0 && v <= availableAmounts.availableShares) {
                    return {
                        isValid: true,
                        message: undefined,
                    };
                }
                if (transaction.warrantsType !== WarrantsType.None && !v) {
                    return {
                        isValid: true,
                        message: undefined,
                    };
                }
                return {
                    isValid: false,
                    message: t("validations.pleaseEnterAValidNumber"),
                };
            },
        ],
        numberOfWarrants: [
            commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
            // (v) => {
            //     if (v && transaction.warrantsEnabled) {
            //         if (v > 0 && v <= availableAmounts.availableWarrants) {
            //             return { isValid: true }
            //         }
            //         else if (!v) {
            //             return { isValid: false, message: t('validations.enterValidNumber') }
            //         }
            //         else {
            //             return { isValid: false, message: t('validations.exceededAvailableAmount') }
            //         }
            //     }
            //     return { isValid: true }
            // }
            (v) => {
                if (v && transaction.warrantsType === WarrantsType.Warrants) {
                    if (v > 0 && v <= availableAmounts.availableWarrants) {
                        return { isValid: true };
                    } else if (!v) {
                        return {
                            isValid: false,
                            message: t("validations.pleaseEnterAValidNumber"),
                        };
                    } else {
                        return {
                            isValid: false,
                            message: t("validations.exceededTheAvailableAmount"),
                        };
                    }
                } else if (v && transaction.warrantsType === WarrantsType.Bsa) {
                    if (v > 0 && v <= availableAmounts.availableBsa) {
                        return { isValid: true };
                    } else if (!v) {
                        return {
                            isValid: false,
                            message: t("validations.pleaseEnterAValidNumber"),
                        };
                    } else {
                        return {
                            isValid: false,
                            message: t("validations.exceededTheAvailableAmount"),
                        };
                    }
                }
                return { isValid: true };
            },
        ],
        exPrice: [
            commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
            commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
        ],
        assetId: [commonValidators.required()],
        date: [commonValidators.required()],
    };

    const commonClassValidatorsSchema: ObjectValidationDescriptor<IssueTransaction> = {
        discount: [
            commonValidators.requiredIf(() => transaction.discountEnabled),
            commonValidators.graterThan(0, t("validations.pleaseEnterAValidNumber")),
        ],
        numberOfShares: [
            commonValidators.requiredIf(() => transaction.warrantsType === WarrantsType.None),

            (v) => {
                if (v) {
                    if (transaction.warrantsType !== WarrantsType.None) {
                        if (v > 0 && v + (transaction.numberOfWarrants ?? 0) <= availableAmounts.availableShares) {
                            return { isValid: true };
                        } else if (!v) {
                            return {
                                isValid: false,
                                message: t("validations.pleaseEnterAValidNumber"),
                            };
                        } else {
                            return {
                                isValid: false,
                                message: t("validations.exceededTheAvailableAmount"),
                            };
                        }
                    } else {
                        if (v > 0 && v <= availableAmounts.availableShares) {
                            return { isValid: true };
                        } else if (!v) {
                            return {
                                isValid: false,
                                message: t("validations.pleaseEnterAValidNumber"),
                            };
                        } else {
                            return {
                                isValid: false,
                                message: t("validations.exceededTheAvailableAmount") + " (registered common minus issued)",
                            };
                        }
                    }
                }
                return { isValid: true };
            },
        ],
        numberOfWarrants: [
            commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
            commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
            (v) => {
                if (v && transaction.warrantsType === WarrantsType.Warrants) {
                    if (v > 0 && v + (transaction.numberOfShares ?? 0) <= availableAmounts.availableWarrants) {
                        return { isValid: true };
                    } else if (!v) {
                        return {
                            isValid: false,
                            message: t("validations.pleaseEnterAValidNumber"),
                        };
                    } else {
                        return {
                            isValid: false,
                            message: t("validations.exceededTheAvailableAmount"),
                        };
                    }
                } else if (v && transaction.warrantsType === WarrantsType.Bsa) {
                    if (v > 0 && v + (transaction.numberOfShares ?? 0) <= availableAmounts.availableBsa) {
                        return { isValid: true };
                    } else if (!v) {
                        return {
                            isValid: false,
                            message: t("validations.pleaseEnterAValidNumber"),
                        };
                    } else {
                        return {
                            isValid: false,
                            message: t("validations.exceededTheAvailableAmount"),
                        };
                    }
                }
                return { isValid: true };
            },
        ],
        exPrice: [
            commonValidators.requiredIf(() => transaction.warrantsType !== WarrantsType.None),
            commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
        ],
        assetId: [commonValidators.required()],
        date: [commonValidators.required()],
        pricePerShare: [commonValidators.requiredIf(() => transaction.warrantsType === WarrantsType.None || !!transaction.numberOfShares)],
    };

    const safeValidatorsSchema: ObjectValidationDescriptor<IssueTransaction> = {
        moneyInvested: [
            commonValidators.required(t("validations.pleaseEnterAValidNumber")),
            commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
        ],
        valuationCap: [commonValidators.positive(t("validations.pleaseEnterAValidNumber"))],
        discount: [commonValidators.range(0.001, 100, t("validations.pleaseEnterAValidNumber"))],
        date: [commonValidators.required()],
    };

    const convertibleLoanValidatorsSchema: ObjectValidationDescriptor<IssueTransaction> = {
        moneyInvested: [
            commonValidators.required(t("validations.pleaseEnterAValidNumber")),
            commonValidators.positive(t("validations.pleaseEnterAValidNumber")),
        ],
        maturityDate: [commonValidators.requiredIf(() => transaction.maturityEnabled ?? false, t("validations.pleaseSelectValidDate"))],
        valuationCap: [commonValidators.positive(t("validations.pleaseEnterAValidNumber"))],
        discount: [commonValidators.graterThan(0, t("validations.pleaseEnterAValidNumber"))],
        interestRate: transaction.interestEnabled
            ? [
                  commonValidators.requiredIf(() => transaction.interestEnabled ?? false, t("validations.pleaseEnterAValidNumber")),
                  commonValidators.graterThan(0, t("validations.pleaseEnterAValidNumber")),
              ]
            : [],
        assetId: [commonValidators.required()],
        date: [commonValidators.required()],
    };
    const { isFormValid, formValidationState, validateForm } = useFormValidation({
        form: transaction,
        schema:
            type === "loan"
                ? convertibleLoanValidatorsSchema
                : type === "class"
                ? classValidatorsSchema
                : type === "common"
                ? commonClassValidatorsSchema
                : safeValidatorsSchema,
    });

    return {
        isFormValid,
        validateForm,
        formValidationState,
    };
};
