import { css } from "@emotion/css";
import { IPivotalCompany } from "../../../../../../Models/App";
import Button from "../../../../../../Shared/Components/Button/Button";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import Title from "../../../../../../Shared/Components/Layout/Title";
import appConfig from "../../../../../../config/config";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { useState } from "react";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import { ModalBodyProps } from "../../../../../../Shared/Components/Modal/types";
import useRecaptcha from "../../../../../../Shared/Hooks/useRecaptcha";
import useModal from "../../../../../../Shared/Hooks/useModal";
import { useHistory } from "react-router-dom";
import useTrusteeModals from "../../../../../TrusteeEquity/useTrusteeModals";

interface Props extends ModalBodyProps {
	companies: IPivotalCompany[];
	onCompanySelect: (reCaptchaToken: string, company: IPivotalCompany) => Promise<boolean>;
	initialRegistrartion?: boolean
}

type IsLoadingType = Record<string, boolean>;

const Style = css({
	label: "PivotalCompany",
	"&__company": {
		borderTop: `1px solid ${appConfig.style.colors.table}`,
		"&:not(:first-child)": {},
		"&:last-child": {
			borderBottom: `1px solid ${appConfig.style.colors.table}`,
		},
	},
});

const CompaniesList = ({ companies, removeModal, onCompanySelect, initialRegistrartion }: Props) => {
	const { capTableStore, auth } = useRootStore();
	const [isLoading, setIsLoading] = useState<IsLoadingType>({} as IsLoadingType);
	const { execute } = useRecaptcha();

	const { clearModals } = useModal();
	const { showSuccessModal } = useTrusteeModals();

	const history = useHistory()

	const onSelectHandler = async (company: IPivotalCompany) => {
		execute(async (token) => {
			setIsLoading((prevState) => ({ ...prevState, [company.companyId]: true }));

			const res = await onCompanySelect(token, company);

			console.log("initialRegistrartion", initialRegistrartion);

			if (res && initialRegistrartion) {
				showSuccessModal();
				setTimeout(() => {
					clearModals();
					history.push('/trustee');
					window.location.reload();
					res && removeModal?.();
					setIsLoading((prevState) => ({ ...prevState, [company.companyId]: false }));
				}, 1400);
			}

			if (res) {
				showSuccessModal();
				setTimeout(() => {
					res && removeModal?.();
					setIsLoading((prevState) => ({ ...prevState, [company.companyId]: false }));
				}, 1400);
			}
		});
	};

	const isSingleLoading = Object.keys(isLoading).some((key) => isLoading[key]);

	return (
		<Flex direction="column" gap="4rem" align="start">
			<Title>Choose your trustee company</Title>
			<Flex direction="column">
				{companies.map((company) => (
					<Flex key={company.companyId} justify="between" className={`${Style}__company`} padding="1.6rem">
						<span>{company.companyName}</span>
						<Button
							qaid="PivotalCompany.Button.Select"
							label="Select"
							disabled={!isLoading[company.companyId] && isSingleLoading}
							isLoading={isLoading[company.companyId]}
							onClick={() => onSelectHandler(company)}
						/>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};

export default CompaniesList;
