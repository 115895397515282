import { ShareClassPreviewInfo } from "../../../Models/API/CapTable";
import { ShareClassTypeOptions } from "../../../Models/API/enums";
import { ExtraAssetTypes } from "../../../Shared/Config";

export const getAssetName = (id?: number) => {
	if (id === ExtraAssetTypes.SAFE) {
		return "S.A.F.E";
	} else if (id === ExtraAssetTypes.ConvertibleLoan) {
		return "Convertible Loan";
	} else if (id === ExtraAssetTypes.ConvertibleLoan) {
		return "Common shares";
	} else {
		return "";
	}
};

export const isCommonClass = (array: ShareClassPreviewInfo[] | null = [], assetId?: number) => {
	return !!(array?.find((x) => x.shareClass.shareClassID === assetId)?.shareClass.shareClassTypeID === ShareClassTypeOptions.Common);
};
