import { Fade } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { IContact, IContactCreate, IContactDetails } from "../../../../../../../Models/API/Contact/contact";
import Clickable from "../../../../../../../Shared/Components/Clickable/Clickable";
import CompanyUserAutoComplete from "../../../../../../../Shared/Components/CompanyUserAutoComplete";
import ExpandToggle from "../../../../../../../Shared/Components/ExpandToggle";
import AutoCompleteInput from "../../../../../../../Shared/Components/Input/AutoCompleteInput";
import PhoneInput from "../../../../../../../Shared/Components/Input/PhoneInput";
import TextInput from "../../../../../../../Shared/Components/Input/TextInput";
import Select from "../../../../../../../Shared/Components/Select/Select";
import { shareholderRoleOptions } from "../../../../../../../Shared/Config";
import { AppendStateInputHandlerType } from "../../../../../../../Shared/Hooks/useAppendState";
import { InputRefs, InputValidationRef } from "../../../../../../../Shared/Hooks/useFormValidation";
import { ObjectValidationStatus } from "../../../../../../../Shared/ObjectValidator";
import { countries } from "../../../../../../../Shared/StaticData/countries";
import AddShareholderStyle from "../AddShareholder.style";
import { isNumber } from "../../../../../../../Shared/Utilities";

interface Errors extends Omit<ObjectValidationStatus<IContactCreate>, "contactDetails"> {
    contactDetails?: ObjectValidationStatus<IContactDetails> | null;
}

interface Props {
    contact: IContactCreate;
    onInputHandler: AppendStateInputHandlerType;
    setContact: Dispatch<SetStateAction<IContactCreate>>;
    formValidationState?: Errors;
    onSuggestionSelected?: (contact: IContact) => void;
    isViewMode?: boolean;
    expand?: boolean;
    disabled?: boolean;
    inputRefs: InputRefs<IContactCreate>;
    contactInputRefs?: InputRefs<IContactDetails>;
    isEmailRequired?: boolean;
    isRoleRequired?: boolean;
}

const PersonForm = ({
    contact,
    contactInputRefs,
    onInputHandler,
    setContact,
    formValidationState,
    onSuggestionSelected,
    isViewMode = false,
    expand = false,
    disabled = false,
    isEmailRequired,
    isRoleRequired = true,
    inputRefs,
}: Props) => {
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(expand);

    return (
        <div className={`${AddShareholderStyle}__form`}>
            <div className={`${AddShareholderStyle}__form-row`}>
                <CompanyUserAutoComplete
                    searchBy="firstName"
                    label="account.firstName"
                    onSuggestionSelected={onSuggestionSelected}
                    required
                    value={contact.firstName}
                    name="firstName"
                    onChange={onInputHandler}
                    error={formValidationState?.firstName?.message}
                    isViewMode={isViewMode}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => (inputRefs.firstName = el)}
                />
                {/* <TextInput
					label="First name"
					qaid="AddShareholder.Input.FirstName"
					name="firstName"
					value={contact.firstName}
					onChange={onInputHandler}
					required
					error={formValidationState?.firstName?.message}
				/> */}
                <CompanyUserAutoComplete
                    searchBy="lastName"
                    label="account.lastName"
                    onSuggestionSelected={onSuggestionSelected}
                    required
                    value={contact.lastName}
                    name="lastName"
                    onChange={onInputHandler}
                    error={formValidationState?.lastName?.message}
                    isViewMode={isViewMode}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => (inputRefs.lastName = el)}
                />
                {/* <TextInput
					label="Last name"
					qaid="AddShareholder.Input.LastName"
					name="lastName"
					value={contact.lastName}
					onChange={onInputHandler}
					required
					error={formValidationState?.lastName?.message}
				/> */}
                <Select
                    qaid="AddShareholder.Select.Role"
                    options={shareholderRoleOptions}
                    label="Role type"
                    onChange={onInputHandler}
                    name="contactDetails.role"
                    value={contact.contactDetails?.role}
                    error={formValidationState?.contactDetails?.role?.message}
                    isViewMode={isViewMode}
                    disabled={disabled}
                    ref={(el: InputValidationRef) => {
                        if (contactInputRefs) contactInputRefs.role = el;
                    }}
                    required={isRoleRequired}
                />
                <CompanyUserAutoComplete
                    searchBy="email"
                    label="Company's email"
                    onSuggestionSelected={onSuggestionSelected}
                    value={contact.email}
                    name="email"
                    onChange={onInputHandler}
                    error={formValidationState?.email?.message}
                    isViewMode={isViewMode}
                    disabled={disabled || isNumber(contact.userId)}
                    ref={(el: InputValidationRef) => (inputRefs.email = el)}
                    required={isEmailRequired}
                />
                {/* <TextInput
					label="Email"
					qaid="AddShareholder.Input.Email"
					name="email"
					value={contact.email}
					onChange={onInputHandler}
					error={formValidationState?.email?.message}
				/> */}
            </div>

            {expand === false && (
                <Clickable
                    justify="start"
                    margin="2rem 0 0 0"
                    width="fit-content"
                    qaid="AddShareholder.Button.MoreInfo"
                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                    gap="1.2rem"
                >
                    Add more info (optional)
                    <ExpandToggle isOpen={showMoreInfo} />
                </Clickable>
            )}

            <Fade
                in={showMoreInfo}
                unmountOnExit
            >
                <div className={`${AddShareholderStyle}__form-row mt`}>
                    <PhoneInput
                        onChange={(mobile: string, mobilePrefix?: string) => {
                            setContact((prevContact) => ({
                                ...prevContact,
                                mobilePrefix: mobile ? mobilePrefix : undefined,
                                mobile: mobile || undefined,
                            }));
                        }}
                        label={isViewMode ? "Mobile" : "Mobile (with country code)"}
                        qaid="AddShareholder.Input.Mobile"
                        value={contact.mobile}
                        mobilePrefix={contact.mobilePrefix}
                        isViewMode={isViewMode}
                        disabled={disabled}
                        error={formValidationState?.mobile?.message}
                    />
                    <TextInput
                        label="Organization name"
                        qaid="AddShareholder.Input.OrganizationName"
                        value={contact.contactDetails?.organizationName}
                        onChange={onInputHandler}
                        name="contactDetails.organizationName"
                        containerClassName="mb-0"
                        isViewMode={isViewMode}
                        disabled={disabled}
                    />
                    <AutoCompleteInput
                        label="Country of residence"
                        qaid="AddShareholder.Input.Nationality"
                        value={contact.contactDetails?.nationality}
                        onChange={onInputHandler}
                        name="contactDetails.nationality"
                        options={countries}
                        autoComplete="nope"
                        containerClassName="mb-0"
                        isViewMode={isViewMode}
                        disabled={disabled}
                    />
                    <TextInput
                        label="ID"
                        qaid="AddShareholder.Input.ID"
                        value={contact.contactDetails?.idNumber}
                        onChange={onInputHandler}
                        name="contactDetails.idNumber"
                        containerClassName="mb-0"
                        isViewMode={isViewMode}
                        disabled={disabled}
                        // error={formValidationState?.id?.message}
                    />
                </div>
            </Fade>
        </div>
    );
};

export default PersonForm;
