import { css } from '@emotion/css';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_CHECK_PURPLE, IC_COMPANY_CIRCLE, IC_EDIT2, IC_USER_CIRCLE, IC_USER_PERMISSIONS, IC_USER_PROFILE_PURPLE } from '../../../../../Assets';
import { UsersPermissionsByCompany } from '../../../../../Models/API/UsersAndPermissions/users-permissions-by-company';
import { Routes } from '../../../../../Routes';
import Button from '../../../../../Shared/Components/Button/Button';
import Image from '../../../../../Shared/Components/Image';
import { Comment } from '../../../../../Shared/Components/Input/Input.Style';
import TextInput from '../../../../../Shared/Components/Input/TextInput';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import { ModalBodyProps } from '../../../../../Shared/Components/Modal/types';
import Table, { TableColumn } from '../../../../../Shared/Components/Table/Table';
import useModal from '../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { REGEX_EMAIL, REGEX_ONLY_LETTERS_SPACES, isArray, isNullOrUndefined, isNumber } from '../../../../../Shared/Utilities';
import appConfig from '../../../../../config/config';
import { IContactCreate } from '../../../../../Models/API/Contact/contact';
import OverflowText from '../../../../../Shared/Components/OverflowText';
import classNames from 'classnames';
import { CapTablePermission } from '../../../../../Models/API/UsersAndPermissions/permissions-enum';
import useGeneralModal from '../../../../../Shared/Hooks/useGeneralModal';

const Style = css({
	label: 'ShareCapTable',
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	padding: '7rem 8rem 4rem',
	height: '100%',
	overflow: 'auto',
	'&__admin-note': {
		background: 'linear-gradient(90deg, #8B96E9 0%, #F3D465 33.00%, #F2AA63 66.00%, #E98B8B 100%)',
		borderRadius: 50,
		marginLeft: '1.6rem',
		padding: '0.45rem 1.5rem',
		position: 'relative',
		span: {
			'&:first-child': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: '#ffffff',
				position: 'absolute',
				width: 'calc(100% - 4px)',
				height: 'calc(100% - 4px)',
				borderRadius: 50,
				top: 2,
				left: 2,
			},
		},
	},
	'.sub-note': {
		color: appConfig.style.colors.text4,
	},
	'&__edit-container': {
		height: '100%',
		marginLeft: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: '1.2rem',
		borderLeft: `1px solid ${appConfig.style.colors.table}`,
	},
});

type Props = {
	availableContacts: number[];
} & ModalBodyProps;

const ShareCapTable = ({ availableContacts, removeModal }: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [contacts, setContacts] = useState<UsersPermissionsByCompany[] | null>();
	const contactsRef = useRef<UsersPermissionsByCompany[] | null>();
	const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
	const [editIds, setEditIds] = useState<Record<number, boolean>>({});
	const [hoverId, setHoverId] = useState<number>(-1);
	const editedIds = useRef<number[]>();
	const [error, setError] = useState<string>();
	const history = useHistory();
	const { showModal } = useModal();
	const { showErrorModal } = useGeneralModal();

	const { contactStore } = useRootStore();

	useEffect(() => {
		if (!availableContacts.length) return setContacts(null);
		(async () => {
			const res = await contactStore.getContactsById(availableContacts);
			if (!res.data) return;
			const data: UsersPermissionsByCompany[] = res.data.sort((a, b) => b.capTablePermission - a.capTablePermission);
			setContacts(data);
			contactsRef.current = data;
		})();
	}, []);

	useEffect(() => {
		setContacts((prevState) => {
			if (isNullOrUndefined(prevState)) return prevState;

			return prevState.map((c, idx) => {
				if (isNullOrUndefined(contactsRef.current)) return c;
				return {
					...c,
					capTablePermission: selectedContacts.includes(c.contactId) ? CapTablePermission.full_access : contactsRef.current?.[idx].capTablePermission,
				};
			});
		});
	}, [selectedContacts]);

	const onEditHandler = (contactId: number, key: string, value: any) => {
		setContacts((prevContacts) => {
			const contactIdx = prevContacts?.findIndex((c) => c.contactId === contactId) ?? -1;
			if (contactIdx === -1 || isNullOrUndefined(prevContacts)) return prevContacts;
			editedIds.current = editedIds.current
				? editedIds.current.includes(contactId)
					? editedIds.current
					: [...editedIds.current, contactId]
				: [contactId];
			setError(undefined);
			return [
				...prevContacts.slice(0, contactIdx),
				{
					...prevContacts[contactIdx],
					[key]: value,
				},
				...prevContacts.slice(contactIdx + 1),
			];
		});
	};

	const onToggleEdit = (contactId: number) => {
		setEditIds((prevState) => ({ ...prevState, [contactId]: !prevState[contactId] }));
	};

	const onShareHandler = async () => {
		const fullContacts = selectedContacts.map((cId) => contacts?.find((c) => c.contactId === cId));
		const duplicates = fullContacts
			.filter((c, index) => fullContacts.findIndex((contact) => contact?.email === c?.email) !== index)
			.filter((i) => !isNullOrUndefined(i));

		if (duplicates.length) {
			return showErrorModal(`An email cannot be used more than once per shareholder (${duplicates.map((c) => c?.email).join(', ')})`);
		}

		const contactsWithoutEmail = fullContacts.reduce((acc, obj) => {
			if (isNullOrUndefined(obj)) return acc;
			if (obj.email && REGEX_EMAIL.test(obj.email)) return acc;
			return [...acc, `${obj?.isLegalEntity ? obj.companyName : obj.firstName + ' ' + obj.lastName}`];
		}, [] as string[]);

		if (contactsWithoutEmail.length) {
			const isOne = contactsWithoutEmail.length === 1;
			let err = `The following shareholder${isOne ? '' : 's -'} `;
			err += isOne
				? contactsWithoutEmail.join('')
				: `${contactsWithoutEmail.slice(0, contactsWithoutEmail.length - 1).join(', ')} and ${contactsWithoutEmail[contactsWithoutEmail.length - 1]}`;
			err += ` ${isOne ? 'does' : 'do'} not have a valid email`;

			setError(err);
		}

		const legalEntitiesWithoutName = selectedContacts
			.map((cId) => contacts?.find((c) => c.contactId === cId))
			.reduce((acc, obj) => {
				if (isNullOrUndefined(obj)) return acc;
				if (
					(obj.isLegalEntity && REGEX_ONLY_LETTERS_SPACES.test(obj.firstName || '') && REGEX_ONLY_LETTERS_SPACES.test(obj.lastName || '')) ||
					!obj.isLegalEntity
				)
					return acc;
				return [...acc, `${obj?.companyName}`];
			}, [] as string[]);

		if (legalEntitiesWithoutName.length) {
			const isOne = legalEntitiesWithoutName.length === 1;
			let err = `The following legal ${isOne ? 'entity' : 'entities'} `;
			err += isOne
				? legalEntitiesWithoutName.join('')
				: `${legalEntitiesWithoutName.slice(0, legalEntitiesWithoutName.length - 1).join(', ')} and ${
						legalEntitiesWithoutName[legalEntitiesWithoutName.length - 1]
				  }`;
			err += ` ${isOne ? 'does' : 'do'} not have a valid contact name`;

			setError((prevError) => (prevError ? `${prevError}\n${err}` : err));
		}

		if (contactsWithoutEmail.length || legalEntitiesWithoutName.length) return;

		const editedAndSelected: Partial<IContactCreate>[] = (editedIds.current || [])
			.filter((id) => selectedContacts.includes(id))
			.map((id) => {
				const user = contacts?.find((c) => c.contactId === id);
				if (isNullOrUndefined(user)) return {};
				return {
					contactId: user.contactId,
					email: user.email,
					firstName: user.firstName || undefined,
					lastName: user.lastName || undefined,
				};
			});

		setIsLoading(true);
		if (editedAndSelected.length) {
			const res = await contactStore.updateContacts(editedAndSelected);
			if (res.error) return setIsLoading(false);
		}

		await contactStore.inviteUsers(selectedContacts.map((c) => ({ contactId: c, permission: { capTablePermission: CapTablePermission.full_access } })));

		removeModal?.();

		showModal({
			type: 'success',
			body: 'The cap table was successfully shared',
			qaid: 'CapTable.Modal.Share',
			width: '44.5rem',
			showProgressBar: false,
			timeout: 1500,
		});
	};

	const isContactEditable = (obj: UsersPermissionsByCompany) =>
		selectedContacts.some((id) => id === obj.contactId) && isNullOrUndefined(contactsRef.current?.find((c) => c.contactId === obj.contactId)?.email);

	const isContactSelectable = (obj: UsersPermissionsByCompany) =>
		!obj.isAdmin && contactsRef.current?.find((c) => c.contactId === obj.contactId)?.capTablePermission === CapTablePermission.no_access;

	const getCapTableImage = (permission: CapTablePermission) => {
		switch (permission) {
			case CapTablePermission.no_access:
				return {
					tooltip: 'Cap Table - No access',
					imgSrc: IC_USER_PERMISSIONS.CAPTABLE.NO_ACCESS,
				};
			case CapTablePermission.full_access:
				return {
					tooltip: 'Cap Table - Viewer',
					imgSrc: IC_USER_PERMISSIONS.CAPTABLE.VIEWER,
				};
			case CapTablePermission.editor:
			case CapTablePermission.admin:
				return {
					tooltip: 'Cap Table - Editor',
					imgSrc: IC_USER_PERMISSIONS.CAPTABLE.EDITOR,
				};
			default:
				return {};
		}
	};

	const tableColumns: TableColumn<UsersPermissionsByCompany>[] = [
		{
			name: 'firstName',
			label: 'Name',
			render(obj, value) {
				return (
					<Flex gap="1.2rem" justify="start">
						<Image width="3rem" src={obj.isAdmin ? IC_USER_PROFILE_PURPLE : obj.isLegalEntity ? IC_COMPANY_CIRCLE : IC_USER_CIRCLE} alt={value} />
						<span className={classNames({ 'text-medium': selectedContacts.includes(obj.contactId) })}>
							{obj.isLegalEntity ? (
								<>
									{`${obj.companyName} `}{' '}
									{!editIds[obj.contactId] && obj.firstName && obj.lastName && (
										<span className="sub-note">
											({obj.firstName} {obj.lastName})
										</span>
									)}
								</>
							) : (
								<>
									{obj.firstName} {obj.lastName}
								</>
							)}
						</span>
						{obj.isLegalEntity && editIds[obj.contactId] && isContactEditable(obj) && (
							<>
								<TextInput
									qaid=""
									value={obj.firstName || undefined}
									name="firstName"
									onChange={(value, name) => onEditHandler(obj.contactId, name, value)}
									onEnter={() => onToggleEdit(obj.contactId)}
									placeholder="Contact first name"
								/>
								<TextInput
									qaid=""
									value={obj.lastName || undefined}
									name="lastName"
									onChange={(value, name) => onEditHandler(obj.contactId, name, value)}
									onEnter={() => onToggleEdit(obj.contactId)}
									placeholder="Contact last name"
								/>
							</>
						)}
						{obj.isAdmin && (
							<div className={`${Style}__admin-note`}>
								<span>Admin</span>
								<span>Admin</span>
							</div>
						)}
					</Flex>
				);
			},
		},
		{
			name: 'capTablePermission',
			label: 'Access Level',
			render(obj, value) {
				const capTableImg = getCapTableImage(obj.capTablePermission);

				return <Image width="3rem" src={capTableImg.imgSrc} tooltip={capTableImg.tooltip} />;
			},
			style: { flex: 0.25 },
		},
		{
			name: 'email',
			label: 'Email',
			render(obj, value) {
				return editIds[obj.contactId] ? (
					<>
						<TextInput
							qaid=""
							value={obj.email}
							name="email"
							onChange={(value, name) => onEditHandler(obj.contactId, name, value)}
							onEnter={() => onToggleEdit(obj.contactId)}
							width="75%"
							placeholder="Specify email address"
						/>
						<Image
							onClick={(e) => {
								e.stopPropagation();
								onToggleEdit(obj.contactId);
							}}
							src={IC_CHECK_PURPLE}
						/>
					</>
				) : (
					<>
						<OverflowText>{value}</OverflowText>
						{hoverId === obj.contactId && isContactEditable(obj) && isContactSelectable(obj) && (
							<span className={`${Style}__edit-container`}>
								<Image
									onClick={(e) => {
										e.stopPropagation();
										onToggleEdit(obj.contactId);
									}}
									src={IC_EDIT2}
									tooltip="Edit"
								/>
							</span>
						)}
					</>
				);
			},
			style: { flex: 0.75 },
		},
	];

	const onRowSelect = (selected: number[]) => {
		setError(undefined);
		setSelectedContacts((prevState) => {
			const isAdd = prevState.length < selected.length;
			if (isAdd) {
				const addedId = selected.find((id) => !prevState.includes(id));
				const isEmailExists = Boolean(contacts?.find((c) => c.contactId === addedId)?.email);
				isNumber(addedId) && !isEmailExists && onToggleEdit(addedId);
			} else {
				const removedId = prevState.find((id) => !selected.includes(id));
				isNumber(removedId) && editIds[removedId] && onToggleEdit(removedId);
			}
			return selected;
		});
	};

	// if (isLoading) {
	//     return (
	//         <Spinner
	//             attachParent
	//             incorporated
	//             center
	//         />
	//     );
	// }

	return (
		<div className={Style}>
			{/* <Title>Share Cap. Table {capTable}</Title> */}

			<Table
				columns={tableColumns}
				rows={contacts}
				onRowSelect={(selected) => {
					if (isArray(selected)) {
						if (!selected.map((s: any) => isNumber(s)).includes(false)) {
							onRowSelect(selected as number[]);
						}
					}
				}}
				onSelectException={(obj) => !isContactSelectable(obj)}
				selectBy="contactId"
				// searchBy={["companyName", "fullName", "email"]}
				onRowEnter={(row) => setHoverId(row.contactId)}
				// onRowLeave={() => setHoverId(-1)}
				rowSize={1.3}
				customHeaderRender={
					availableContacts.length ? (
						<div>
							<span className="bold" style={{ fontSize: '1.5rem' }}>
								Please select the shareholders you would like to share the cap table with.
							</span>
							<br />
							<span className="sub-note">
								Note: to grant advanced permissions for shareholders, including access to other products, please refer to{' '}
								<span className="link clickable" onClick={() => history.push(Routes.usersAndPermission.index)}>
									Users & Permissions
								</span>{' '}
								page
							</span>
						</div>
					) : undefined
				}
				fallbackText="Please add shareholder/s so you can start sharing your cap table"
			/>

			<Flex justify={error ? 'between' : 'end'} margin="2rem 0 0 0" gap="2rem">
				{!!error && (
					<Comment error absolute={false}>
						{error}
					</Comment>
				)}
				<Button qaid="" onClick={onShareHandler} label="Share" isLoading={isLoading} disabled={!selectedContacts.length} />
			</Flex>
		</div>
	);
};

export default ShareCapTable;
