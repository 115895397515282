import { useEffect, useState, useRef } from "react";
import Flex from "../../../../Shared/Components/Layout/Flex";
import UpdatesLoader from "../../Loaders/UpdatesLoader";
import { formatDate, formatNumber, isNullOrUndefined } from "../../../../Shared/Utilities";
import { IC_UPDATES_GRANT, IC_UPDATES_CREATE, IC_UPDATES_DELETE, IC_UPDATES_PUBLISH } from "../../../../Assets";
import { UpdatesStyle } from "./Updates.style";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Image from "./../../../../Shared/Components/Image/index";
import { snakeCase } from "lodash";

export interface IUpdate {
    readonly userId: number;
    readonly eventName: UpdateEventNameEnum;
    readonly eventDate: Date;
    readonly firstName: string;
    readonly lastName: string;
    readonly numberOfEvents?: number;
    readonly planName?: string;
    readonly shareClassName?: string;
    readonly grantNumber?: string;
    readonly awardTypes?: string;
    readonly beneficiaryName?: string;
    readonly employeeNumber?: string;
    readonly orderType?: string;
    readonly projectDescription?: string;
    readonly projectName?: string;
    readonly granted?: string;
    readonly amountToCancel?: number;
}

export enum UpdateEventNameEnum {
    PublishedCapTable = 1,
    CreatedNewEquityPlan = 2,
    DeletedEquityPlan = 3,
    DeletedGrants = 4,
    AddedCanacelleationOrders = 5,
    AddedOrders = 6,
    AddedNewGrants = 7,
    CreatedNewBeneficiary = 8,
}

interface IProps {
    readonly className?: string;
    readonly flex?: number;
    readonly capTablePermission: boolean;
    readonly hasEquityPermission: boolean;
}

const LOAD_SIZE = 10;

const Updates = (props: IProps) => {
    const { capTableStore, equityPlansStore, auth } = useRootStore();
    const mounted = useRef<boolean>(true);
    const [data, setData] = useState<IUpdate[]>();
    const [page, setPage] = useState<number>(1);
    const scrollRef = useRef<HTMLDivElement>(null);

    function updateObjToString(update: IUpdate) {
        const excludedProperties = ["eventName", "eventDate", "firstName", "lastName", "userId", "numberOfEvents", "sourceUserID"];

        return (
            <div className="flex flex-column">
                {Object.entries(update)
                    .filter(([key, value]) => value !== null && !excludedProperties.includes(key))
                    .map(([key, value]) => {
                        const newKey = key === 'projectName' ? 'Name'
                            : key === 'projectDescription' ? 'Change summary' : (key === 'granted' && (update.orderType === 'SameDaySale' || update.orderType === 'SellOfShares')) ? 'Sold' : (key === 'granted' && update.orderType === 'ExerciseAndHold') ? 'Exercised' : snakeCase(key).replace("_", " ");

                        return (
                            <span key={value}>
                                {newKey[0].toUpperCase() + newKey.slice(1)}: {(value === 'SameDaySale' || value === 'SellOfShares') ? 'Sale' : (value === 'ExerciseAndHold') ? 'Exercise' : (newKey === 'Sold' || newKey === 'Exercised') ? formatNumber(value) : value}
                            </span>
                        );
                    })}
            </div>
        );
    }

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const onScrollHandler = (e: any) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target;
        const isBottom = offsetHeight + scrollTop > scrollHeight - 2;
        if (!isBottom) return;
        setPage((prevPage) => {
            return prevPage + 1;
        });
    };

    const fetchData = async (index: number) => {
        const res = await Promise.all([
            capTableStore.getCapTableUpdates(index, LOAD_SIZE),
            equityPlansStore.GetCfoLogEvents(index, LOAD_SIZE),
        ]);
        const capTableRes = res[0];
        const optionsRes = res[1];

        if ((capTableRes && !capTableRes.isSuccess) || (optionsRes && !optionsRes.data) || !mounted.current) return;

        const capTablePublishedList = capTableRes ? capTableRes?.data?.capTablePublishedList : [];
        const optionsUpdates = optionsRes ? optionsRes?.data : ([] as IUpdate[]);

        const combinedData: IUpdate[] = [...capTablePublishedList].concat(optionsUpdates);
        combinedData.sort((a, b) => {
            return new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime();
        });

        if (combinedData.length === 0) scrollRef.current?.removeEventListener("scroll", (e) => onScrollHandler(e));

        setData((prevData) => (prevData ? [...prevData, ...combinedData] : combinedData));
    };

    const getUpdateInfo = (update: IUpdate) => {
        const name = update.userId === auth.permissions?.userId ? "You" : `${update.firstName} ${update.lastName}`;
        switch (update.eventName) {
            case UpdateEventNameEnum.PublishedCapTable:
                return { icon: IC_UPDATES_PUBLISH, name, description: "published cap table" };
            case UpdateEventNameEnum.CreatedNewEquityPlan:
                return { icon: IC_UPDATES_CREATE, name, description: `created a new Equity plan` };
            case UpdateEventNameEnum.DeletedEquityPlan:
                return { icon: IC_UPDATES_DELETE, name, description: "deleted Equity plan" };
            case UpdateEventNameEnum.DeletedGrants:
                return { icon: IC_UPDATES_DELETE, name, description: `deleted ${update.numberOfEvents} grants from Equity plan` };
            case UpdateEventNameEnum.AddedCanacelleationOrders:
                return {
                    icon: IC_UPDATES_CREATE,
                    name,
                    description: `added a cancellation order of ${formatNumber(update.amountToCancel)} options to Equity plan`,
                };
            case UpdateEventNameEnum.AddedOrders:
                return {
                    icon: IC_UPDATES_CREATE, name, description:
                        update.orderType === 'ExerciseAndHold' ? `added an exercise order of ${formatNumber(update.granted)} options to Equity plan` : `added a sale order of ${formatNumber(update.granted)} shares to Equity plan`
                };
            case UpdateEventNameEnum.AddedNewGrants:
                return { icon: IC_UPDATES_GRANT, name, description: "added new grant" };
            case UpdateEventNameEnum.CreatedNewBeneficiary:
                return { icon: IC_UPDATES_CREATE, name, description: "added new beneficiary" };

            default:
                break;
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);

    useEffect(() => {
        if (isNullOrUndefined(scrollRef.current) || scrollRef.current.getAttribute("listener") === "true") return;
        scrollRef.current.setAttribute("listener", "true");

        scrollRef.current.addEventListener("scroll", (e) => onScrollHandler(e));

        return () => {
            scrollRef.current?.removeEventListener("scroll", (e) => onScrollHandler(e));
        };
    }, [scrollRef.current, data]);

    return (
        <Flex
            className={`${props.className} ${UpdatesStyle}`}
            flex={props.flex}
            align="start"
            direction="column"
        >
            {data === undefined ? (
                <UpdatesLoader />
            ) : (
                <>
                    {data.length === 0 ? (
                        <Flex
                            className="noData"
                            direction="column"
                            align="start"
                        >
                            <span className="noData__title">No updates yet</span>
                            <div className="noData__background" />
                        </Flex>
                    ) : (
                        <Flex
                            className="updatesContainer"
                            direction="column"
                            align="start"
                            justify="start"
                        >
                            <Flex
                                className="updatesContainer__updates"
                                direction="column"
                                align="start"
                                justify="start"
                                ref={scrollRef}
                            >
                                {data.map((update, index) => (
                                    <Flex
                                        key={index}
                                        justify="start"
                                        align="start"
                                        className="rowContainer"
                                        flex={0}
                                    >
                                        <Image
                                            tooltip={updateObjToString(update)}
                                            className={"rowContainer__icon"}
                                            src={getUpdateInfo(update)?.icon}
                                            alt="logo"
                                        />
                                        <Flex
                                            align="start"
                                            direction="column"
                                        >
                                            <Flex
                                                justify="start"
                                                margin="0"
                                                align="start"
                                            >
                                                <span className="rowContainer__action">
                                                    <span className="name">{getUpdateInfo(update)?.name}</span>
                                                    {getUpdateInfo(update)?.description}
                                                </span>
                                            </Flex>
                                            <Flex
                                                justify="start"
                                                gap={10}
                                            >
                                                {update.shareClassName && <span>{`'${update.shareClassName}`}</span>}
                                                <span className="rowContainer__date">{formatDate(update.eventDate)}</span>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};

export default Updates;
