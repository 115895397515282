import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";
import { useEffect, useState } from "react";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Button from "../../../../../Shared/Components/Button/Button";
import { formatNumber, isNullOrUndefined } from "../../../../../Shared/Utilities";
import { ClickAwayListener } from "@mui/material";
import classNames from "classnames";
import { AddShareholderProps, EditCommonSharesProps } from "../../../types";
import Tooltip from "../../../../../Shared/Components/Tooltip";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { ShareClassTypeOptions } from "../../../../../Models/API/enums";

const Style = css({
	label: "EditCommonShares",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	background: "#ffffff",
	zIndex: 3,
	height: "100%",
	width: "100%",
	padding: "6rem 9rem",
	textAlign: "center",
	borderRadius: 16,
	"&__input": {
		marginTop: "2rem",
		width: "18rem",
	},
	"&.arrow": {
		width: "44rem",
		height: "27.5rem",
		border: `1px solid ${appConfig.style.colors.background1}`,
		borderRadius: 16,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		top: "50%",
		right: "50%",
		transform: "translate(50%, -50%)",
		position: "absolute",
		"&:after": {
			content: "''",
			position: "absolute",
			width: 0,
			height: 0,
			top: 0,
			left: "calc(50% + 1.4rem)",
			// padding: "6rem 11rem",
			borderRadius: 4,
			border: "1rem solid black",
			borderColor: "transparent transparent #ffffff #ffffff",
			transformOrigin: "0 0",
			transform: "rotate(135deg)",
			boxShadow: "-4px 4px 4px 1px rgba(0, 0, 0, 0.05)",
		},
	},
	".create-explanation-text": {
		marginTop: "2rem",
		whiteSpace: "pre-line",
	},
});

const EditCommonShares = ({ onAddCommonShares, minNumberOfShares, numberOfRegisteredShares, onCancel, removeModal, isEdit = true }: EditCommonSharesProps) => {
	if (isNullOrUndefined(minNumberOfShares)) minNumberOfShares = 0;

	const [numberOfShares, setNumberOfShares] = useState<number | undefined>(numberOfRegisteredShares);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			setIsLoading(false);
		};
	}, []);

	const onCancelHandler = () => {
		onCancel?.();
		removeModal?.();
	};

	const onSubmit = async () => {
		if (isNullOrUndefined(numberOfShares) || numberOfShares < minNumberOfShares!) return;

		setIsLoading(true);
		await onAddCommonShares?.(numberOfShares);
		setIsLoading(false);
		removeModal?.();
	};

	return (
		<ClickAwayListener onClickAway={onCancelHandler}>
			<div className={classNames(Style, { arrow: false && isNullOrUndefined(removeModal) })}>
				<Flex gap="0.8rem" className="bold">
					{isEdit ? (
						"Edit the number of registered common shares"
					) : (
						<>
							<Tooltip title="Once you define the registered common shares, you will be able to issue shares to the shareholders" showIcon>
								Please add a number of registered common shares
							</Tooltip>
						</>
					)}
				</Flex>
				{!isEdit && <div className="create-explanation-text">{"You can edit this amount\nfrom the common shares column"}</div>}
				<NumberInput
					qaid="CapTableCommonShares.Input.Edit"
					value={numberOfShares}
					onChange={(value) => setNumberOfShares(value)}
					comment={minNumberOfShares ? `Minimum amount: ${formatNumber(minNumberOfShares)}` : undefined}
					containerClassName={`${Style}__input`}
					onEnter={onSubmit}
				/>
				<Flex gap="2rem" margin="4rem 0 0 0" flex={0}>
					<Button qaid="CapTableCommonShares.Button.Cancel" label="Cancel" cancel onClick={onCancelHandler} />
					<Button
						qaid="CapTableCommonShares.Button.Done"
						label="Done"
						onClick={onSubmit}
						isLoading={isLoading}
						disabled={isNullOrUndefined(numberOfShares) || numberOfShares < minNumberOfShares}
					/>
				</Flex>
			</div>
		</ClickAwayListener>
	);
};

export default EditCommonShares;
