import { isValid, parse } from "date-fns";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { TableColumn } from "../../../../../../../Shared/Components/Table/Table";
import { DateFormatsEnum, ExcelProps, isArray } from "../../../../../../../Shared/Utilities";
import appConfig from "../../../../../../../config/config";
import {
    createDynamicTable,
    shareClassValuationTable,
    shareClassesWaterfallTable,
    shareHolderValuationTable,
    shareHoldersWaterfallTable,
    sortEquityHolderParsedArray,
} from "./helpers";
import { BreakPoint, IDestructuredWaterfallSummaryData, IEquityHolderParsed, IWaterfallSummaryDataParsed } from './types';

const middleCenter = {
    vertical: "middle",
    horizontal: "center",
} as ExcelJS.Alignment;
const middleLeft = {
    vertical: "middle",
    horizontal: "left",
} as ExcelJS.Alignment;
const middleRight = {
    vertical: "middle",
    horizontal: "right",
} as ExcelJS.Alignment;
const mainTitle = { bold: true, color: { argb: "#ffffff" } } as ExcelJS.Font;

const setBackground = (cell: ExcelJS.Cell, color: string = appConfig.style.colors.color1) => {
    cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
            argb: color.replace("#", ""),
        },
    };
};

const setAlignment = (cell: ExcelJS.Cell, alignment: Partial<ExcelJS.Alignment> = middleCenter) => {
    cell.alignment = alignment;
};

const boldText = {
    bold: true,
    color: { argb: appConfig.style.colors.text1 },
} as ExcelJS.Font;

const setFont = (cell: ExcelJS.Cell, font: Partial<ExcelJS.Font> = boldText) => {
    cell.font = {
        ...cell.font,
        ...font,
        color: {
            argb: font.color?.argb?.replace("#", "") || appConfig.style.colors.text1.replace("#", ""),
        },
    };
};

const setBorders = (cell: ExcelJS.Cell, top: boolean, right: boolean, bottom: boolean, left: boolean, color: string, style: ExcelJS.BorderStyle = "thick") => {
    if (bottom) {
        cell.border = {
            ...cell.border,
            bottom: {
                style,
                color: {
                    argb: color.replace("#", ""),
                },
            },
        };
    }
    if (top) {
        cell.border = {
            ...cell.border,
            top: {
                style,
                color: {
                    argb: color.replace("#", ""),
                },
            },
        };
    }
    if (left) {
        cell.border = {
            ...cell.border,
            left: {
                style,
                color: {
                    argb: color.replace("#", ""),
                },
            },
        };
    }
    if (right) {
        cell.border = {
            ...cell.border,
            right: {
                style,
                color: {
                    argb: color.replace("#", ""),
                },
            },
        };
    }
};

interface Props {
    data: IDestructuredWaterfallSummaryData | null;
    projectName?: string;
    summaryData?: IWaterfallSummaryDataParsed;
    exitValue?: number;
}

const useExportSummary = ({ data, projectName = "", summaryData, exitValue = 0 }: Props) => {
    // const { waterfallStore } = useRootStore();

    const buildTableData = <T extends object>({ rows, columns }: ExcelProps<T>) => {
        return (
            rows?.map((obj: T) =>
                columns
                    .filter((column) => column.name !== "actions" && column.name !== "menu")
                    .map((col) =>
                        col.format
                            ? col.format(isArray(col.name) ? col.name.map((key) => obj[key]) : obj[col.name as keyof T], obj)
                            : isArray(col.name)
                            ? col.name.map((key) => obj[key]).join(" ")
                            : obj[col.name as keyof T]
                    )
            ) || []
        );
    };

    const setGeneralData = (ws: ExcelJS.Worksheet, title: string, includeExitValue: boolean = true) => {
        const titleCell = ws.getCell("A1");
        titleCell.value = title;
        setFont(titleCell);

        const nameCell = ws.getCell("C1");
        nameCell.value = projectName;
        setFont(nameCell);
        setAlignment(nameCell);

        if (!includeExitValue) return;

        const exitValueCells = [ws.getCell("B3"), ws.getCell("C3")];
        exitValueCells[0].value = "Exit Value ($M)";
        exitValueCells[1].value = exitValue;
        exitValueCells.forEach((cell, idx) => {
            setFont(cell, boldText);
            if (idx === 1) {
                setAlignment(cell);
                setBorders(cell, true, true, true, false, appConfig.style.colors.text1);
            } else {
                setBorders(cell, true, false, true, true, appConfig.style.colors.text1);
            }
            // setBackground(cell, appConfig.style.colors.background3);
        });
    };

    const setMainTable = (ws: ExcelJS.Worksheet, title: string, columns: TableColumn<IEquityHolderParsed>[], rows: IEquityHolderParsed[]) => {
        const shareClassesTitleCell = ws.getCell("B6");
        shareClassesTitleCell.value = title;
        shareClassesTitleCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
                argb: appConfig.style.colors.color1.replace("#", ""),
            },
        };
        setFont(shareClassesTitleCell, mainTitle);

        const titleCells = ws.getRow(ws.rowCount + 2);
        titleCells.values = ["", ...columns.map((col: Partial<TableColumn<IEquityHolderParsed>>) => col.label)];
        titleCells.eachCell((cell, idx) => {
            if (idx === 1) return;
            // setBorders(cell, false, false, true, false, appConfig.style.colors.text1, "thin");

            setBackground(cell, appConfig.style.colors.text1);
            setFont(cell, { size: 9, color: { argb: "#ffffff" } });
        });

        let rowCount = ws.rowCount + 1;

        buildTableData({
            rows,
            columns,
            showTotal: true,
        }).forEach((data: any, idx) => {
            const row = ws.getRow(rowCount + idx);
            row.font = { ...row.font, size: 9 };
            row.values = ["", ...data];
            row.eachCell((cell) => {
                setAlignment(cell, middleLeft);
            });
        });

        ws.getRow(ws.rowCount).eachCell((cell, idx) => {
            if (idx === 1) return;
            setBackground(cell, appConfig.style.colors.background1);
            setFont(cell);
        });
    };

    const setClassesTables = (ws: ExcelJS.Worksheet, data: IEquityHolderParsed[], title: string, subTitle: string, isShareClass: boolean) => {
        let rowCount = ws.rowCount + 2;

        data.forEach((shareClass) => {
            rowCount = ws.rowCount + 1;
            const titleCell = ws.getRow(rowCount + 2);
            titleCell.values = ["", `${title}: ${shareClass.name}`];
            setBackground(titleCell.getCell(2));
            setFont(titleCell.getCell(2), mainTitle);

            // Sensitivity analysis of share class

            const sensitivityTitleRow = ws.getRow(rowCount + 4);
            sensitivityTitleRow.values = ["", "Sensitivity Analysis", shareClass.name];
            // sensitivityTitleRow.font = { ...sensitivityTitleRow.font, size: 9 };
            sensitivityTitleRow.eachCell((cell, idx) => {
                if (idx === 1) return;
                setBorders(cell, false, false, true, false, appConfig.style.colors.text1);
                setFont(cell);
            });

            const originalInvestmentRow = ws.getRow(rowCount + 6);
            originalInvestmentRow.values = ["", "Original Investment ($M)", shareClass.originalInvestment];
            originalInvestmentRow.font = {
                ...originalInvestmentRow.font,
                size: 9,
            };
            originalInvestmentRow.alignment = {
                vertical: "middle",
                horizontal: "left",
            };

            const shareHoldersRow = ws.getRow(rowCount + 7);
            shareHoldersRow.values = ["", subTitle, shareClass.shareClassHolders.map((sh: any) => `${sh.name} (${sh.shares}%)`).join(", ")];
            shareHoldersRow.font = { ...shareHoldersRow.font, size: 9 };

            createDynamicTable(shareClass).forEach((data, idx) => {
                const row = ws.getRow(rowCount + 9 + idx);
                row.values = ["", ...data];
                row.font = { ...row.font, size: 9 };
                row.eachCell((cell, cIdx) => {
                    if (cIdx === 1) return;
                    if (idx === 0) {
                        // setBorders(cell, false, false, true, false, appConfig.style.colors.text1, "thin");

                        setBackground(cell, appConfig.style.colors.text1);
                        // setFont(cell);

                        setFont(cell, { color: { argb: "#ffffff" } });
                    }
                    setAlignment(cell, middleLeft);
                });
            });

            // Waterfall of share class

            rowCount = ws.rowCount;

            const waterfallClassRow = ws.getRow(rowCount + 2);
            waterfallClassRow.values = ["", "Waterfall", shareClass.name];
            // waterfallClassRow.font = { ...waterfallClassRow.font, size: 9 };
            waterfallClassRow.eachCell((cell, idx) => {
                if (idx === 1) return;
                setBorders(cell, false, false, true, false, appConfig.style.colors.text1);
                setFont(cell);
            });

            const titleCells = ws.getRow(rowCount + 4);
            titleCells.values = [
				'',
				...(isShareClass ? shareClassesWaterfallTable : shareHoldersWaterfallTable).map((col: TableColumn<BreakPoint>) => col.label),
			];
            titleCells.eachCell((cell, idx) => {
                if (idx === 1) return;
                // setBorders(cell, false, false, true, false, appConfig.style.colors.text1, "thin");

                setBackground(cell, appConfig.style.colors.text1);
                setFont(cell, { size: 9, color: { argb: "#ffffff" } });
            });

            buildTableData({
                rows: shareClass.breakPoints,
                columns: isShareClass ? shareClassesWaterfallTable : shareHoldersWaterfallTable,
            }).forEach((data, idx) => {
                const row = ws.getRow(rowCount + 5 + idx);
                row.font = { ...row.font, size: 9 };
                row.values = ["", ...data];
                row.eachCell((cell) => {
                    setAlignment(cell, middleLeft);
                });
            });
        });
    };

    const fixGeneralDesign = (ws: ExcelJS.Worksheet) => {
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                cell.font = {
                    ...cell.font,
                    name: "Arial",
                    color: cell.font?.color || {
                        argb: appConfig.style.colors.text1.replace("#", ""),
                    },
                };

                const cellValue = cell.value?.toString() || "";
                const isNumber = /^[+-]?\d+(\.\d+)?$/.test(cellValue.replaceAll(",", ""));
                if (isNumber) {
                    cell.value = parseFloat(cellValue.replaceAll(",", ""));
                    cell.numFmt = cell.value % 1 === 0 ? "#,##0" : "#,##0.00";
                    if (cell.value < 1) {
                        cell.font = {
                            ...cell.font,
                            color: {
                                argb: appConfig.style.colors.danger.replace("#", ""),
                            },
                        };
                    }
                } else if (!isNaN(parseInt(cellValue)) && cellValue.endsWith("%")) {
                    cell.value = parseFloat(cellValue) / 100;
                    cell.numFmt = "0.00%";
                } else if (isValid(parse(cellValue, DateFormatsEnum.LABEL_SHORT, new Date()))) {
                    cell.numFmt = DateFormatsEnum.LABEL_SHORT;
                }
            });
        });
        ws.columns.forEach((col, idx) => (col.width = idx === 1 ? 30 : idx > 1 ? 25 : 10));
    };

    const createShareClassSheet = (ws: ExcelJS.Worksheet, shareClasses: IEquityHolderParsed[], shareClassesTableData: IEquityHolderParsed[]) => {
        setGeneralData(ws, "Receives L.P");
        setMainTable(ws, "Share Class Valuation Table", shareClassValuationTable, shareClassesTableData);
        setClassesTables(ws, shareClasses, "Share Class", "Shareholers", true);
        fixGeneralDesign(ws);
    };

    const createShareHolderSheet = (ws: ExcelJS.Worksheet, shareHolders: IEquityHolderParsed[], shareHoldersTableData: IEquityHolderParsed[]) => {
        setGeneralData(ws, "Shareholders Analysis Summary");
        setMainTable(ws, "Shareholder Valuation Table", shareHolderValuationTable, shareHoldersTableData);
        setClassesTables(ws, shareHolders, "Shareholder", "Invested in share classes", false);
        fixGeneralDesign(ws);
    };

    const createBreakpointsSheet = (ws: ExcelJS.Worksheet, shareClasses: IEquityHolderParsed[]) => {
        setGeneralData(ws, "Breakpoint Analysis", false);
        if (!shareClasses?.length) return;

        let strikePriceRow = ws.getRow(3);
        const ranges = buildTableData({
            rows: shareClasses[0].breakPoints,
            columns: shareClassesWaterfallTable,
        });

        strikePriceRow.values = ["", "Strike Price ($M) - Description", ...ranges.map((range) => range[0])];

        strikePriceRow.eachCell((cell, idx) => {
            if (idx === 1) return;
            setBackground(cell);
            setFont(cell, { bold: true, color: { argb: "#ffffff" } });

            if (idx > 2) setAlignment(cell, middleRight);
            else setAlignment(cell, middleLeft);
        });
        strikePriceRow.height = 30;

        shareClasses.forEach((shareClass, idx) => {
            const rowIdx = 4 + idx;
            const shareClassRow = ws.getRow(rowIdx);
            shareClassRow.values = ["", shareClass.name, ...shareClass.breakPoints.map((bp) => bp.waterfallDescription)];
            shareClassRow.eachCell((cell, cIdx) => {
                if (cIdx > 2) setAlignment(cell, middleRight);
                else setAlignment(cell, middleLeft);

                if (cIdx !== 1 && idx % 2 === 0) {
                    setBackground(cell, appConfig.style.colors.background1);
                }
            });
            shareClassRow.height = 30;
        });

        strikePriceRow = ws.getRow(ws.rowCount + 3);

        strikePriceRow.values = ["", "Strike Price ($M) - Percentrage", ...ranges.map((range) => range[0])];
        strikePriceRow.eachCell((cell, idx) => {
            if (idx === 1) return;
            setBackground(cell);
            setFont(cell, { bold: true, color: { argb: "#ffffff" } });

            if (idx > 2) setAlignment(cell, middleRight);
            else setAlignment(cell, middleLeft);
        });

        // const percentageCell = ws.getCell(`B${ws.rowCount + 2}`);
        // percentageCell.value = "Percentrage";
        // setBackground(percentageCell, appConfig.style.colors.background1);
        // setFont(percentageCell, { bold: true });

        const rowCount = ws.rowCount + 1;

        shareClasses.forEach((shareClass, idx) => {
            const rowIdx = rowCount + idx;
            const shareClassRow = ws.getRow(rowIdx);
            shareClassRow.values = ["", shareClass.name, ...shareClass.breakPoints.map((bp) => `${bp.proRataValue && bp.proRataValue * 100}%`)];
            shareClassRow.eachCell((cell, cIdx) => {
                if (cIdx > 2) setAlignment(cell, middleRight);
                else setAlignment(cell, middleLeft);

                if (cIdx !== 1 && idx % 2 === 0) {
                    setBackground(cell, appConfig.style.colors.background1);
                }
            });
            shareClassRow.height = 30;
        });
        strikePriceRow.height = 30;

        fixGeneralDesign(ws);
    };

    const onExportSummary = () => {
        if (!data) return;

        const { shareClassesTableData, shareHolders, shareClasses, shareHoldersTableData } = data;
        const wb = new ExcelJS.Workbook();
        const fileName = `Altshare - Waterfall Summary (${projectName}).xlsx`;
        const pageSettings = {
            pageSetup: {
                horizontalCentered: true,
                verticalCentered: true,
                fitToPage: true,
                orientation: "landscape",
            },
            views: [{ showGridLines: false }],
        } as Partial<ExcelJS.AddWorksheetOptions>;

        const shareHolderWs = wb.addWorksheet("Shareholders Analysis Summary", pageSettings);
        createShareHolderSheet(shareHolderWs, shareHolders, shareHoldersTableData);

        const shareClassWs = wb.addWorksheet("Share Classes Analysis Summary", pageSettings);
        createShareClassSheet(shareClassWs, shareClasses, shareClassesTableData);

        const breakPointsWs = wb.addWorksheet("Breakpoint Analysis", pageSettings);
        createBreakpointsSheet(breakPointsWs, shareClasses);

        wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
        });
    };

    return {
        onExportSummary,
    };
};

export default useExportSummary;
