import { action, makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { ProjectsUserInfo } from '../Models/API/CapTable';
import {
	CapTableData,
	CapTableRequest,
	CreateProjectRequest,
	PayoffSelectionOptions,
	PreferenceShareClassRequest,
	PreferenceShareClassRight,
	ScenarioRequest,
	ShareClassRight,
	ShareClassRightIssueRequest,
	ShareClassRightRequest,
	ShareClassRightResponse,
	ToggleReviewerRequest,
	UploadOptionsRequest,
	WaterfallAwardType,
	WaterfallDefaultParams,
	WaterfallProject,
} from '../Models/API/Waterfall/IForm';
import { ProductTypeEnum } from '../Models/API/enums';
import { getDestrcturedValuationData, parseWaterfallSummaryData } from '../Screens/Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/helpers';
import {
	IShareClassesSummaryCalculation,
	IWaterfallSummaryData,
	IWaterfallSummaryDataParsed,
} from '../Screens/Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import { WaterfallService } from '../Services/WaterfallService';
import { isBoolean, isNullOrUndefined } from '../Shared/Utilities';
import { RootStore } from './RootStore';

export interface ScenariosForm {
	valuationDate: Date | undefined;
	projectName: string | undefined;
	exitValue: number | undefined;
	timeToExit: number | undefined;
	description: string;
}

export enum WaterfallFormSteps {
	'scenarios' = 0,
	'capTable' = 1,
	'optionsPlan' = 2,
	'shareClasses' = 3,
	'preferencesTerms' = 4,
	'summary' = 5,
}

export default class WaterfallStore {
	projectName: string | undefined = undefined;
	protected waterfallService: WaterfallService;
	waterfallId: number = 0;
	lastAllowedStep: number = 0;
	originalValuationDate: Date | undefined = undefined;
	projects?: WaterfallProject[] | null = null;
	summaryData: IWaterfallSummaryDataParsed | null = null;
	scenariosForm = {
		valuationDate: undefined,
		projectName: undefined,
		exitValue: undefined,
		timeToExit: undefined,
		description: '',
	} as ScenariosForm;
	shareClasses?: ShareClassRight[] = undefined;
	preferenceShareClasses?: PreferenceShareClassRight[] = undefined;
	// ui related observables:
	isViewMode: boolean | undefined = undefined;
	isNewProject: boolean = false;
	isOptionsAllowed: boolean = true;
	isShowError: string | null = null;
	summaryCounter = 0;

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this, {
			resetForm: action,
			updateScenarioForm: action,
			getProjects: action,

			// step 6:
			setSummary: action,
			setIsShowError: action,
		});
		makePersistable(this, {
			name: 'WaterfallStore',
			properties: ['scenariosForm', 'waterfallId', 'lastAllowedStep', 'projects', 'isViewMode', 'projectName', 'originalValuationDate'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});

		this.waterfallService = new WaterfallService();
	}

	get waterfallProjects() {
		return this.projects as WaterfallProject[];
	}

	get currentWaterfallId() {
		return this.waterfallId;
	}

	set currentWaterfallId(id: number) {
		this.waterfallId = id;
	}

	setProjectViewMode(isViewMode: boolean | undefined) {
		this.isViewMode = isViewMode;
	}

	setProjectName(name: string | undefined) {
		this.projectName = name;
	}

	resetWaterfallProject() {
		this.waterfallId = 0;
		this.setProjectName(undefined);
		this.lastAllowedStep = 0;
		this.originalValuationDate = undefined;
		this.projects = null;
		this.summaryData = null;
		this.resetForm();
		this.shareClasses = undefined;
		this.preferenceShareClasses = undefined;
		// ui related observables:
		this.isViewMode = undefined;
		this.isNewProject = false;
		this.isOptionsAllowed = false;
	}

	resetStoreToDefaultValues = () => {
		this.resetForm();
		this.projects = null;
		this.isNewProject = false;
		this.setSummary(null);
		this.setProjectName(undefined);
		clearPersistedStore(this);
	};

	resetForm = () => {
		this.waterfallId = 0;
		this.scenariosForm = {
			valuationDate: undefined,
			projectName: undefined,
			exitValue: undefined,
			timeToExit: undefined,
			description: '',
		} as ScenariosForm;
		this.lastAllowedStep = 0;
		this.setProjectViewMode(undefined);
	};

	clearProjects = () => (this.projects = null);

	setLastAllowedStep = (step: number = WaterfallFormSteps.scenarios) => {
		step -= 1;

		this.lastAllowedStep = step === WaterfallFormSteps.summary && this.summaryData ? 7 : step;
	};

	updateScenarioForm = (name: string, value?: string | number) => {
		this.scenariosForm = {
			...this.scenariosForm,
			[name]: value,
		};
	};

	// General API

	getProjects = async (companyId: number) => {
		const res = await this.waterfallService.getProjects(companyId);
		this.projects = res.data;
		return res;
	};

	deleteProject = async (waterfallId: number) => {
		const res = await this.waterfallService.deleteProject(waterfallId);
		if (res.isSuccess) {
			this.projects = this.projects?.filter((project) => project.waterfallId !== waterfallId);
		}
		return res.errorMessage;
	};

	getProject = async (waterfallId: number) => {
		const res = await this.waterfallService.getProject(waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const { exitValue, timeToExit, projectName, valuationDate, description, unfulfillmentStep } = res.data;
		this.originalValuationDate = valuationDate;
		// if doesnt need the project return a promise
		this.scenariosForm = {
			exitValue: exitValue || undefined,
			timeToExit: timeToExit || undefined,
			projectName,
			valuationDate,
			description,
		};
		// this.updateCurrentStep(step);
		this.setProjectName(projectName);
		this.setLastAllowedStep(unfulfillmentStep);

		return res;
	};

	duplicateProject = async (payload: CreateProjectRequest) => {
		return this.waterfallService.duplicateProject(payload);
	};

	getSteps = async (waterfallId: number = this.waterfallId, companyId: number = this.rootStore.companyStore.companyId): Promise<void> => {
		const res = await this.waterfallService.getSteps({ waterfallId, companyId });
		if (isNullOrUndefined(res.data)) return;

		this.isOptionsAllowed = res.data.isOptionsAllowed;
		this.setLastAllowedStep(res.data.unfulfillmentStep);
	};

	// # step 1:
	createProject = async (payload: CreateProjectRequest) => {
		const res = await this.waterfallService.createProject(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.waterfallId = res.data.waterfallId;
		this.setProjectName(payload.projectName);
		this.getSteps();
	};

	getProjectReviewers = (payload: WaterfallDefaultParams) => {
		return this.waterfallService.getProjectReviewers(payload);
	};

	toggleProjectReviewer = (payload: ToggleReviewerRequest) => {
		return this.waterfallService.toggleProjectReviewer(payload);
	};

	updateScenarioStep = async (payload: ScenarioRequest) => {
		const res = await this.waterfallService.updateScenarioStep(payload);
		if (isNullOrUndefined(res.data)) return res;

		this.setLastAllowedStep(res.data.unfulfillmentStep);
		this.originalValuationDate = payload.valuationDate;
		return res;
	};

	// # step 2:
	// ## get/set capTableBases - ProjectsUserInfo[]:
	getCapTableBases = async (companyId: number) => {
		const res = await this.waterfallService.getCapTableBases(companyId);
		if (!res.data || isNullOrUndefined(res.data?.projectsUserList)) return res;

		return res;
	};

	// get a snapshot of a captable base (result might not be updated with the latest captable):
	getSavedCapTableBase = async (companyId: number, waterfallId: number) => {
		const res = await this.waterfallService.getSavedCapTableBase(companyId, waterfallId);
		if (isNullOrUndefined(res.data)) return res;

		const isShowSelect = res.data.captableImportDate === null;
		return res;
	};

	// get captable base directly from captable module:
	getSingleCapTableData = async (payload: CapTableRequest) => {
		const res = await this.waterfallService.getSingleCapTableData(payload);

		if (res.data) {
			this.getSteps();
		}
		return res;
	};

	// step 3:

	uploadOptions = async (payload: UploadOptionsRequest) => {
		const res = await this.waterfallService.uploadOptions(payload);
		this.getSteps();
		return res;
	};

	reimportOptions = (companyId: number = this.rootStore.companyStore.companyId, waterfallId: number = this.waterfallId) => {
		return this.waterfallService.reimportOptions(companyId, waterfallId);
	};

	getOptions = (waterfallId: number = this.waterfallId) => {
		return this.waterfallService.getOptions(waterfallId);
	};

	updateOptionsMode = (payload: UploadOptionsRequest) => {
		return this.waterfallService.updateOptionsMode(payload);
	};

	getValuationDate = (waterfallId: number = this.waterfallId) => {
		return this.waterfallService.getValuationDate(waterfallId);
	};

	addAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.waterfallService.addAwardType(data, waterfallId);
	};

	updateAwardType = (data: WaterfallAwardType, waterfallId: number = this.waterfallId) => {
		return this.waterfallService.updateAwardType(waterfallId, data);
	};

	deleteAwardType = (shareClassId: number, waterfallId: number = this.waterfallId) => {
		return this.waterfallService.deleteAwardType(waterfallId, shareClassId);
	};

	// step 4:
	getShareClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.waterfallService.getShareClassRights(waterfallId);
		this.shareClasses = res.data?.shareClasses;
	};

	getShareClassRight = async (payload: WaterfallDefaultParams) => {
		return this.waterfallService.getShareClassRight(payload);
	};

	updateClassRight = async (payload: ShareClassRightRequest | ShareClassRightIssueRequest) => {
		const res = await this.waterfallService.updateClassRight(payload);
		if (!res.isSuccess) return;

		this.updateClassRightValue(payload.shareClassId, 'isPreferred', true);
		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
	};

	deleteClassRight = async (payload: WaterfallDefaultParams): Promise<void> => {
		const res = await this.waterfallService.deleteClassRight(payload);
		if (!res.isSuccess) return;

		const areClassesAdded = this.shareClasses?.every((s) => isBoolean(s.isPreferred));
		areClassesAdded && this.getSteps();
		this.updateClassRightValue(payload.shareClassId as number, 'isPreferred', null);
	};

	updateLastModified(payload: WaterfallDefaultParams) {
		return this.waterfallService.updateLastModified(payload);
	}

	private updateClassRightValue = (shareClassId: number, key: string, value: any) => {
		if (isNullOrUndefined(this.shareClasses)) return;
		const shareClassIdx = this.shareClasses.findIndex((sc) => sc.shareClassId === shareClassId);
		this.shareClasses = [
			...this.shareClasses.slice(0, shareClassIdx),
			{
				...this.shareClasses[shareClassIdx],
				[key]: value,
			},
			...this.shareClasses.slice(shareClassIdx + 1),
		];
	};

	// step 5

	getPreferenceClassRights = async ({ waterfallId }: WaterfallDefaultParams) => {
		const res = await this.waterfallService.getPreferenceClassRights(waterfallId);
		if (!res.isSuccess) return;

		this.preferenceShareClasses = res.data;
	};

	addPreferenceClassRight = async (payload: PreferenceShareClassRequest) => {
		// await new Promise(res => setTimeout(res, 2000));
		const res = await this.waterfallService.addPreferenceClassRight(payload);
		if (!res.isSuccess) return;

		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				...res.data,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	deletePreferenceClassRight = async (payload: WaterfallDefaultParams) => {
		// await new Promise(res => setTimeout(res, 2000));
		await this.waterfallService.deletePreferenceClassRight(payload);
		this.getSteps();
		if (isNullOrUndefined(this.preferenceShareClasses)) return;
		const scIdx = this.preferenceShareClasses.findIndex((sc) => sc.shareClassId === payload.shareClassId);
		this.preferenceShareClasses = [
			...this.preferenceShareClasses.slice(0, scIdx),
			{
				...this.preferenceShareClasses[scIdx],
				shareClassPreferenceTermsTypeId: PayoffSelectionOptions.None,
			},
			...this.preferenceShareClasses.slice(scIdx + 1),
		];
	};

	updatePreferenceClassRights = async (payload: WaterfallDefaultParams) => {
		return this.waterfallService.updatePreferenceClassRights(payload);
	};

	// Step 6
	// async getSummaryWithDefaultValues() {
	//     const payload = {
	//         waterfallId: this.waterfallId,
	//         companyId: this.rootStore.companyStore.companyId,
	//     };

	//     const res = await this.checkAndGetSummary(payload);
	//     // const res = await this.getSummary(payload);
	//     console.log("parsed res", res);
	//     const parsed = parseWaterfallSummaryData(res as any);
	//     console.log("parsed", parsed);
	//     this.setSummary(parsed);
	//     return res;
	// }

	// async checkAndGetSummary(data?: WaterfallDefaultParams) {
	//     // TODO: check data cleared on params change
	//     // console.log('Summerizing waterfall', this.summaryCounter)
	//     // data && console.log('CheckAndGetSummary DATA', data)

	//     const defaultData = {
	//         waterfallId: this.waterfallId,
	//         companyId: this.rootStore.companyStore.companyId,
	//     };

	//     if (!data) data = defaultData;

	//     if (this.summaryData && this.summaryCounter > 2) return;

	//     const time = () => {
	//         if (this.summaryCounter <= 10) {
	//             return 5000;
	//         } else if (this.summaryCounter > 10 && this.summaryCounter <= 20) {
	//             return 7500;
	//         } else if (this.summaryCounter > 20 && this.summaryCounter <= 30) {
	//             return 10000;
	//         } else if (this.summaryCounter > 30 && this.summaryCounter <= 40) {
	//             return 13000;
	//         } else if (this.summaryCounter > 40 && this.summaryCounter <= 50) {
	//             return 16000;
	//         } else if (this.summaryCounter > 50 && this.summaryCounter <= 60) {
	//             return 25000;
	//         } else {
	//             return 45000;
	//         }
	//     };

	//     const summary = await this.getWaterfallSummary(data);
	//     if (summary?.totalShareClasses) {
	//         this.summaryCounter = 0;
	//     } else {
	//         this.summaryCounter++;
	//         setTimeout(() => {
	//             this.checkAndGetSummary(data);
	//         }, time());
	//     }
	//     return summary;
	// }

	async getWaterfallSummary(waterfallId: number = this.waterfallId) {
		const res = await this.waterfallService.getSummary(waterfallId);
		if (res.data?.totalShareClasses) {
			const parsed = parseWaterfallSummaryData(res.data);
			this.setSummary(parsed);
			this.updateScenarioForm('exitValue', res.data.businessValue);
			this.setLastAllowedStep(7);
		}
		return res;
	}

	async runSummary(data?: WaterfallDefaultParams, isUpdate: boolean = false) {
		const defaultData = {
			waterfallId: this.waterfallId,
			companyId: this.rootStore.companyStore.companyId,
		};
		const res = await this.waterfallService.runSummary(data ?? defaultData);
		if (isUpdate && res.data?.totalShareClasses) {
			const parsed = parseWaterfallSummaryData(res.data);
			this.setSummary(parsed);
			this.updateScenarioForm('exitValue', res.data.businessValue);

			this.setLastAllowedStep(7);
		}
		// if (res.data) {
		//     this.setSummary(parseWaterfallSummaryData(res.data));
		// }
		return res;
	}
	// HERE

	// async getSummaryIfNoSummaryExist(): Promise<HigherLevelResponse<IWaterfallSummaryData>> {
	// 	let response = await this.getSummaryWithDefaultValues();
	// 	if (!this.summaryData) {
	// 		response = await this.getSummaryWithDefaultValues();
	// 	}

	// 	return response;
	// }

	async getSummary(waterfallId: number = this.waterfallId) {
		const res = await this.waterfallService.getSummary(waterfallId);
		// const response = SummaryMockData;
		if (res.isSuccess) {
			this.setSummary(parseWaterfallSummaryData(res.data));
		}
		return res.data;
	}

	setSummary = (data: IWaterfallSummaryDataParsed | null) => {
		this.summaryData = data;
	};

	setIsShowError = (data: Error | null) => {
		this.setIsShowError(data);
	};

	// To comment out
	get simulationExitValues() {
		return this.summaryData?.shareClasses.shareClassValuation?.[0].shareClassesSummaryCalculations.map(
			(summary: IShareClassesSummaryCalculation) => summary.exitValue
		);
	}

	get destrcturedValuationData() {
		return (exitValue: number) => getDestrcturedValuationData(this.summaryData, exitValue);
	}

	getSummaryStatus(waterfallId: number = this.waterfallId) {
		return this.waterfallService.getSummaryStatus(waterfallId);
	}

	cancelSummary(waterfallId: number = this.waterfallId) {
		return this.waterfallService.cancelSummary(waterfallId);
	}

	// get valuationTableDataBySelectedExitValue() {

	// 	return (holderType: EquityHolderType, exitValue: number) => {
	// 		const key = holderType === EquityHolderType.ShareClasses ? "shareClassesTableData" : "shareHoldersTableData";
	// 		return this.destrcturedValuationData?.[key].find((data) => data.totalShareClassValuation
	// 	}
	// }
}
