

export interface PoolSummary {
    allocated: number;
    totalPool: number;
    unallocated: number;
    exercised: number;
}

export interface GrantsSummary {
    unvested: number;
    exercisable: number;
    saleable: number;
    exercised: number;
    sold: number;
    canceled: number;
}

export interface BeneficiariesSummary {
    active: number;
    inactive: number;
}

export interface GrantDocument {
    beneficiaryName: string,
    documentId: string,
    idSSN: string,
    grantDate: Date,
    grantNumber: string,
    grantType: string,
    exercisePrice: number,
    quantity: number,
    lastDateToSign: Date,
    allowToUpdate: number
}
export interface GrantDocuments {
    documents: GrantDocument[];
    numberOfTotalItems: number;
    documentsType: number;
}

// export interface UpcomingVestings {
//     totalVestingEvents: number;
//     upComingVestingList: VestRow[];
// }

export interface UpcomingVestings {
    grantType: string
    numberOfGrants: number
    vestingAmmount: number
    vestingDate: Date
}






export interface TrusteeGrant {
    beneficiaryName: string;
    grantId: string;
    beneficiaryId: string;
    planName: string;
    awardType: string;
    grantDate: Date;
    grantNumber: string;
    granted: number;
    expirationDate: Date;
    erercisePrice: number;
    exxercisePriceCurrency: string;
    taxTrack: string;
    department: string;
    site: string;
    vestingSchedule: string;
    vestingStartDate: Date;
    unVested: number;
    exercisable: number;
    outstanding: number;
    saleable: number;
    sold: number;
    canceled: number;
    exercised: number;
    vestings: TrusteeVest[];
}

export interface TrusteeGrantTable {
    grantNumber: string;
    beneficiaryName: string;
    awardType: string;
    grantDate: Date;
    granted: number;
    unVested: number;
    exercisable: number;
    saleable: number;
    exercised: number;
    sold: number;
    canceled: number;
    outstanding: number;
    // department: string;
    // site: string;
    // vestingSchedule: string;
    // vestingStartDate: Date;
    // vestings: TrusteeVest[];
}

export interface TrusteeVest {
    eventType: string;
    eventDate: Date;
    granted: number;
    vested: number;
    exercised: number;
    sold: number;
    canceled: number;
    exercisable: number;
    saleable: number;
    outstanding: number;
}

export interface TrusteeBeneficiaries {
    firstName: string;
    lastName: string;
    fullName: string;
    beneficiaryId: string;
    employeeNumber: string;
    canUpdate: string;
    requestNumber: number;
    requestStatus: string;
    site: string;
    shares: number;
    webAccess: true;
    businessEmail: string;
    outstanding: number;
    mobile: string;
    idSSN: string;
    employeeStatus: string;
    startDate: Date;
    endDate: Date;
    gender: number;
    openTerminationRequest: OpenTerminationRequest
}

export interface OpenTerminationRequest {
    remarks: string
    requestDate: Date
    requestId: string
}

export interface TrusteeOrder {
    orderNumber: string;
    grantNumber: string;
    orderType: string;
    amount: number;
    salePrice: number;
    exercisePrice: number;
    grantId: string;
    orderId: string;
    beneficiaryId: string;
    beneficiaryName: string;
    orderDate: Date;
    orderStatus: string;
}

export interface TrusteeCancelation {
    cancelationNumber: string;
    cancelType: string;
    cancelDate: Date;
    cancellReason: string;
    amount: number;
    grantId: string;
    beneficiaryId: string;
    beneficiaryName: string;
    grantNumber: string;
}

export interface TrusteeGrantDocument {
    beneficiaryName: string;
    documentId: string;
    idSSN: string;
    grantDate: Date;
    grantNumber: string;
    grantType: string;
    exercisePrice: number;
    quantity: number;
    lastDateToSign: Date;
    allowToUpdate: number;
}

export interface TrusteeManagerReport {
    reportName: string;
    reportNameHeb: string;
    reportNameEng: string;
    reportParams: ReportParamsType[]
}

export interface ReportParamsType {
    key: string;
    hedderEng: string;
    hedderHeb: string;
    isAsOfDate: true;
    type: PivotalParameterType;
    comboOptions: string[];
}

// enums

export enum PivotalParameterType {
    TextBox = 1,
    ComboBox,
    Date,
    DateRange,
    Bool
}


export enum Gender {
    Female = 0,
    Male = 1,
    Other = 2
}

export enum OrderByBeneficiaryTable {
    FirstName = 0,
    LastName = 1,
    FullName = 2,
    BeneficiaryId = 3,
    EmployeeNumber = 4,
    CanUpdate = 5,
    RequestNumber = 6,
    RequestStatus = 7,
    Site = 8,
    Shares = 9,
    WebAccess = 10,
    BusinessEmail = 11,
    Outstanding = 12,
    Mobile = 13,
    IdSSN = 14,
    EmployeeStatus = 15,
    StartDate = 16,
    EndDate = 17,
    Gender = 18
}

export enum OrderByGrantTable {
    BeneficiaryName = 0,
    GrantId = 1,
    BeneficiaryId = 2,
    PlanName = 3,
    AwardType = 4,
    GrantDate = 5,
    GrantNumber = 6,
    Granted = 7,
    ExpirationDate = 8,
    ErercisePrice = 9,
    ExxercisePriceCurrency = 10,
    TaxTrack = 11,
    Department = 12,
    Site = 13,
    VestingSchedule = 14,
    VestingStartDate = 15,
    UnVested = 16,
    Exercisable = 17,
    Outstanding = 18,
    Saleable = 19,
    Sold = 20,
    Canceled = 21,
    Exercised = 22
}

export enum OrderByCancelationTable {
    CancelationNumber = 0,
    CancelType = 1,
    CancelDate = 2,
    CancellReason = 3,
    Amount = 4,
    GrantId = 5,
    BeneficiaryId = 6,
    BeneficiaryName = 7,
    GrantNumber = 8,
}

export enum OrderByOrderTable {
    OrderNumber = 0,
    GrantNumber = 1,
    OrderType = 2,
    Amount = 3,
    SalePrice = 4,
    ExercisePrice = 5,
    GrantId = 6,
    OrderId = 7,
    BeneficiaryId = 8,
    BeneficiaryName = 9,
    OrderDate = 10,
    OrderStatus = 11,
}

export enum SqlOperator {
    Equal,
    NotEqual,
    GreaterThan,
    LessThan,
    GreaterThanOrEqual,
    LessThanOrEqual,
    Contains
}

export interface FilterLevel {
    entityEnum: number
    operator: SqlOperator
    serachValue: string
}

export enum AndOrConnector {
    And = 0,
    Or = 1
}

export enum SubmitFilesToTrusteeType {
    Other,
    Board,
    NewBeneficiary,
    NewGrant,
    NewExercise
}

export enum GrantDocumentStatus {
    Pending,
    Signed,
    PendingEmployee
}