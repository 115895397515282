import { css } from '@emotion/css';
import { ShareClassValuation } from '../../../../../../../Models/API/Valuation/summary';
import { ModalBodyProps } from '../../../../../../../Shared/Components/Modal/types';
import { formatDecimal } from '../../../../../../../Shared/Utilities';
import ValuationCardScatterChart from '../../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/components/ValuationCard/ValuationCardScatterChart';
import { parseNumericValue } from '../../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/helpers';
import { BreakPoint, ScatterChartPoint } from '../../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import CallsSetTable from './CallsSetTable';

type Props = {
	shareClass: ShareClassValuation;
} & ModalBodyProps;

const Style = css({
	label: 'ValuationCard',
	padding: '6.8rem 14rem 4.8rem 10rem',
	display: 'flex',
	flexDirection: 'column',
	'&__title': {
		fontWeight: 700,
		fontSize: '1.8rem',
		marginBottom: '2rem',
	},
	'&__component-text': {
		fontWeight: 400,
		'&:first-child': {
			marginBottom: 5,
		},
	},
	'&__total-share-class': {
		fontWeight: 700,
		margin: '2rem 0',
	},
	'&__chart-container': {
		marginTop: '3rem',
	},
});

const ValuationCard = ({ shareClass }: Props) => {
	const breakPointsData: ScatterChartPoint[] = shareClass.breakPoints
		.filter((breakpoint) => parseNumericValue(breakpoint.rangeTo))
		.map((breakpoint: BreakPoint) => ({
			x: breakpoint.rangeTo,
			y: breakpoint.currentPayoff,
			hide: !!breakpoint.isEndOfRange,
		}));

	return (
		<div className={Style}>
			<div className={`${Style}__title`}>Valuation of share class: {shareClass.name}</div>
			<span className={`${Style}__component-text`}>
				Liquidation preference component - {formatDecimal(shareClass.liquidationPreferenceComponent, { decimalLength: 3 })} $M
			</span>
			<span className={`${Style}__component-text`}>
				Common share component - {formatDecimal(shareClass.commonShareComponent, { decimalLength: 3 })} $M
			</span>
			<div className={`${Style}__total-share-class`}>
				Total share class value - {formatDecimal(shareClass.totalShareClassValuation, { decimalLength: 3 })} $M
			</div>
			<div>
				<span className="bold">Preference summary:</span>
				{` ${shareClass.preferenceSummary.slice(0, 1).toUpperCase() + shareClass.preferenceSummary.slice(1)}`}
			</div>

			<div className={`${Style}__chart-container`}>
				<ValuationCardScatterChart yAxisLabel="Payoff ($M)" xAxisLabel="Company Exit value ($M)" data={breakPointsData} />
			</div>
			<CallsSetTable breakpoints={shareClass.breakPoints} />
		</div>
	);
};

export default ValuationCard;
