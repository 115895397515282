export enum ImportExcelStep {
	'typeSelection',
	'fileImport',
	'headlineSelection',
	'rowSelection',
	'columnSelection',
	'tableEdit',
}

export interface BeneficiariesFields {
	firstName: string;
	lastName: string;
	email?: string;
	employeeNumber: string;
}

export interface GrantFields extends BeneficiariesFields {
	grantNumber: string;
	awardType: number;
	grantDate: Date;
	numberOfOptions: number;
	exercisePrice: number;
	vestingStartDate: Date;
	vestingSchedule: Date;
	expirationDate: Date;
	taxTrack: number;
}

export enum InfoTypeEnum {
	'Beneficiaries',
	'Grants',
}

export type InfoTypes = 'Beneficiaries' | 'Grants';
