import { useToggle } from 'react-use';
import Clickable from '../../../../../../../Shared/Components/Clickable/Clickable';
import { useEffect, useRef } from 'react';
import ExpandToggle from '../../../../../../../Shared/Components/ExpandToggle';
import Table, { TableColumn } from '../../../../../../../Shared/Components/Table/Table';
import { Breakpoint } from '../../../../../../../Models/API/Valuation/summary';
import { formatDecimal } from '../../../../../../../Shared/Utilities';

type Props = {
	breakpoints: Breakpoint[];
};

const CallsSetTable = ({ breakpoints }: Props) => {
	const [showTable, toggleShowTable] = useToggle(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		showTable && ref.current?.scrollIntoView({ behavior: 'smooth' });
	}, [showTable]);

	const tableColumns: TableColumn<Breakpoint>[] = [
		{
			name: 'rangeFrom',
			label: 'Range value ($M)',
			format: (val, obj) => {
				if (!obj) return '-';
				return obj.isEndOfRange ? `${formatDecimal(obj.rangeFrom)} - end of range` : `${formatDecimal(obj.rangeFrom)} - ${formatDecimal(obj.rangeTo)}`;
			},
		},
		{
			name: 'allShareClassesValue',
			label: 'All share classes value ($M)',
			format: (value) => formatDecimal(value),
		},
		{
			name: 'percent',
			label: 'Share class (%)',
		},
		{
			name: 'shareClassValue',
			label: 'Share class value ($M)',
			format: (value) => formatDecimal(value),
		},
	];

	return (
		<div>
			<Clickable
				justify="start"
				margin="2rem 0"
				width="fit-content"
				qaid="ValuationCard.Button.ToggleCallsSetTable"
				onClick={toggleShowTable}
				gap="0.8rem"
				ref={ref}
			>
				<span style={{ color: '#4d4d4d', marginRight: '1rem' }}>{showTable ? 'Hide calls set' : 'Show calls set'}</span>
				<ExpandToggle isOpen={showTable} />
			</Clickable>
			{showTable && <Table rows={breakpoints} columns={tableColumns} />}
		</div>
	);
};

export default CallsSetTable;
