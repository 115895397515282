import { useHistory } from 'react-router-dom';
import Button from '../../Shared/Components/Button/Button';
import { Routes } from '../../Routes';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const DataCollection = () => {
	const history = useHistory();
	const {
		dataCollectionStore,
		companyStore: { companyId },
	} = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		dataCollectionStore.getProjects();
	}, [companyId]);

	const onOrderHandler = async () => {
		setIsLoading(true);
		const res = await dataCollectionStore.createProject();
		if (!res.data?.data.valuationProjectId) return;

		const dcRes = await dataCollectionStore.createDataCollection({
			valuationProjectId: res.data.data.valuationProjectId,
		});
		setIsLoading(false);
		if (!dcRes.data?.data.dataCollectionId) return;

		dataCollectionStore.valuationProjectId = res.data.data.valuationProjectId;
		history.push(`${Routes.valuation.dataCollection}/${dcRes.data.data.dataCollectionId}/0`);
	};

	return (
		<div>
			<div>Data Collection</div>
			<Button qaid="DataCollection.Button.Order409A" label="Order new 409A" onClick={onOrderHandler} isLoading={isLoading} />
			{dataCollectionStore.projects?.map((project) => (
				<div
					key={project.valuationProjectId}
					onClick={() => {
						dataCollectionStore.valuationProjectId = project.valuationProjectId;
						history.push(`${Routes.valuation.dataCollection}/${project.valuationProjectId}/0`);
					}}
				>
					{project.projectName}, {project.valuationProjectId}
				</div>
			))}
		</div>
	);
};

export default observer(DataCollection);
