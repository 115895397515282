import { css } from "@emotion/css";

const NewEquityPlanStyle = css({
	label: "NewEquityPlan",
	display: "flex",
	flexDirection: "column",
	padding: "6rem 7.7rem 6.7rem 7.7rem",
	width: '100rem',

	".innerButtons": {
		position: "relative",
	},

	"&__loading-spinner": {
		minHeight: "40rem",
	},

	"&__settingSection": {
		width: '100%',
		maxWidth: '120rem',
		alignSelf: 'center',
		paddingTop: '4rem',
		marginTop: '2rem',

		'> h2': {
			fontSize: '2.2rem',
		}
	}
});

export default NewEquityPlanStyle;
