import { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { NumberOption } from "../../../../../../../../Models/API/All/NumberOption";
import { ProjectPersonInfo } from "../../../../../../../../Models/API/CapTable";
import { AvailableAmountsResultInfo } from "../../../../../../../../Models/API/CapTable/available-amounts-result-info";
import { IContact, IContactCreate } from "../../../../../../../../Models/API/Contact/contact";
import { EntityTypeEnum, PersonTypesEnum, WarrantsType } from "../../../../../../../../Models/API/enums";
import { SecondaryTransaction as SecondaryTransactionType } from "../../../../../../../../Models/App/CapTable/SecondaryTransaction";
import Button from "../../../../../../../../Shared/Components/Button/Button";
import CompanyUserAutoComplete from "../../../../../../../../Shared/Components/CompanyUserAutoComplete";
import NumberInput from "../../../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../../../Shared/Components/Input/TextInput";
import Select from "../../../../../../../../Shared/Components/Select/Select";
import { PersonTypes, optionSectionWarrantsTypes, shareholderRoleOptions } from "../../../../../../../../Shared/Config";
import { AppendStateInputHandlerType } from "../../../../../../../../Shared/Hooks/useAppendState";
import useContact from "../../../../../../../../Shared/Hooks/useContact";
import { InputValidationRef } from "../../../../../../../../Shared/Hooks/useFormValidation";
import { ForwardedRef } from "../../../../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../../../../Shared/Hooks/useRootStore";
import { formatNumber, isNullOrUndefined, zeroIfNegative } from "../../../../../../../../Shared/Utilities";
import useSecondaryTransactionValidation from "../../../../../../Hooks/Validations/useSecondaryTransactionValidation";
import { CapTablePermission } from "../../../../../../../../Models/API/UsersAndPermissions/permissions-enum";

interface Props {
    transaction: SecondaryTransactionType;
    onInputHandler: AppendStateInputHandlerType;
    setTransaction: Dispatch<SetStateAction<SecondaryTransactionType>>;
    onCancel?: () => void;
}

const SecondaryTransaction = forwardRef<ForwardedRef, Props>(({ transaction, onInputHandler, setTransaction, onCancel }, forwardedRef) => {
    const { capTableStore, currency, shareHolderStore, contactStore, appState } = useRootStore();
    const secondPerson = useRef<ProjectPersonInfo>();
    const [assetsSold, setAssetsSold] = useState<NumberOption[]>([]);
    const [assetsBought, setAssetsBought] = useState<NumberOption[]>([]);
    const [availableAmounts, setAvailableAmounts] = useState<AvailableAmountsResultInfo>({
        availableShares: 0,
        availableWarrants: 0,
        minAvailableRegisteredShares: 0,
        minAvailableRegisteredWarrants: 0,
        availableBsa: 0,
    });
    const { validateForm, formValidationState, inputRefs } = useSecondaryTransactionValidation(transaction, availableAmounts);
    const initialTransaction = useRef<SecondaryTransactionType>(transaction);
    const {
        contact,
        setContact,
        formValidationState: contactFormValidationState,
        validateForm: validateContactForm,
        onCreateContactHandler,
        contactInputRefs: contactDetailsInputRefs,
        inputRefs: contactInputRefs,
        onInputHandler: onContactInputHandler,
        setDummyData,
    } = useContact({
        isEmailRequired: true,
        async onSuccess(c) {
            onAddTransaction(c);
        },
        onMerge(c) {
            onAddTransaction(c);
        },
        onUnmount() {
            resolver.current?.(false);
        },
    });

    const resolver = useRef<Function>();

    useEffect(() => {
        (async () => {
            if (!transaction.transactionId) return;

            const shareholderData = await shareHolderStore.loadShareholderData(
                transaction.entityType === EntityTypeEnum.Seller ? transaction.buyerId : transaction.sellerId,
                false
            );
            if (isNullOrUndefined(shareholderData.data.person)) return;

            const loadedContact = await contactStore.getContact(shareholderData.data.person.sourceUserID);
            if (isNullOrUndefined(loadedContact)) return;

            // const addPersonRes = await shareHolderStore.addPerson(contact as IContact);
            // if (isNullOrUndefined(addPersonRes.data?.projectPersonID)) {
            // 	return console.log("Error retrieving shareholder");
            // }
            secondPerson.current = shareholderData.data.person;

            const updatedContact: IContactCreate = {
                ...loadedContact,
                contactDetails: {
                    ...contact.contactDetails,
                    // role: shareholderData.data?.person?.shareholder_type,
                },
            };
            setContact(updatedContact);
        })();
    }, []);

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            let isValid = validateForm();
            isValid = validateContactForm() && isValid;
            if (!isValid) return false;

            if (
                ((transaction.entityType === EntityTypeEnum.Buyer && !transaction.sellerId) ||
                    (transaction.entityType === EntityTypeEnum.Seller && !transaction.buyerId)) &&
                isNullOrUndefined(contact?.contactId)
            ) {
                onCreateContactHandler(contact, { isUpdate: false });
                return new Promise(function (resolve) {
                    resolver.current = resolve;
                });
            }

            await onAddTransaction(contact);
            return true;
        },
    }));

    const onAddTransaction = async (contact: IContactCreate) => {
        if (isNullOrUndefined(contact.contactId)) return resolver.current?.(true);

        // Daniel: to take the sellerId if selected from auto complete otherwise will enter the if statement and create
        let projectPersonId = transaction.entityType === EntityTypeEnum.Buyer ? transaction.sellerId : transaction.buyerId;

        if (isNullOrUndefined(secondPerson.current)) {
            const addPersonRes = await shareHolderStore.addPerson(
                contact as IContact,
                shareHolderStore.currentProjectId,
                CapTablePermission.no_access
            );
            if (isNullOrUndefined(addPersonRes.data?.projectPersonID)) {
                resolver.current?.(false);
                return console.log("Error retrieving shareholder");
            }
            projectPersonId = addPersonRes.data?.projectPersonID ?? 0;

            const shareholderData = await shareHolderStore.loadShareholderData(addPersonRes.data.projectPersonID, false);
            secondPerson.current = shareholderData.data.person;
        }

        const res = await contactStore.updateContact(contact);
        const newContact = res?.data;

        if (isNullOrUndefined(newContact) || !projectPersonId) return resolver.current?.(false);

        contact.contactDetails?.role && (await shareHolderStore.updatePerson(contact.contactDetails.role, secondPerson.current));
        await shareHolderStore.addUpdateSecondaryTransaction({
            ...transaction,
            [transaction.entityType === EntityTypeEnum.Buyer ? "sellerId" : "buyerId"]: projectPersonId,
        });

        resolver.current?.(true);
    };

    useEffect(() => {
        (async () => {
            if (!transaction.sellerId) return;
            const defaultAssets =
                capTableStore.project?.shareClassesList?.map((z) => ({
                    label: z.shareClass.shareClass ?? "",
                    value: z.shareClass.shareClassID ?? 0,
                })) ?? [];

            console.log(transaction.buyerId, transaction.sellerId);

            const shareClasses = await shareHolderStore.getAssetsSold(transaction.sellerId, transaction.date);
            if (transaction.entityType === EntityTypeEnum.Buyer) {
                if (transaction.buyerId) {
                    setAssetsSold(
                        shareClasses.map((sc) => ({
                            label: sc.shareClassName,
                            value: sc.shareClassID,
                        }))
                    );
                } else setAssetsSold(defaultAssets);
                setAssetsBought(defaultAssets);
            } else if (transaction.entityType === EntityTypeEnum.Seller) {
                setAssetsSold(shareClasses.map((sc) => ({ label: sc.shareClassName, value: sc.shareClassID })));
                setAssetsBought(defaultAssets);
            }
        })();
        // if (!transaction.transactionID) {
        // 	updateTransaction({ assetSoldId: undefined, assetBoughtId: undefined });
        // }
    }, [capTableStore.project?.shareClassesList, transaction.sellerId, transaction.date, transaction.entityType]);

    useEffect(() => {
        (async () => {
            if (isNullOrUndefined(transaction.assetSoldId)) return;
            const res = await capTableStore.getAvailableAmountForSecondaryTx(transaction.assetSoldId, getSellerId(), transaction.date);

            res.availableShares += initialTransaction.current.numberOfShares ?? 0;
            if (initialTransaction.current.warrantsType === WarrantsType.Warrants) {
                res.availableWarrants += initialTransaction.current.numberOfWarrants ?? 0;
            } else if (initialTransaction.current.warrantsType === WarrantsType.Bsa) {
                res.availableBsa += initialTransaction.current.numberOfWarrants ?? 0;
            }
            setAvailableAmounts(res);
        })();
    }, [transaction.assetSoldId, transaction.date]);

    const getSellerId = () => {
        if (transaction.entityType === EntityTypeEnum.Seller) {
            return shareHolderStore.projectPersonId ?? 0;
        }
        if (transaction.entityType === EntityTypeEnum.Buyer) {
            return transaction.sellerId ?? 0;
        }
        return 0;
    };

    const onSuggestionSelected = async (contact: IContact) => {
        const addPersonRes = await shareHolderStore.addPerson(contact);
        if (isNullOrUndefined(addPersonRes.data?.projectPersonID)) {
            return console.log("Error retrieving shareholder");
        }

        const shareholderData = await shareHolderStore.loadShareholderData(addPersonRes.data.projectPersonID, false);
        secondPerson.current = shareholderData.data.person;
        setTransaction((prevState) => ({
            ...prevState,
            // sellerFirstName: contact.isLegalEntity ? contact.companyName : contact.firstName,
            // sellerLastName: contact.isLegalEntity ? '' : contact.lastName,
            // sellerEmail: contact.email,
            [transaction.entityType === EntityTypeEnum.Buyer ? "sellerId" : "buyerId"]: addPersonRes.data.projectPersonID,
        }));

        const updatedContact: IContactCreate = {
            ...contact,
            contactDetails: {
                ...contact.contactDetails,
                // role: shareholderData.data?.person?.shareholder_type,
            },
        };
        setContact(updatedContact);
    };

    const getAvailableWarrants = (): string => {
        const amount =
            transaction.warrantsType === WarrantsType.Warrants
                ? formatNumber(zeroIfNegative(availableAmounts.availableWarrants))
                : transaction.warrantsType === WarrantsType.Bsa
                ? formatNumber(zeroIfNegative(availableAmounts.availableBsa))
                : 0;

        return `Available: ${amount}`;
    };

    const availableShares: string = `Available: ${formatNumber(zeroIfNegative(availableAmounts.availableShares))}`;

    if (!transaction.date) return <></>;

    const isLegalEntity = contact.isLegalEntity;

    return (
        <>
            <Select
                label="Other side type"
                qaid="AddEditTransaction.Select.PersonType"
                value={isLegalEntity ? PersonTypesEnum.LegalCompany : PersonTypesEnum.Person}
                options={PersonTypes}
                name="isLegalEntity"
                onChange={(value) =>
                    setContact((prevContact) => ({ ...prevContact, isLegalEntity: value === PersonTypesEnum.LegalCompany }))
                }
                // error={formValidationState?.warrantsType?.message}
                style={{ gridArea: "1 / 4 / 1 / 4" }}
                required
            />

            {isLegalEntity && (
                <CompanyUserAutoComplete
                    searchBy="legalEntityCompanyName"
                    label="Organization name"
                    onSuggestionSelected={onSuggestionSelected}
                    required
                    value={contact.companyName}
                    name="companyName"
                    onChange={onContactInputHandler}
                    error={contactFormValidationState?.companyName?.message}
                    ref={(el: InputValidationRef) => (contactInputRefs.companyName = el)}
                    containerStyle={{ gridArea: "2 / 1 / 2 / 1" }}
                />
            )}

            <CompanyUserAutoComplete
                searchBy="firstName"
                label={transaction.entityType === EntityTypeEnum.Buyer ? "Seller first name" : "Buyer first name"}
                onSuggestionSelected={onSuggestionSelected}
                required
                value={contact.firstName}
                name="firstName"
                onChange={onContactInputHandler}
                containerStyle={{ gridArea: isLegalEntity ? "2 / 2 / 2 / 2" : "2 / 1 / 2 / 1" }}
                error={contactFormValidationState?.firstName?.message}
                ref={(el: InputValidationRef) => (contactInputRefs.firstName = el)}
            />
            <CompanyUserAutoComplete
                searchBy="lastName"
                label={transaction.entityType === EntityTypeEnum.Buyer ? "Seller last name" : "Buyer last name"}
                onSuggestionSelected={onSuggestionSelected}
                required
                value={contact.lastName}
                name="lastName"
                onChange={onContactInputHandler}
                containerStyle={{ gridArea: isLegalEntity ? "2 / 3 / 2 / 3" : "2 / 2 / 2 / 2" }}
                error={contactFormValidationState?.lastName?.message}
                ref={(el: InputValidationRef) => (contactInputRefs.lastName = el)}
            />
            <CompanyUserAutoComplete
                searchBy="email"
                label={transaction.entityType === EntityTypeEnum.Buyer ? "Seller email" : "Buyer email"}
                onSuggestionSelected={onSuggestionSelected}
                required
                value={contact.email}
                name="email"
                onChange={onContactInputHandler}
                containerStyle={{ gridArea: isLegalEntity ? "2 / 4 / 2 / 4" : "2 / 3 / 2 / 3" }}
                error={contactFormValidationState?.email?.message}
                ref={(el: InputValidationRef) => (contactInputRefs.email = el)}
            />
            <Select
                qaid="AddEditTransaction.Select.Role"
                options={shareholderRoleOptions}
                label="Role type"
                onChange={onContactInputHandler}
                name="contactDetails.role"
                value={contact.contactDetails?.role}
                error={contactFormValidationState?.contactDetails?.role?.message}
                ref={(el: InputValidationRef) => {
                    if (contactDetailsInputRefs) contactDetailsInputRefs.role = el;
                }}
                style={{ gridArea: isLegalEntity ? "2 / 5 / 2 / 5" : "2 / 4 / 2 / 4" }}
                required
            />
            <Select
                label="Asset sold"
                qaid="AddEditTransaction.Select.AssetSold"
                value={transaction.assetSoldId}
                options={assetsSold}
                name="assetSoldId"
                onChange={onInputHandler}
                error={formValidationState?.assetSoldId?.message}
                style={{ gridArea: "3 / 1 / 3 / 1" }}
                required
                ref={(el: InputValidationRef) => (inputRefs.assetSoldId = el)}
            />
            <Select
                label="Asset bought"
                qaid="AddEditTransaction.Select.AssetBought"
                value={transaction.assetBoughtId}
                options={assetsBought}
                name="assetBoughtId"
                onChange={onInputHandler}
                error={formValidationState?.assetBoughtId?.message}
                style={{ gridArea: "3 / 2 / 3 / 2" }}
                required
                ref={(el: InputValidationRef) => (inputRefs.assetBoughtId = el)}
            />
            <NumberInput
                label="Number of shares"
                qaid="AddEditTransaction.Input.Shares"
                value={transaction.numberOfShares}
                name="numberOfShares"
                onChange={onInputHandler}
                required
                error={formValidationState?.numberOfShares?.message}
                containerStyle={{ gridArea: "3 / 3 / 3 / 3" }}
                comment={availableShares}
                ref={(el: InputValidationRef) => (inputRefs.numberOfShares = el)}
            />
            <NumberInput
                label={`Price per share (${currency?.symbol})`}
                qaid="AddEditTransaction.Input.PricePerShare"
                value={transaction.pricePerShare}
                name="pricePerShare"
                onChange={onInputHandler}
                number="float"
                error={formValidationState?.pricePerShare?.message}
                required
                containerStyle={{ gridArea: "3 / 4 / 3 / 4" }}
                ref={(el: InputValidationRef) => (inputRefs.pricePerShare = el)}
                prependText={currency?.symbol}
            />
            <TextInput
                label={`Total cost (${currency?.symbol})`}
                qaid="AddEditTransaction.Input.TotalCost"
                isTotal
                value={currency?.symbol + formatNumber((transaction.numberOfShares ?? 0) * (transaction.pricePerShare ?? 0), false)}
                containerStyle={{ gridArea: "3 / 5 / 3 / 5" }}
            />
            <Select
                label="Award type"
                qaid="AddEditTransaction.Select.AwardType"
                value={transaction.warrantsType}
                options={optionSectionWarrantsTypes}
                name="warrantsType"
                onChange={onInputHandler}
                style={{ gridArea: "4 / 1 / 4 / 1" }}
                // error={formValidationState?.warrantsType?.message}
            />
            {transaction.warrantsType !== WarrantsType.None && (
                <>
                    <NumberInput
                        label={transaction.warrantsType === WarrantsType.Bsa ? "Number of BSA" : "Number of warrants"}
                        qaid="AddEditTransaction.Input.Warrants"
                        value={transaction.numberOfWarrants}
                        name="numberOfWarrants"
                        onChange={onInputHandler}
                        comment={getAvailableWarrants()}
                        error={formValidationState?.numberOfWarrants?.message}
                        required={(transaction.warrantsType ?? 0) !== WarrantsType.None}
                        containerStyle={{ gridArea: "4 / 2 / 4 / 2" }}
                        ref={(el: InputValidationRef) => (inputRefs.numberOfWarrants = el)}
                    />
                    <NumberInput
                        label="Price"
                        qaid="AddEditTransaction.Input.ExPrice"
                        value={transaction.pricePerWarrant}
                        name="pricePerWarrant"
                        onChange={onInputHandler}
                        error={formValidationState?.pricePerWarrant?.message}
                        required={(transaction.warrantsType ?? 0) !== WarrantsType.None}
                        containerStyle={{ gridArea: "4 / 3 / 4 / 3" }}
                        ref={(el: InputValidationRef) => (inputRefs.pricePerWarrant = el)}
                    />
                </>
            )}

            {appState.isDev && (
                <Button
                    qaid=""
                    label="Fill Data (dev only)"
                    cancel
                    onClick={setDummyData}
                />
            )}
        </>
    );
});

export default SecondaryTransaction;
