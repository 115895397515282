import { observer } from 'mobx-react-lite';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import Button from '../../../../../Shared/Components/Button/Button';
import Select from '../../../../../Shared/Components/Select/Select';
import Table from '../../../../../Shared/Components/Table/Table';
import useModal from '../../../../../Shared/Hooks/useModal';
import { isNullOrUndefined } from '../../../../../Shared/Utilities';

import { WaterfallStep } from '../../../../../Screens/Waterfall/Components/AddEditWaterfall/index.style';
import WaterfallTableHeading from '../../../../../Screens/Waterfall/Components/WaterfallTableHeading/WaterfallTableHeading';
import Heading from '../../../../../Shared/Components/Heading/Heading';
import Hr from '../../../../../Shared/Components/HorizontalRule/Hr';
import Flex from '../../../../../Shared/Components/Layout/Flex';
import { COLOR_SANTAS_GRAY } from '../../../../../config/style/lib/colors';
import { theme } from '../../../../../config/style/theme';
import { ForwardedRef } from '../../../../Hooks/useMultiStepForm';
import LiquidationPreferencesStyle from '../LiquidationPreferences/index.style';
import Card from './Card/Card';
import PreferencesTermsStyle from './index.style';
import useShareClass, { PreferencesTermsBasicOption } from './useShareClass';
import { DefaultStepResponse, PreferenceShareClassRequest, PreferenceShareClassRight, WaterfallDefaultParams } from '../../../../../Models/API/Waterfall/IForm';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../Services/Axios';

export type PreferencesTermsProps = {
	preferenceShareClasses?: PreferenceShareClassRight[];
	getPreferenceClassRights: ({ waterfallId }: WaterfallDefaultParams) => Promise<void>;
	addPreferenceClassRight: (payload: PreferenceShareClassRequest) => Promise<void>;
	deletePreferenceClassRight: (payload: WaterfallDefaultParams) => Promise<void>;
	updatePreferenceClassRights: (payload: WaterfallDefaultParams) => Promise<HighLevelErrorResponse | HigherLevelResponse<DefaultStepResponse>>;
	waterfallId: number;
	isViewMode?: boolean;
};

const PreferencesTerms = React.forwardRef<ForwardedRef, PreferencesTermsProps>((props, forwardedRef) => {
	const { showModal } = useModal();
	const {
		// selectedOption,
		// setSelectedOption,
		selectedClass,
		classRights,
		isFormValid,
		payoffOptions,
		onSelectClassHandler,
		onAddShareClass,
		isLoading,
		onInputChange,
		tableColumns,
		classRightEditId,
		updateSelection,
		onEvaluate,
		preferenceShareClasses,
		isViewMode,
		onResetEditHandler,
	} = useShareClass(props);

	const pointARef = useRef<HTMLTableElement>(null);
	const pointBRef = useRef<HTMLTableElement>(null);

	const scrollToPointA = () => {
		pointARef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	};

	const scrollToPointB = () => {
		pointBRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			if ((preferenceShareClasses && !preferenceShareClasses.length) || (!classRights.selectable.length && classRights.added.length)) {
				const res = await onEvaluate();
				return res;
			}

			showModal({
				type: 'warning',
				title: 'Preference data notice',
				body: (
					<>
						<div>Not all preference data has been added yet.</div>
						<div>Please complete all share classes preference data.</div>
					</>
				),
			});

			return false;
		},
	}));

	useEffect(() => {
		if (isNullOrUndefined(classRightEditId)) return;
		setTimeout(scrollToPointA, 100);
	}, [classRightEditId]);

	return (
		<WaterfallStep className={PreferencesTermsStyle}>
			{!!(classRights.selectable.length || classRightEditId) && (
				<div className={`${LiquidationPreferencesStyle}__add-class`}>
					<div className="layout__extra" ref={pointARef}>
						<Heading
							ap={{
								tag: 'h2',
								size: 'md',
								isBold: true,
								spacingBottom: 'xs',
							}}
						>
							Share class name
						</Heading>
						<Select
							required
							qaid="PreferencesTerms.Select.ShareClass"
							options={classRights.selectable}
							value={selectedClass?.shareClassId}
							onChange={(shareClassId) => onSelectClassHandler(shareClassId as number)}
							className="input-size--main"
							isLoading={isNullOrUndefined(preferenceShareClasses)}
							disabled={isViewMode || !classRights.selectable.length}
							defaultValue={classRightEditId ? selectedClass?.shredClassName : undefined}
						/>
					</div>
					<div className="layout__main">
						<p style={{ marginBottom: 0, color: COLOR_SANTAS_GRAY }}>
							Please select the limitations in a liquidation event per each preferred share class:
						</p>
						<Hr
							ap={{
								spacing: 'sm',
							}}
						/>
						{!!selectedClass && (
							<>
								<div>
									{payoffOptions.map((option: PreferencesTermsBasicOption, idx) => (
										<div key={idx}>
											<Card
												isActive={selectedClass.shareClassPreferenceTermsTypeId === option.value}
												isSelected={selectedClass.shareClassPreferenceTermsTypeId === option.value}
												onChange={() => updateSelection(selectedClass, option.value)}
												{...option}
											/>

											<Hr ap={{ spacing: 'sm' }} />
										</div>
									))}
								</div>

								<Flex justify="end" style={{ marginBlock: theme.spacings.sm }} gap="1.2rem">
									{!!classRightEditId && <Button qaid="ShareClassRights.Button.Cancel" cancel onClick={onResetEditHandler} label="Cancel" />}
									<Button
										qaid="PreferencesTerms.Button.Add"
										label={classRightEditId ? 'Update' : 'Add'}
										disabled={!isFormValid}
										onClick={async () => {
											await onAddShareClass();
											scrollToPointB();
										}}
										isLoading={isLoading}
									/>
								</Flex>
							</>
						)}
					</div>
				</div>
			)}

			<div ref={pointBRef}>
				<Table
					fullscreen
					columns={tableColumns}
					rows={classRights.added}
					border={{ bottom: true }}
					headerSize={2}
					rowSize={1.25}
					isLoading={isLoading}
					fallbackText="No share class was added yet"
					customHeaderRender={<WaterfallTableHeading text="Payoff preferences terms summary" />}
					rowClassName={(shareClass) => (shareClass.shareClassId && shareClass.shareClassId === classRightEditId ? 'class-right-edit' : '')}
				/>
				{classRights.selectable.length > 0 && (
					<Button
						style={{ marginLeft: 'auto' }}
						qaid="PreferencesTerms.Button.AddAnotherClass"
						type="button"
						label="Add another class"
						onClick={() => {
							scrollToPointA();
						}}
					/>
				)}
			</div>
		</WaterfallStep>
	);
});

export default observer(PreferencesTerms);
