import { lazy } from "react";
import { SplitTableData } from "../../../Models/API/CapTable/split-table-data";
import useModal from "../../../Shared/Hooks/useModal";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { ShareClassInfo } from "../../../Models/API/CapTable";
import { AddShareholderProps } from "../types";
import EditShareholder from "../Components/General/AddEditShareholder/EditShareholder/EditShareholder";
import { IPivotalCompany } from "../../../Models/App";
import CreateCapTable from "../Components/General/CreateCapTable";
import ShareCapTable from "../Components/MainMenu/Modals/ShareCapTable";
import { isNullOrUndefined } from "../../../Shared/Utilities";

const AddEditShareholder = lazy(() => import("../Components/General/AddEditShareholder/AddEditShareholder"));
const AddEditShareClass = lazy(() => import("../Components/General/AddEditShareClass/AddEditShareClass"));
const PivotalLogin = lazy(() => import("../Components/General/OptionsSync/Pivotal/Login"));
const SyncOptions = lazy(() => import("../Components/General/OptionsSync/SyncOptions"));
const NewDraft = lazy(() => import("../Components/MainMenu/Modals/NewDraft"));
const PublishCapTable = lazy(() => import("../Components/MainMenu/Modals/PublishCapTable"));
const Split = lazy(() => import("../Components/MainMenu/Modals/Split"));

const useCapTableModal = () => {
    const {
        capTableStore: {
            deleteProject,
            publishProject,
            saveAsDraftProject,
            createNewProject,
            project,
            addUpdateSplit,
            removeSplit,
            deleteShareClass,
            addUpdateShareClass,
            getAvailableAmountByClass,
        },
        contactStore,
        shareHolderStore,
    } = useRootStore();
    const { showModal } = useModal();

    // Main actions

    const onPublishHandler = (projectId: number) => {
        const onPublish = (name: string, date: Date, description?: string | undefined) =>
            publishProject(projectId, name, date, description);
        showModal({
            body: <PublishCapTable onPublish={onPublish} />,
            isFrameless: true,
            width: "44.2rem",
            maxWidth: "44.2rem",

            allowScrolling: false,
        });
    };

    const onNewDraftHandler = () => {
        showModal({
            body: <CreateCapTable isFirstTime={false} />,
            isFrameless: true,
            height: "48.7rem",
            width: "76rem",
        });
    };

    const onDuplicateDraftHandler = () => {
        showModal({
            body: (
                <NewDraft
                    isDuplicate
                    onSave={(name) => saveAsDraftProject(name)}
                />
            ),
            isFrameless: true,
            width: "44.2rem",
            maxWidth: "44.2rem",
            height: "42.8rem",
        });
    };

    const onDeleteDraftHandler = (projectId: number, draftName: string) => {
        showModal({
            type: "delete",
            title: "Delete this draft",
            body: (
                <>
                    <div style={{ marginBottom: "0.4rem" }}>Are you sure you want to delete this draft?</div>
                    <div className="bold">{draftName}</div>
                </>
            ),
            width: "44.2rem",
            maxWidth: "44.2rem",
            height: "41rem",
            async onConfirm() {
                await deleteProject(projectId);
            },
        });
    };

    const onSplitHandler = () => {
        showModal({
            body: <Split />,
            isFrameless: true,
            maxWidth: "98rem",
            width: "100%",
        });
    };

    const onSyncOptionsHandler = () => {
        showModal({
            body: <SyncOptions />,
            isFrameless: true,
            maxWidth: "98rem",
            width: "100%",
        });
    };

    const onPivotalLoginHandler = (onCompanySelect: (reCaptchaToken: string, company: IPivotalCompany) => Promise<boolean>) => {
        showModal({
            body: <PivotalLogin onCompanySelect={onCompanySelect} />,
            width: "45rem",
            isMust: true,
        });
    };

    const onAddEditShareClassHandler = (shareClassInfo?: ShareClassInfo, isPublishMode?: boolean) => {
        showModal({
            body: (
                <AddEditShareClass
                    shareClassInfo={shareClassInfo}
                    onAdd={(shareClass) => addUpdateShareClass(shareClass)}
                    onDelete={(id) => deleteShareClass(id)}
                    getAvailableAmounts={(shareClassId, date) => getAvailableAmountByClass(shareClassId, date)}
                    isViewMode={isPublishMode}
                />
            ),
            allowScrolling: false,
            width: "90rem",
            isFrameless: true,
            isMust: true,
        });
    };

    const onAddShareholderHandler = (props: AddShareholderProps) => {
        contactStore.setEditContactId(undefined);
        shareHolderStore.isShareholderCreated = false;
        showModal({
            body: <AddEditShareholder {...props} />,
            width: "90%",
            height: "90%",
            maxWidth: "140rem",
            maxHeight: "95rem",
            isFrameless: true,
            isMust: true,
            onModalClose() {
                if (shareHolderStore.isShareholderCreated) {
                    const hasTransaction = Boolean(shareHolderStore.shareholderData?.transactions?.length);

                    showModal({
                        type: "success",
                        body: hasTransaction
                            ? "The shareholder data was added"
                            : "The shareholder was added successfully without any transactions",
                        qaid: "Shareholder.Modal.Add",
                        width: "44.5rem",
                        showProgressBar: false,
                        timeout: 1500,
                    });
                }
            },
        });
    };

    const onEditShareholderHandler = (contactId: number, projectPersonId: number, isView: boolean = project?.isPublished ?? false) => {
        contactStore.setEditContactId(contactId);
        shareHolderStore.setProjectPersonId(projectPersonId);
        shareHolderStore.isShareholderCreated = false;
        showModal({
			body: <EditShareholder isView={isView} />,
			width: '90%',
			height: '90%',
			maxHeight: '95rem',
			isFrameless: true,
			isMust: true,
		});
    };

    const onShareCapTable = () => {
        if (isNullOrUndefined(project) || isNullOrUndefined(project.personsList)) return;
        showModal({
            body: <ShareCapTable availableContacts={project.personsList.map((p) => p.sourceUserID)} />,
            width: "90%",
            maxWidth: "140rem",
            maxHeight: "95rem",
            isFrameless: true,
            isMust: true,
        });
    };

    return {
        onPublishHandler,
        onNewDraftHandler,
        onDeleteDraftHandler,
        onSplitHandler,
        onSyncOptionsHandler,
        onPivotalLoginHandler,
        onAddEditShareClassHandler,
        onDuplicateDraftHandler,
        onAddShareholderHandler,
        onEditShareholderHandler,
        onShareCapTable,
    };
};

export default useCapTableModal;
