import { css } from '@emotion/css';
import { Collapse } from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { IC_MESSAGE } from '../../../../../Assets';
import appConfig from '../../../../../config/config';
import { DataCollectionComment, DataCollectionFieldTypeEnum } from '../../../../../Models/API/DataCollection/comment-response';
import Button from '../../../../../Shared/Components/Button/Button';
import Image from '../../../../../Shared/Components/Image';
import { TextAreaInput } from '../../../../../Shared/Components/Input/Input.Style';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import useModal from '../../../../../Shared/Hooks/useModal';
import { ForwardedRefType } from '../../../../../Shared/Hooks/useMultiStepForm';
import QuestionsHistory from '../QuestionHistory';
import { observer } from 'mobx-react-lite';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import classNames from 'classnames';

type Props = {
	title: string;
	fieldType: DataCollectionFieldTypeEnum;
	info?: string;
	renderActions: JSX.Element;
	headeredComment?: boolean;
};

const Style = css({
	label: 'DataCollectionQuestion',
	display: 'flex',
	flexDirection: 'column',
	padding: '2rem 0',
	'&:not(:last-child)': {
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
	},
	'&__question-lbl': {
		display: 'flex',
		alignItems: 'center',
		gap: '1.2rem',
		marginBottom: '2rem',
		justifyContent: 'space-between',
		'> span': {
			display: 'flex',
			alignItems: 'center',
			gap: '1.2rem',
		},
	},
	'.actions': {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	'&__comments': {
		marginRight: '2rem',
	},
});

const Question = forwardRef<ForwardedRefType<string | undefined>, Props>(
	({ title, info, fieldType, renderActions, headeredComment: headeredActions }, forwardedRef) => {
		const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false);
		const [comment, setComment] = useState<string>();
		const { showModal } = useModal();
		const { dataCollectionStore } = useRootStore();

		const comments = fieldType ? dataCollectionStore.commentsByQuestion[fieldType] : undefined;
		const unreadComments = (dataCollectionStore.comments || []).reduce((acc, comment) => {
			if (comment.isValuationTeam && dataCollectionStore.currentRole === 'user' && !comment.isRead) {
				return acc + 1;
			}
			if (!comment.isValuationTeam && dataCollectionStore.currentRole === 'valuationTeam' && !comment.isRead) {
				return acc + 1;
			}
			return acc;
		}, 0);

		const onToggleShow = () => {
			setIsCommentOpen((prev) => !prev);
			// setComment(undefined);
		};

		useImperativeHandle(forwardedRef, () => ({
			onValidate: () => true,
			getData: () => comment,
		}));

		const AddCommentElement =
			comments !== undefined &&
			(comments.length ? (
				<div
					className={`${Style}__comments`}
					onClick={() =>
						showModal({
							body: <QuestionsHistory fieldType={fieldType} />,
							isFrameless: true,
							width: '70rem',
							height: '70dvh',
							allowScrolling: false,
						})
					}
				>
					<UnreadMessages count={unreadComments} />
				</div>
			) : (
				<Button qaid="" label="Add comment" inverse cancel onClick={onToggleShow} />
			));

		const TextAreaInputElement = (
			<Collapse in={isCommentOpen} unmountOnExit className={headeredActions ? 'mb-2r' : 'mt-2r'}>
				<TextAreaInput
					data-qaid=""
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					style={{ resize: 'none', height: '100%', width: '100%' }}
					rows={4}
					maxLength={500}
					placeholder="Type text here..."
					name=""
				/>
			</Collapse>
		);

		return (
			<div className={Style}>
				<div className={`${Style}__question-lbl`}>
					<span>
						{title}
						{!!info && <Tooltip title={info} />}
					</span>
					{!!headeredActions && <>{AddCommentElement}</>}
				</div>
				{!!headeredActions && TextAreaInputElement}

				<div className="actions">
					{renderActions}
					{!headeredActions && AddCommentElement}
				</div>
				{!headeredActions && TextAreaInputElement}
			</div>
		);
	}
);

const UnreadMessages = ({ count }: { count: number }) => {
	return (
		<div style={{ position: 'relative' }} className="pointer">
			<Image src={IC_MESSAGE} width="4rem" />
			{!!count && (
				<div
					style={{
						background: '#F6818F',
						borderRadius: '50%',
						color: '#ffffff',
						fontSize: '1.1rem',
						fontWeight: 700,
						width: 17,
						height: 17,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						bottom: -6,
						right: -8,
					}}
				>
					{count}
				</div>
			)}
		</div>
	);
};

export default observer(Question);
