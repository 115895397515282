import { css } from '@emotion/css';
import { ClickAwayListener, Fade } from '@mui/material';
import { IC_EXPORT_TO_EXCEL_BUTTON, IC_FILTER_BUTTON, IC_FILTER_BUTTON_CLICKED, IC_TRASH2 } from '../../../../../../../Assets';
import { MultipleData, VolatilityData, VolatilityTableData, WaterfallPeer } from '../../../../../../../Models/API/Waterfall/IForm';
import Image from '../../../../../../../Shared/Components/Image';
import Spinner from '../../../../../../../Shared/Components/Spinner/Spinner';
import { Cell, Row, Table } from '../../../../../../../Shared/Components/Table/Table.Style';
import { formatDecimal, getPercentrage, isNumber } from '../../../../../../../Shared/Utilities';
import appConfig from '../../../../../../../config/config';
import { useEffect, useState } from 'react';
import Select from '../../../../../../../Shared/Components/Select/Select';
import { NumberOption } from '../../../../../../../Models/API/All/NumberOption';
import OverflowText from '../../../../../../../Shared/Components/OverflowText';
import useVolatilityTooltip from '../../../../../utils/useVolatilityTooltip';
import Tooltip from '../../../../../../../Shared/Components/Tooltip';

type Props = {
	multiples: MultipleData[];
	timeToLiquiity?: number;
	peers?: WaterfallPeer[];
};

const Style = css({
	label: 'MultipleTable',
	marginTop: '4rem',
	'&__table': {
		border: `1px solid ${appConfig.style.colors.table}`,
		overflow: 'unset !important',
		maxHeight: 'unset !important',
		borderRadius: 16,
	},
	'&__header': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '0.4rem',
		'.title': {
			fontWeight: 500,
		},
		'.actions': {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			'.import-img': {
				marginTop: 3,
			},
		},
	},
	'.arrow-down': {
		width: 0,
		height: 0,
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent',
		borderTop: '8px solid #E98B8B',
	},
	'.arrow-up': {
		width: 0,
		height: 0,
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent',
		borderBottom: `8px solid ${appConfig.style.colors.success}`,
	},
});

type AdjustKeys = keyof Pick<VolatilityTableData, 'adjustedTableChosenTo' | 'adjustedTableFrom' | 'adjustedTableInterspace'>;

const MultiplesTable = ({ multiples, timeToLiquiity = 0, peers }: Props) => {
	const [filter, setFilter] = useState<{ from: number; to: number }>();
	const { getTooltip, onTooltipEnter, onTooltipLeave, tooltipData } = useVolatilityTooltip();
	const [isLoading, setIsLoading] = useState<Record<AdjustKeys, boolean>>({
		adjustedTableChosenTo: false,
		adjustedTableFrom: false,
		adjustedTableInterspace: false,
	});

	const onExportHandler = () => {};

	const average = multiples.find((m) => m.companyName === 'average');

	return (
		<Fade in={multiples.length > 2} unmountOnExit>
			<div className={Style}>
				<div className={`${Style}__header`}>
					<span className="title">Time to liquidity event: {timeToLiquiity}</span>
					<div className="actions">
						<Image
							data-qaid="VolatilityTable.Button.Export"
							className="import-img pointer"
							src={IC_EXPORT_TO_EXCEL_BUTTON}
							height="4.6rem"
							onClick={onExportHandler}
						/>
					</div>
				</div>
				<Table className={`${Style}__table`}>
					<Row header headerSize={1.25}>
						<Cell border={{ bottom: true, right: true }} style={{ borderTopLeftRadius: 16 }}>
							Peer Company
						</Cell>

						<Cell border={{ bottom: true, right: true }}>EV / Sales</Cell>
						<Cell border={{ bottom: true, right: true }}>EV / EDITDA</Cell>
						<Cell border={{ bottom: true, right: true }}>Market Cap / Net income</Cell>
						<Cell border={{ bottom: true, right: true }}>EDITDA margin</Cell>
						<Cell border={{ bottom: true }} style={{ borderTopRightRadius: 16 }}>
							Market Cap ($M)
						</Cell>
					</Row>
					{multiples.map((multiple, idx) => {
						const isLast = idx === multiples.length - 1;
						const isTotal = multiple.companyName === 'median' || multiple.companyName === 'average';
						const waterfallPeer = peers?.find((p) => p.companyName === multiple.companyName);
						const isAverageMarketCap = isNumber(average?.marketCaptToNetIncome);

						return (
							<Row key={multiple.identifier} rowSize={1.25} isTotal={isTotal}>
								<Cell
									border={{ bottom: !isLast, right: true }}
									style={{ borderBottomLeftRadius: isLast ? 16 : 0, fontWeight: isTotal ? 500 : 400 }}
								>
									{isTotal ? (
										<OverflowText>{`${multiple.companyName[0].toUpperCase()}${multiple.companyName.slice(1)}`}</OverflowText>
									) : waterfallPeer ? (
										<Tooltip title={getTooltip(waterfallPeer, tooltipData)} placement="right">
											<span
												onMouseEnter={() => {
													onTooltipLeave(waterfallPeer.stockId);
													onTooltipEnter(waterfallPeer.stockId);
												}}
											>
												{multiple.companyName}
											</span>
										</Tooltip>
									) : (
										<OverflowText>{multiple.companyName}</OverflowText>
									)}
								</Cell>
								<Cell border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
									{multiple.enterpriseValueToSales === null ? (
										'N/A'
									) : !isTotal ? (
										<>
											{multiple.enterpriseValueToSales !== average?.enterpriseValueToSales && (
												<span
													className={
														multiple.enterpriseValueToSales < (average?.enterpriseValueToSales ?? 0) ? 'arrow-down' : 'arrow-up'
													}
												></span>
											)}
											{formatDecimal(multiple.enterpriseValueToSales, { decimalLength: 3 })}
										</>
									) : (
										formatDecimal(multiple.enterpriseValueToSales, { decimalLength: 3 })
									)}
								</Cell>
								<Cell border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
									{multiple.enterpriseValueToEbitda === null ? (
										'N/A'
									) : !isTotal ? (
										<>
											{multiple.enterpriseValueToEbitda !== average?.enterpriseValueToEbitda && (
												<span
													className={
														multiple.enterpriseValueToEbitda < (average?.enterpriseValueToEbitda ?? 0) ? 'arrow-down' : 'arrow-up'
													}
												></span>
											)}
											{multiple.enterpriseValueToEbitda < 0
												? '(NEG)'
												: formatDecimal(multiple.enterpriseValueToEbitda, { decimalLength: 3 })}
										</>
									) : (
										formatDecimal(multiple.enterpriseValueToEbitda, { decimalLength: 3 })
									)}
								</Cell>
								<Cell border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
									{multiple.marketCaptToNetIncome === null ? (
										'N/A'
									) : !isTotal ? (
										<>
											{isAverageMarketCap && multiple.marketCaptToNetIncome !== average?.marketCaptToNetIncome && (
												<span
													className={
														multiple.marketCaptToNetIncome < (average?.marketCaptToNetIncome ?? 0) ? 'arrow-down' : 'arrow-up'
													}
												></span>
											)}
											{isAverageMarketCap && multiple.marketCaptToNetIncome < 0 ? '(NEG)' : multiple.marketCaptToNetIncome}
										</>
									) : (
										getPercentrage(multiple.marketCaptToNetIncome)
									)}
								</Cell>
								<Cell border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
									{multiple.ebitdaMargin ? getPercentrage(multiple.ebitdaMargin) : undefined}
								</Cell>
								<Cell border={{ bottom: !isLast, right: true }} style={isTotal ? { fontWeight: 500 } : {}}>
									{formatDecimal(multiple.marketCap)}
								</Cell>
							</Row>
						);
					})}
				</Table>
			</div>
		</Fade>
	);
};

export default MultiplesTable;
