import { css } from '@emotion/css';
import { ShareClassValuationTable, SummaryData } from '../../../../../../../Models/API/Valuation/summary';
import Table, { TableColumn } from '../../../../../../../Shared/Components/Table/Table';
import appConfig from '../../../../../../../config/config';
import { formatDecimal } from '../../../../../../../Shared/Utilities';
import useRootStore from '../../../../../../../Shared/Hooks/useRootStore';
import OverflowText from '../../../../../../../Shared/Components/OverflowText';
import useModal from '../../../../../../../Shared/Hooks/useModal';
import ValuationCard from './ValuationCard';

type Props = {} & Pick<SummaryData, 'shareClassValuationTable'>;

const Style = css({
	marginTop: '2.4rem',
	"div[data-row-type='row']": {
		svg: {
			height: '3.3rem',
		},
		'&:hover': {
			'.valuation-actions': {
				transition: 'all .3s',
				path: {
					stroke: appConfig.style.colors.color1,
				},
				rect: {
					fill: appConfig.style.colors.purple,
				},
			},
		},
	},
});

const ValuationTable = () => {
	const {
		valuationStore: { summaryData },
	} = useRootStore();
	const { showModal } = useModal();

	const tableColumns: TableColumn<ShareClassValuationTable>[] = [
		{
			label: 'Share class',
			name: 'shareClass',
			render: (obj, value) => <OverflowText>{value === 'Common' ? <span className="semi-bold">{value}</span> : value}</OverflowText>,
		},
		{
			label: 'Number of shares outstanding',
			name: 'numberOfSharesOutstanding',
			format: (value) => (value ? formatDecimal(value, { decimalLength: 3 }) : undefined),
		},
		{
			label: 'Amount of shares as converted ratio',
			name: 'AmountOfSharesAsConvertedRatio',
			format: (value) => (value ? formatDecimal(value, { decimalLength: 3 }) : undefined),
		},
		{
			label: 'Total share class value ($M)',
			name: 'totalShareClassValue',
			format: (value) => (value ? formatDecimal(value, { decimalLength: 3 }) : undefined),
		},
		{
			label: 'Value per one share class ($)',
			name: 'valuePerOneShareClass',
			format: (value) => (value ? formatDecimal(value, { decimalLength: 3 }) : undefined),
		},
		{
			name: 'actions',
			label: 'Summary',
			className: 'valuation-actions',
			render(obj, value) {
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="38" height="35" viewBox="0 0 38 35" fill="none">
						<g filter="url(#filter0_d_26_55)">
							<rect x="1.22021" y="1" width="33" height="31" rx="4" fill="#F9F9FF" shapeRendering="crispEdges" />
							<path
								d="M25.5176 24.442C21.403 24.4419 12.1983 24.4598 8.47266 24.442V10.0029"
								stroke="#979DB5"
								strokeWidth="1.4"
								strokeMiterlimit="10"
								strokeLinecap="round"
							/>
							<path
								d="M11.1525 21.4436L17.6817 14.6745C17.8702 14.4791 18.1802 14.4698 18.3801 14.6537L19.8444 16.0009C20.0579 16.1973 20.3931 16.1715 20.5739 15.9447L24.7826 10.6664"
								stroke="#979DB5"
								strokeWidth="1.4"
								strokeMiterlimit="10"
								strokeLinecap="round"
							/>
							<path
								d="M21.015 10.0855L24.9793 10.0692C25.2543 10.0681 25.4783 10.2888 25.481 10.5634L25.5176 14.3167"
								stroke="#979DB5"
								strokeWidth="1.4"
								strokeLinecap="round"
							/>
						</g>
						<defs>
							<filter
								id="filter0_d_26_55"
								x="0.220215"
								y="0"
								width="37"
								height="35"
								filterUnits="userSpaceOnUse"
								colorInterpolationFilters="sRGB"
							>
								<feFlood floodOpacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="1" dy="1" />
								<feGaussianBlur stdDeviation="1" />
								<feComposite in2="hardAlpha" operator="out" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.792157 0 0 0 0 0.815686 0 0 0 0.4 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_55" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_55" result="shape" />
							</filter>
						</defs>
					</svg>
				);
			},
			style: { flex: 0.5 },
		},
	];

	const totalRow: ShareClassValuationTable = {
		totalShareClassValue: summaryData?.shareClassValuationTable.reduce((acc, shareClass) => acc + shareClass.totalShareClassValue, 0) ?? 0,
		AmountOfSharesAsConvertedRatio: 0,
		numberOfSharesOutstanding: 0,
		shareClass: '',
		valuePerOneShareClass: 0,
	};

	if (!summaryData) return <></>;

	return (
		<Table
			className={Style}
			rows={[...summaryData.shareClassValuationTable, totalRow]}
			columns={tableColumns}
			headerSize={1.5}
			rowSize={1.1}
			onRowClick={(row) => {
				const shareClass = summaryData.shareClassValuation.find((sc) => sc.name === row.shareClass);
				if (!shareClass) return;

				showModal({
					body: <ValuationCard shareClass={shareClass} />,
					maxWidth: '140rem',
					maxHeight: '90rem',
					width: '90%',
					height: '90%',
					isFrameless: true,
					isMust: true,
				});
			}}
			customHeaderRender={<span className="bold">Share Class Valuation Table</span>}
			totalLabel="Company Equity Value"
			showTotal
		/>
	);
};

export default ValuationTable;
