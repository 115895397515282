import { useTranslation } from "react-i18next";
import { WarrantsTransaction } from "../../../../Models/App/CapTable/WarrantsTransaction";
import { useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../Shared/ObjectValidator";

const useExerciseWarrantsValidation = (transaction: WarrantsTransaction, availableAmount: number = 0) => {
	const { t } = useTranslation();

	const { isFormValid, formErrors, formValidationState, setFormValidationState, validateForm } = useFormValidation({
		form: transaction,
		schema: {
			warrantsType: [commonValidators.required()],
			numberOfWarrants: [commonValidators.required(), commonValidators.range2(0, availableAmount, { includeMax: true, includeMin: false })],
			exPrice: [commonValidators.required()],
		},
	});

	return {
		isFormValid,
		formErrors,
		formValidationState,
		setFormValidationState,
		validateForm,
	};
};

export default useExerciseWarrantsValidation;
