import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";
import appConfig from "../../../../config/config";

export const EquityStyle = css({
	label: "Equity",

	".noData": {
		marginTop: "1.3rem",
		"&__title": {
			fontSize: "1.8rem",
		},
		"&__background": {
			height: "100%",
			width: "100%",
			borderRadius: "8px",
			opacity: 0.8,
			background: "linear-gradient(0deg, #BDC3F1 0%, #F0F1FF 45.83%, rgba(255, 255, 255, 0.00) 100%)",
		},
	},

	".navArrow": {
		position: "absolute",
		right: "2rem",
		top: "2rem",
		width: "fit-content",
		height: "fit-content",

		"&__icon": {
			width: "1.9rem",
			height: "auto",
		},
	},

	".innerContainer": {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		justifyContent: 'center',

		".activeBeneficiaries": {
			marginBottom: "2rem",
			width: "fit-content",

			"&__img": {
				width: "4rem",
				height: "4rem",
				marginBottom: "0.5rem",
			},

			"&__title": {
				color: "#4D4D4D",
				fontSize: "1.4rem",
				whiteSpace: "nowrap",
			},

			"&__info": {
				color: "#4D4D4D",
				fontWeight: "700",
				fontSize: "1.4rem",
			},
		},
	},

	[`@media screen and (max-width: ${breakpoints.lg})`]: {
		".innerContainer": {
			".activeBeneficiaries": {
				"&__title": {
					fontSize: "1.7rem !important",
				},

				"&__info": {
					fontSize: "1.6rem !important",
				},
			},
		},
	},

	".chartsContainer": {
		paddingLeft: "1.2rem",

		"&__amount": {
			height: "3rem",
			borderRadius: "5px",
			boxShadow: "1px 1px 1px 0px rgba(217, 217, 217, 0.50)",
			maxWidth: "73%",
			zIndex: 1,
		},
	},

	".totalPool": {
		borderRight: `2px solid ${appConfig.style.colors.table}`,
		width: '20%',
		height: '26.8rem',

		"> span": {
			fontSize: '1.8rem',
			fontWeight: '700'
		}
	},

	".totalPool_inline": {
		display: 'flex',
		gap: '1rem',
		marginBottom: '1rem',

		"> span": {
			fontSize: '1.6rem'
		}
	},


	".rowsContainer": {
		width: '100%',
		height: '26.8rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},

	".rowContainer": {

		'> span': {
			fontSize: '1.6rem',
			marginBottom: "1rem",
		}

	},


});