export type DataCollectionComment = {
	dataCollectionId: string;
	fieldType: DataCollectionFieldTypeEnum;
	commentDescription: string;
	isValuationTeam: boolean;
	isRead: boolean;
	createDate: number;
};

export enum DataCollectionFieldTypeEnum {
	None,
	ValuationDate,
	InvestmentExist,
	InvestmentType,
	CompanyProfitable,
	AboutUs,
	NextRoundValuation,
	CapTable,
	IssuanceDocument,
	ArticleOfAssociation,
	FinancialStatement,
	DiscountCashFlow,
	Dcf,
	SafeOrConvertible,
	TimeToLiquidity,
	CompanyProfitableInFuture,
}
