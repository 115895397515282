import { css } from "@emotion/css";
import Image from "../../../../Shared/Components/Image";
import { IC_CAPTABLE_LOGO_INSTANT, IC_PLAY_TUTORIAL_LOGO, IC_PLAY_WHITE } from "../../../../Assets";
import Title from "../../../../Shared/Components/Layout/Title";
import Button from "../../../../Shared/Components/Button/Button";
import { formatDate } from "../../../../Shared/Utilities";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { ModalBodyProps } from "../../../../Shared/Components/Modal/types";
import { useEffect, useState } from "react";
import classNames from "classnames";
import useGeneralModal from "../../../../Shared/Hooks/useGeneralModal";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import useModal from "../../../../Shared/Hooks/useModal";
import NewDraft from "../MainMenu/Modals/NewDraft";

const Style = css({
    label: "CreateCapTable",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    maxWidth: "40rem",
    alignSelf: "center",
    textAlign: "center",
    "&.tutorial": {
        maxWidth: "44rem",
    },
    "&__img": {
        marginBottom: "4rem",
        height: "15rem",
    },
    "&__text": {
        marginTop: "1rem",
        marginBottom: "3rem",
    },
    "&__actions": {
        display: "flex",
        gap: "3.2rem",
        "> button": {
            width: "14rem",
        },
    },
});

type Props = {
    isFirstTime?: boolean;
} & ModalBodyProps;

const CreateCapTable = ({ removeModal, hideModal, isFirstTime = true }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isManually, setIsManually] = useState<boolean>();
    const {
        capTableStore,
        appState: { tutorial, isProd },
    } = useRootStore();
    const { showModal } = useModal();
    const { showYouTubeModal } = useGeneralModal();
    const history = useHistory();

    const onCreateProject = async (name?: string) => {
        setIsLoading(true);
        await capTableStore.createNewProject(name || `Autosave ${formatDate(new Date())}`, new Date());
        setIsLoading(false);
    };

    const showToturial = isManually && isFirstTime;

    return (
        <div className={classNames(Style, { tutorial: showToturial })}>
            {showToturial ? (
                <>
                    <Image
                        src={IC_PLAY_TUTORIAL_LOGO}
                        width="22.5rem"
                        className={`${Style}__img`}
                    />
                    <Title type="secondary">Make it easy with our quick tutorial!</Title>
                    <div className={`${Style}__text`}>
                        You can always watch the tutorial later, simply go to "actions" menu and selected "watch tutorial"
                    </div>
                    <div className={`${Style}__actions`}>
                        <Button
                            qaid="CreateCapTable.Button.Watch"
                            label="Watch"
                            onClick={() => {
                                showYouTubeModal(tutorial.capTable);
                                removeModal?.();
                            }}
                            // prepend={IC_PLAY_WHITE}
                        />
                        <Button
                            qaid="CreateCapTable.Button.Skip"
                            inverse
                            cancel
                            label="Skip"
                            isLoading={isLoading}
                            onClick={removeModal}
                        />
                    </div>
                </>
            ) : (
                <>
                    <Image
                        src={IC_CAPTABLE_LOGO_INSTANT}
                        className={`${Style}__img`}
                    />
                    <Title type="secondary">Create your cap table in minutes!</Title>
                    <div className={`${Style}__text`}>
                        Would you like to upload cap table directly from the excel file or continue manually?
                    </div>
                    <div className={`${Style}__actions`}>
                        <Button
                            qaid="CreateCapTable.Button.Upload"
                            label="Upload"
                            onClick={async () => {
                                hideModal?.();
                                setIsManually(true);
                                await new Promise((res) => setTimeout(res, 50));
                                history.push(`${Routes.capTable.import}/0`);
                            }}
                        />
                        <Button
                            qaid="CreateCapTable.Button.Manually"
                            inverse
                            label="Manually"
                            isLoading={isLoading}
                            onClick={async () => {
                                if (isFirstTime) {
                                    await onCreateProject();
                                    setIsManually(true);
                                } else {
                                    setIsManually(true);
                                    showModal({
                                        body: <NewDraft onSave={(name) => onCreateProject(name)} />,
                                        isFrameless: true,
                                        width: "44.2rem",
                                        maxWidth: "44.2rem",
                                        height: "42.8rem",
                                    });
                                    await removeModal?.();
                                }
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default CreateCapTable;
