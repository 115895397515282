import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import Select from "../../../../../../Shared/Components/Select/Select";
import React from "react";
import { observer } from "mobx-react-lite";
import { ModalBodyProps } from "../../../../../../Shared/Components/Modal/types";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { TemplateTypeEnum } from "../../../../../../Models/API/Document/template-type-enum";
import Button from "../../../../../../Shared/Components/Button/Button";
import { Transaction } from "../../../../../../Models/App/CapTable/Transaction";
import { CreateShareCertificateType } from "../../../../../../Models/API/CapTable/document-types";
import useContact from "../../../../../../Shared/Hooks/useContact";
import { IContact, IContactCreate } from "../../../../../../Models/API/Contact/contact";
import { ExecuteResponse } from "../../../../../../Services/BaseService";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";

type Props = ModalBodyProps & {
    obj: Transaction;
    contact: IContactCreate;
    updateContact: (contact: Partial<IContactCreate>, isUpdate?: boolean) => Promise<ExecuteResponse<IContact> | undefined>;
    setReupdateContact?: (toUpdateContact: boolean) => void;
}

const modalStyle = css({
    padding: '6rem 7.7rem',
    whiteSpace: 'nowrap',

    '>h3': {
        fontSize: '2.5rem',
        margin: '3rem 0'
    },

    '.wrapDivider': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: '500',
        marginBottom: '3rem',
        gap: '15px'
    },

    '.divider': {
        backgroundColor: appConfig.style.colors.table,
        width: '100%',
        height: '0.1rem'
    },

    '.wrapInputs': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '2.4rem',
        marginBottom: '3rem',
    },

    '.errorMsg': {
        color: appConfig.style.colors.danger,
    }
});

const CreateShareCertificate: React.FC<Props> = ({ obj, onConfirmHandler, contact, updateContact, setReupdateContact }) => {
    const [shareCertificateLetterDetails, setShareCertificateLetterDetails] = React.useState<{ templateId: string, userEmail: string, errorMessage: string }>({
        templateId: '',
        userEmail: contact.email || '',
        errorMessage: ''
    });
    const { shareHolderStore, documentsStore, } = useRootStore();

    React.useEffect(() => {
        documentsStore.getTemplates();
    }, []);

    const onCreateLette = async () => {
        if (!contact.email) {
            await updateContact({ email: shareCertificateLetterDetails.userEmail, contactId: contact.contactId });
            setReupdateContact && setReupdateContact(true);
        }

        const data: CreateShareCertificateType = {
            projectID: obj.projectID || 0,
            templateId: shareCertificateLetterDetails.templateId,
            ProjectPersonID: obj.ProjectPersonID || 0,
            TransactionId: obj.transactionId,
            TransactionType: obj.typeId,
            email: shareCertificateLetterDetails.userEmail,
        }

        const res = await shareHolderStore.createCertificateLetter(data);

        if (res.data?.isSucceeded) onConfirmHandler && onConfirmHandler();
    }

    const onCheckValidation = () => {
        if (shareCertificateLetterDetails.userEmail === '') {
            setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, errorMessage: 'Please add recipient email to continue' });
            return;
        }

        if (shareCertificateLetterDetails.templateId === '') {
            setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, errorMessage: 'Template was not selected, please select template to to continue' });
            return;
        }

        const validateEmail = commonValidators.emailValidator(shareCertificateLetterDetails.userEmail).message;
        if (validateEmail) {
            setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, errorMessage: validateEmail });
            return;
        }

        onCreateLette();
    }

    return (
        <div className={modalStyle}>
            <h3>Create share certificate</h3>
            <div className="wrapDivider">
                <span>Share certificate details</span>
                <div className="divider"></div>
            </div>
            <div className="wrapInputs">
                <TextInput
                    qaid="CreateCertificateLetter.Input.CertificateId"
                    label="Certificate ID"
                    placeholder="Auto-generated"
                    value={''}
                    disabled
                />
                <Select
                    qaid="CreateCertificateLetter.Select.Template"
                    label="Template"
                    value={shareCertificateLetterDetails.templateId}
                    options={documentsStore.getTemplatesOptionsByType(TemplateTypeEnum.ShareCertificate)}
                    name="templateId"
                    onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, templateId: value as string, errorMessage: '' })}
                    required
                />
                <TextInput
                    qaid="CreateCertificateLetter.Input.userEmail"
                    label="Shareholder email"
                    name="userEmail"
                    value={shareCertificateLetterDetails.userEmail}
                    onChange={(value) => setShareCertificateLetterDetails({ ...shareCertificateLetterDetails, userEmail: value, errorMessage: '' })}
                    required
                    disabled={!!contact.email}
                />
            </div>
            <div className="divider"></div><br />
            <span className="errorMsg">{shareCertificateLetterDetails.errorMessage}</span>
            <Button
                style={{ marginRight: "0px", marginLeft: 'auto' }}
                onClick={() => onCheckValidation()}
                qaid="create.button">Create</Button>
        </div>
    )
}

export default observer(CreateShareCertificate);