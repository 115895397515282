import { observer } from "mobx-react-lite";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Tab from "../../../../Shared/Components/Tab";
import { css } from "@emotion/css";
import appConfig from "../../../../config/config";
import { GrantsTableTab } from "../../../../Models/App/EquityPlans/GrantTableTabs";

const Style = css({
    label: "GrantsTabs",
    display: "flex",
    borderBottom: `1px solid ${appConfig.style.colors.border1}`,
    width: '80%',
});

const Tabs = () => {
    const { equityPlansStore: { selectedTab, setSelectedTab }, } = useRootStore();

    return (
        <div className={Style}>
            <Tab
                label="Promised"
                onClick={() => setSelectedTab(GrantsTableTab.Promised)}
                isActive={selectedTab === GrantsTableTab.Promised}
                qaid="equityDashboard.tab.Promised"
            />
            <Tab
                label="Grants"
                onClick={() => setSelectedTab(GrantsTableTab.Grants)}
                isActive={selectedTab === GrantsTableTab.Grants}
                qaid="equityDashboard.tab.Grants"
            />
            <Tab
                label="Beneficiaries"
                onClick={() => setSelectedTab(GrantsTableTab.Beneficiaries)}
                isActive={selectedTab === GrantsTableTab.Beneficiaries}
                qaid="equityDashboard.tab.Beneficiaries"
            />
            <Tab
                label="Orders"
                onClick={() => setSelectedTab(GrantsTableTab.Orders)}
                isActive={selectedTab === GrantsTableTab.Orders}
                qaid="equityDashboard.tab.Orders"
            />
            <Tab
                label="Cancelations"
                onClick={() => setSelectedTab(GrantsTableTab.Cancelations)}
                isActive={selectedTab === GrantsTableTab.Cancelations}
                qaid="equityDashboard.tab.Cancelations"
            />
        </div>
    );
};

export default observer(Tabs);